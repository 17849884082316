import { Component, ElementRef, EventEmitter,Inject,LOCALE_ID, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Attachment, AttachmentMultiple } from 'src/app/core/models/attachment.model';
import { CommonService } from '../../../core/services/common.service';
import {formatDate} from '@angular/common';

@Component({
  selector: 'app-attachment-multiple',
  templateUrl: './attachment-multiple.component.html',
  styleUrls: ['./attachment-multiple.component.css']
})
export class AttachmentMultipleComponent implements OnInit {
@ViewChild('newFile', { static: false })
  private newFileInput: ElementRef;
  files: File;
  Attachments: any[] = [];

  @Input() disabled: boolean;
  @Input() attachmentMultiple: AttachmentMultiple[];
  @Input() fileType: string;
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(
    private commonService: CommonService,
    @Inject(LOCALE_ID) private locale: string) {
    if (this.disabled) {
      this.disabled = this.disabled
    }
    else {
      this.disabled = false;
    }
  }

  ngOnInit(): void {
    //console.log("In upload-file-multiple ngOnInit fileType: " + this.fileType);
  }
  ngOnChanges(changes: SimpleChanges) {
    //console.log("In ngOnChanges");
    //console.log(this.fileUploadObjectMultiple)
    this.eventEmitter.emit(this.attachmentMultiple)
  }

  async convertFile(file: File): Promise<Observable<string>> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }

  async fileNewSelect(event: any): Promise<void> {
    //console.log(event)

    if (event.target.files && event.target.files[0]) {
      this.files = event.target.files;
      let filesCount = event.target.files.length;
      //console.log("filesCount " + filesCount);

      for (let i = 0; i < filesCount; i++) {

        const file: File = this.files[i];
        (await this.convertFile(file)).subscribe(base64 => {
          //console.log('Convert base64: ' + JSON.stringify(base64));
          var AttachmentFileName = this.files[i].name;

          var AttachmentFileHeader = base64.split(',')[0];
          var AttachmentFileBytes = base64.split(',')[1];
          //console.log('AttachmentFileName: ' + AttachmentFileName);
          //console.log('AttachmentFileHeader: ' + AttachmentFileHeader);
          //console.log('AttachmentFileBytes: ' + AttachmentFileBytes);
          //console.log('fileType: ' + this.files[i].type);

          const itemTemp:AttachmentMultiple = {
            'AttachmentId': 0,
            'AttachmentFileName': AttachmentFileName,
            'AttachmentFileType': '',
            'AttachmentFileModule': '',
            'AttachmentFilePath': '',
            'Base64Attachment': AttachmentFileBytes,
            'TypeId': 0,
            'CreatedOn': new Date(formatDate(Date.now(),'yyyy-MM-dd hh:mm:ss', this.locale)),
            'CreatedBy': '',
            'AttachmentFileBase64': base64,
            'AttachmentFileSize': this.commonService.countFileUploadedSizeText(AttachmentFileName, base64),
            //'AttachmentIsImage': this.isImage(AttachmentFileName)
          }
          //console.log(this.isImage(AttachmentFileName));
          this.attachmentMultiple.push(itemTemp);
        });

        //console.log('Attachments: ' + JSON.stringify(this.Attachments));
      }

      //console.log("In fileNewSelect");
      //console.log(this.fileUploadObjectMultiple);
      this.eventEmitter.emit(this.attachmentMultiple);
    }

  }

  onClickDownload(fileUploaded){

    if(fileUploaded?.AttachmentFileBase64){
      this.commonService.onClickDownload(fileUploaded?.AttachmentFileBase64, fileUploaded?.AttachmentFileName);
      return 0;
    }

    this.commonService.readFileFromURL(fileUploaded?.AttachmentFilePath)
    .subscribe(async res => {
      const url= window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileUploaded?.AttachmentFilePath.substring(fileUploaded?.AttachmentFilePath.lastIndexOf('\\')+1);
      link.click();
    });
  }

  fileUploadedDelete(i: number, AttachmentId: number) {
    console.log('Delete i:' + i + ' AttachmentId:' + AttachmentId);

    this.attachmentMultiple.splice(i, 1);

    //console.log(this.fileUploadObjectMultiple);
    this.eventEmitter.emit(this.attachmentMultiple);
  }

  getFileExtension(fileName) {
    var fileExtension;
    fileExtension = fileName.replace(/^.*\./, '');
    return fileExtension;
  }
  isImage(attachment){
      if(attachment == null || attachment == undefined)
      return;
      var fileExt = this.getFileExtension(attachment.AttachmentFileName);
      //console.log('fileExt:' + fileExt);
      var imagesExtension = ["png", "jpg", "jpeg","PNG", "JPG", "JPEG"];

      if (imagesExtension.indexOf(fileExt) !== -1) {
        return true;
      } else {
        return false;
      }
  }

  // isImage(attachment) {
  //   if(attachment == null || attachment == undefined)
  //   return;
  //   var fileExt = this.getFileExtension(attachment.AttachmentFileName);
  //   //console.log('fileExt:' + fileExt);
  //   var imagesExtension = ["png", "jpg", "jpeg","PNG", "JPG", "JPEG"];

  //   if (imagesExtension.indexOf(fileExt) !== -1) {
  //         if(attachment.AttachmentFileBase64 == null || attachment.AttachmentFileBase64 == undefined){
  //           this.commonService.readFileFromURL(attachment.AttachmentFilePath)
  //                 .subscribe(async res => {
  //                   const reader = new FileReader();
  //                     reader.readAsDataURL(res); 
  //                     reader.onloadend = () => {
  //                       const base64data = reader.result;   
  //                       attachment.AttachmentFileBase64 =base64data.toString();
  //                     }
  //                 });
  //         }
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }
}
