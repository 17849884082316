import {Component, OnDestroy, OnInit} from '@angular/core';

import { EventService } from '../../../core/services/event.service';
import {Subscription} from 'rxjs';
import {Site} from '../../../core/models/site.models';
import {AuthenticationService} from '../../../core/services/auth.service';
import {SiteService} from '../../../core/services/site.service';
import {UserService} from '../../../core/services/user.service';
import {User} from '../../../core/models/user.models';
import {CommonService} from '../../../core/services/common.service';

@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.css']
})
export class RightsidebarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  userId: number;
  usersOfCurrentSite: User[] = [];
  buildingManager: User;
  buildingSupervisor: User;
  building: User;
  siteId: number;
  currentSite: Site;
  siteAddress3: string = '';

  constructor(private eventService: EventService,
              private authService: AuthenticationService,
              private siteService: SiteService,
              private commonService: CommonService,
              private userService: UserService) { }

  ngOnInit() {
    this.siteService.currentselectedSite.subscribe(site => {
      this.currentSite= site
    })
    this.siteId = Number(localStorage.getItem('siteId'));
    this.userId = this.authService.currentUser().UserId;
    if(this.siteId!=null && this.siteId!=undefined && this.siteId!=0)
    {
      this.getSiteDetailById()
      this.getAllUsersBySiteId()
      
    window.addEventListener('storage', (event) => {
      console.log('event from localstorage')
      this.getSiteDetailById()
      this.getAllUsersBySiteId()
    });
    }
  }

  /**
   * Change the layout onclick
   * @param layout Change the layout
   */
  changeLayout(layout: string) {
    this.eventService.broadcast('changeLeftSidebarType', 'default');
    this.eventService.broadcast('changeLeftSidebarTheme', 'default');
    this.eventService.broadcast('changeLayoutWidth', 'default');
    this.eventService.broadcast('changeLayout', layout);
    this.hide();
  }

  /**
   * Change the left-sidebar theme
   * @param theme Change the theme
   */
  changeLeftSidebarTheme(theme: string) {
    this.eventService.broadcast('changeLayout', 'vertical');
    this.eventService.broadcast('changeLeftSidebarTheme', theme);
    this.eventService.broadcast('changeLeftSidebarType', 'default');
    this.hide();
  }

  /**
   * Change the layout
   * @param type Change the layout type
   */
  changeLeftSidebarType(type: string) {
    this.eventService.broadcast('changeLayout', 'vertical');
    this.eventService.broadcast('changeLeftSidebarType', type);
    this.hide();
  }

  /**
   * Change the layout width
   * @param width string
   */
  changeLayoutWidth(width: string) {
    this.eventService.broadcast('changeLayoutWidth', width);
    this.hide();
  }


  /**
   * Hide the sidebar
   */
  public hide() {
    document.body.classList.remove('right-bar-enabled');
  }

  getAllSitesByUserId() {
    this.subscriptions.push(
      this.siteService.getAllSitesDetailsByUserId(this.userId).subscribe({
        next: data => {
          this.currentSite = data.find(site => site.SiteId === this.siteId)
        },
        error: err => console.error(err)
      })
    )
  }

  getSiteDetailById(){
     this.subscriptions.push(
      this.siteService.getSitesById(this.siteId).subscribe({
        next: data => {
          this.currentSite = data
          if(this.currentSite?.PostCode){
            this.siteAddress3 = this.siteAddress3 + this.currentSite?.PostCode
          }
          if(this.currentSite?.SiteCity){
            this.siteAddress3 =  this.siteAddress3 + ', ' + this.currentSite?.SiteCity
          }
          if(this.currentSite?.SiteState){
            this.siteAddress3 = this.siteAddress3 + ', ' + this.currentSite?.SiteState          
          }
          if(this.currentSite?.SiteCountry){
            this.siteAddress3 = this.siteAddress3 + ', ' +  this.currentSite?.SiteCountry          
          }
          
           },
        error: err => console.error(err)
      })
    )
  }

  getAllUsersBySiteId() {
    this.subscriptions.push(
      this.userService.getUsersBySiteId(this.userId).subscribe({
        next: data => {
          this.usersOfCurrentSite = data
          this.buildingManager = this.usersOfCurrentSite.find(user => user.Role.RoleName === 'BM')
          this.buildingSupervisor = this.usersOfCurrentSite.find(user => user.Role.RoleName === 'BS')
        },
        error: err => console.error(err)
      })
    )
  }
    getHourMinTime(time) {
      let AmPm = 'AM';
      if(time == null || time == undefined)
      {
        return '00:00'
      }
      else
      {
        const timeSeparated = time.split(' ')
        const timeHoursAndMinutes = timeSeparated[0].split(':')


        timeHoursAndMinutes[0] = parseInt(timeHoursAndMinutes[0])

        if(timeHoursAndMinutes[0] >= 12){
           AmPm = 'PM';
        }
        
        if(timeHoursAndMinutes[0] > 12){
          timeHoursAndMinutes[0] = timeHoursAndMinutes[0] - 12;
        }



        const timeMinHour = timeHoursAndMinutes[0] + ':' + timeHoursAndMinutes[1] + ' ' +AmPm
        return timeMinHour
      }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
