import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { Attachment } from 'src/app/core/models/attachment.model';
import { CommonService } from 'src/app/core/services/common.service';
import { User, VIEW_LIST_CONST } from 'src/app/core/models/user.models';
import { ActivatedRoute, Router } from '@angular/router';
import { Vendor } from 'src/app/core/models/vendor.models';
import { Subscription } from 'rxjs';
import { VendorService } from 'src/app/core/services/vendor.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-vendor-info',
  templateUrl: './vendor-info.component.html',
  styleUrls: ['./vendor-info.component.css']
})
export class VendorInfoComponent implements OnInit {

  user: User;
  userEditVendorAllowedAccess: boolean = false;

  vendorLoad: Vendor = new Vendor();
  id: number;
  currentDate = new Date();
  private sub!: Subscription;
  errorMessage = '';

  profileAttachment: Attachment = new Attachment();
  contractAttachment: Attachment = new Attachment();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vendorService: VendorService,
    private commonService: CommonService,
    private authenticationService: AuthenticationService,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.user = this.authenticationService.currentUser();
    
    this.userEditVendorAllowedAccess = this.authenticationService.isAllowAccess('Edit Vendor');
    this.sub = this.route.paramMap.subscribe(
      params => {
        this.id = Number(this.route.snapshot.paramMap.get('id'));
        // console.log("The ID: " + this.id);

        this.displayVendor();
      }
    );
  }

  displayVendor(): void {
    if (this.id > 0) {
      this.vendorService.getVendorByID(this.id).subscribe({
        next: data => {
          this.vendorLoad = data;
          //console.log('Load data : ' + JSON.stringify(this.vendorLoad));

          if (this.vendorLoad.AttachmentFileList) {
            for (let AttachmentFile of this.vendorLoad.AttachmentFileList) {
              if (AttachmentFile.AttachmentFileType == "Contract") {
                 this.contractAttachment.fileName = AttachmentFile.AttachmentFileName;
                 this.contractAttachment.filePath = AttachmentFile.AttachmentFilePath;
              }

              if (AttachmentFile.AttachmentFileType == "Profile") {
                 this.profileAttachment.fileName = AttachmentFile.AttachmentFileName;
                 this.profileAttachment.filePath = AttachmentFile.AttachmentFilePath;
              }
            }
          }
        },
        error: err => this.errorMessage = err
      });

    } else {
      this.router.navigate(['/apps/service-provider']);
    }
  }

  vendorDateStatus(endDate) {
    return this.vendorService.vendorDateStatus(endDate);
  }

  onClickDownload(base64String, fileName) {
    this.commonService.onClickDownload(base64String, fileName);
  }
}
