import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-reuseable-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})
export class TableComponent implements OnInit {
  @Input() dataSource: MatTableDataSource<any>;
  @Input() displayedColumns: string[];
  @Input() columnTemplates: { [key: string]: TemplateRef<any> };
  @Input() columnHeaders: string[];
  @Input() selection: SelectionModel<any>;
  @Input() isAllSelected: () => boolean;
  @Input() masterToggle: () => void;

  // New array to include the 'select' column
  allColumns: string[];

  ngOnInit(): void {
    console.log('TableComponent dataSource:', this.dataSource.data);

    this.allColumns = ['select', ...this.displayedColumns];
    // Ensure 'select' column is the first column for checkboxes
    // if (!this.displayedColumns.includes('select')) {
    //   this.displayedColumns.unshift('select');
    // }
    // if (!this.displayedColumns.includes('select')) {
    //   // Ensure 'select' column is added only once
    //   this.displayedColumns = ['select', ...this.displayedColumns];
    // }
  }
}
