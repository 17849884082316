export class FileUpload {
  // new file
  fileNewName?: string;
  fileNewByte?: string;
  fileNewSize?: string;

  // old file
  fileUploadedName: string;
  fileUploadedByte: string;
  fileUploadedSize: string;
}
