import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
  transform(value: any, args?: any): any {
	  if(args=='short'){    //Example: Apr 2022
		  return super.transform(value, "MMM yyyy");
	  }else if(args=='shorttime'){   //Example: 04:03 pm
      if (value) {

        var timeOriHour = value.split(':')[0];
        var timeOriMinute = value.split(':')[1];
        if (timeOriHour > 12) {
          let timeNewHour = timeOriHour - 12;
          return timeNewHour.toString().padStart(2, '0') + ':' + timeOriMinute + ' pm';
        } else {
          if(timeOriHour==12){
            return timeOriHour + ':' + timeOriMinute + ' pm';
          }
          return timeOriHour + ':' + timeOriMinute + ' am';
        }
  
      }
      return '';
    }
    else if(args=='dateonly'){   //Example: 12
      if (value) {
        return super.transform(value, "d");
      }
    }
    else if(args=='withtime'){   //Example: 15 Apr 2022, 04:03 pm
      if (value) {
        //value = '2022-04-11T14:23:10';
        var dateOri = value.split('T')[0];
        var timeOri = value.split('T')[1];
        var timeOriHour = timeOri.split(':')[0];
        var timeOriMinute = timeOri.split(':')[1];
        var timeString = '';
        if (timeOriHour > 12) {
          let timeNewHour = timeOriHour - 12;
          timeString = timeNewHour.toString().padStart(2, '0') + ':' + timeOriMinute + ' pm';
        } else {
          if(timeOriHour==12){
            timeString = timeOriHour + ':' + timeOriMinute + ' pm';
          }
          timeString = timeOriHour + ':' + timeOriMinute + ' am';
        }
        return super.transform(value, "dd MMM yyyy") + ', ' + timeString;
      }
      return '';
    }
    else if(args=='withtimeshort'){   //Example: 15 Apr 22, 04:03 pm
      if (value) {
        //value = '2022-04-11T14:23:10';
        var dateOri = value.split('T')[0];
        var timeOri = value.split('T')[1];
        var timeOriHour = timeOri.split(':')[0];
        var timeOriMinute = timeOri.split(':')[1];
        var timeString = '';
        if (timeOriHour > 12) {
          let timeNewHour = timeOriHour - 12;
          timeString = timeNewHour.toString().padStart(2, '0') + ':' + timeOriMinute + 'pm';
        } else {
          if(timeOriHour==12){
            timeString = timeOriHour + ':' + timeOriMinute + 'pm';
          }
          timeString = timeOriHour + ':' + timeOriMinute + 'am';
        }
        return super.transform(value, "dd MMM yy") + ', ' + timeString;
      }
      return '';
    }
    return super.transform(value, "dd MMM yyyy");    //Example: 15 Apr 2022
  }
}