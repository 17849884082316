import { Component, OnInit, AfterViewInit, ViewChild, Inject, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../../../../core/services/vendor.service';

import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-vehicle-add-dialog',
    templateUrl: './add-dialog.component.html',
    styleUrls: ['./add-dialog.component.css'],
})

export class VehicleAddDialogComponent {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];

    deleteForm!: FormGroup;
    submitted = false;
    errorMessage = '';
    infoMessage = '';
    pageTitle = 'Delete';
    private sub!: Subscription;
    displayMessage: { [key: string]: string } = {};
    vendorLoad: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<VehicleAddDialogComponent>,
        private vendorService: VendorService,
        @Inject(MAT_DIALOG_DATA) data) {

        //console.log('Inject data: ' + JSON.stringify(data));

        this.vendorLoad = { ...data };
        this.pageTitle = 'Delete : ' + data.VendorName;

    }

    ngOnInit(): void {
        this.deleteForm = this.fb.group({
        });
    }

    get f() { return this.deleteForm.controls; }

    save() {
        //console.log(this.deleteForm);
        //console.log('Form data: ' + JSON.stringify(this.deleteForm.value));

        this.submitted = true;

            console.log('Inside Delete: ' + this.vendorLoad.VendorID);

            this.vendorService.deleteVendor(this.vendorLoad.VendorID)
                .subscribe({
                    next: x => {
                        //console.log(x);
                        return this.onSaveComplete();
                    },
                    error: err => this.errorMessage = err
                });

            this.infoMessage = 'Success Delete.';
            return;
        
    }

    close(): void {
        this.dialogRef.close(false);
    }

    onSaveComplete(): void {
        // Reset the form to clear the flags
        this.deleteForm.reset();
        this.dialogRef.close(this.deleteForm.value);
    }

}