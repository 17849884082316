import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError, EMPTY } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {Vendor, VendorAttachmentDelete} from '../models/vendor.models';
import { DatePipe } from '@angular/common';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})

export class VendorService {
  private vendorBySiteUrl = environment.apiBaseUrl + 'siteData/getallserviceproviderbysite';
  private vendorByIDURL = environment.apiBaseUrl + 'siteData/getserviceproviderbyid';
  private addVendorUrl = environment.apiBaseUrl + 'siteData/addvendor';
  private updateVendorUrl = environment.apiBaseUrl + 'siteData/updatevendor';
  private categoryUrl = environment.apiBaseUrl + 'siteData/getvendorcategory';
  private archiveVendorUrl = environment.apiBaseUrl + 'siteData/archivevendor';
  private deleteVendorUrl = environment.apiBaseUrl + 'siteData/deletevendor';

  constructor(private http: HttpClient,
    private datePipe: DatePipe,
    private commonService: CommonService) { }

  getVendorBySite(siteId: number): Observable<Vendor[]> {
    const url = `${this.vendorBySiteUrl}/${siteId}`;
    //console.log(url);
    return this.http.get<Vendor[]>(url)
      .pipe(
        tap(data =>
          //console.log('getVendorBySite: ' + JSON.stringify(data))
          console.log('getVendorBySite: ')
        ),
        catchError(this.commonService.handleError)
      );
  }

  getVendorByID(Id: number): Observable<Vendor> {
    const url = `${this.vendorByIDURL}/${Id}`;
    //console.log(url);
    return this.http.get<Vendor>(url)
      .pipe(
        tap(data =>
          //console.log('getVendorByID: ' + JSON.stringify(data))
          console.log('getVendorByID: ')
        ),
        catchError(this.commonService.handleError)
      );
  }
  
  addVendor(vendor: Vendor): Observable<Vendor> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.addVendorUrl}`;
    return this.http.post<Vendor>(url, vendor, { headers })
      .pipe(
        tap(() => console.log('added vendor: ' + JSON.stringify(vendor))),
        // Return the vendor on create
        map(() => vendor),
        catchError(this.commonService.handleError)
      );
  }

  archiveVendor(Id: number): Observable<{}> {
    const url = `${this.archiveVendorUrl}/${Id}`;
    console.log(url);
    return this.http.put<any>(url, null)
      .pipe(
        //tap(data => console.log('deleteChecklist End: ' + Task.TaskId)),
        catchError(this.commonService.handleError)
      );
  }

  updateVendor(vendor: Vendor): Observable<Vendor> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.updateVendorUrl}`;
    return this.http.post<Vendor>(url, vendor, { headers })
      .pipe(
        tap(() => console.log('updated vendor: ' + vendor.VendorID)),
        // Return the vendor on an update
        map(() => vendor),
        catchError(this.commonService.handleError)
      );
  }

  getAllVendorCategory(): Observable<any[]> {
    const url = `${this.categoryUrl}`;
    //console.log(url);
    return this.http.get<any[]>(url)
      .pipe(
        tap(data =>
          //console.log('getAllVendorCategory: ' + JSON.stringify(data))
          console.log('getAllVendorCategory: ')
        ),
        catchError(this.commonService.handleError)
      );
  }

vendorDateStatus(endDate) {
  const currentDate = new Date();
  let localStart = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
  let localEnd = this.datePipe.transform(endDate, 'yyyy-MM-dd');

  if (localStart > localEnd)
    return "Inactive";
  else
    return "Active";
}

  deleteVendor(Id: number): Observable<{}> {
    const url = `${this.deleteVendorUrl}/${Id}`;
    console.log(url);
    return this.http.put<any>(url, null)
      .pipe(
        //tap(data => console.log('deleteChecklist End: ' + Task.TaskId)),
        catchError(this.commonService.handleError)
      );
  }
}