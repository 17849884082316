import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  title: any;
  message: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any = null,
  ) { }

  ngOnInit(): void {
    this.title = this.data.title
    this.message = this.data.message
  }

}
