import {Unit} from './unit.models';
export class VisitorParkingLot{
  CarParkId: number;
  SiteId: number;
  CarParkType: string;
  OverNightParking: boolean;
  AdvanceBooking: number;
  ParkingLotMap: string;
  LotMapByte: string;
  LotMapFileName: string;
  // SlotPerBay: number;
  SlotPerBooking: number;
  ParkingLotName: string;
  UpdatedOn: Date;
  UpdatedBy: string;
  TotalParkingSlot: number;
  StartTime: string;
  EndTime: string;
  Duration: number;
  IsActive: boolean;
  ParkingSlot: string; // not from BE; modified from TotalParkingSlot
  VisitorParkingTimeSlotList: TimeSlot[];
  VisitorParkingSpecialTimeSlotList: SpecialTimeSlot[];
  ErrorMsg?: string;
}

export class TimeSlot{
  TimeSlotId?: number;
  CarParkId?: number;
  SlotTimeFrom: string;
  SlotTimeTo: string;
  SlotTimeNo: number;
  SlotDay: string;
  UpdatedOn: Date;
  UpdatedBy: string;
  BookingId?: number;
  IsBooked?: boolean;
}

export class SpecialTimeSlot{
  TimeSlotSpecialId?: number;
  CarParkId?: number;
  Note?: string;
  SpecialDate: Date;
  SpecialStartTime: string;
  SpecialEndTime: string;
  IsActive: boolean;
  UpdatedOn: Date;
  UpdatedBy: string;
}

export class ExactDateBlock {
  TimeSlotSpecialId?: Array<number>;
  CarParkId?: number;
  Note?: string;
  SpecialDates: Array<Date> = [];
  SpecialStartTime?: string;
  SpecialEndTime?: string;
  IsActive?: boolean;
}

export class VisitorParkingLotDelete{
  CarParkId: number;
}

// =======
// Booking
// =======

export class VisitorParkingBooking{
  BookingId: number;
  UnitId: number;
  SiteId: number;
  CarParkId: number;
  VehicleType: string;
  VisitorType: string;
  VisitorName: string;
  VisitorContact: string;
  CarPlateNo: string;
  StartParkingTimeSlot: TimeSlot;
  EndParkingTimeSlot: TimeSlot;
  VisitorParkingLot: VisitorParkingLot;
  CheckInTime: string;
  CheckOutTime: string;
  Note: string;
  NeedParking: boolean;
  RegisteredBy: string;
  QRCode: string;
  OvernightParking: boolean;
  AccessMethod: string;
  ApprovedBy: string;
  ApprovedOn: Date;
  CreatedOn: Date;
  CreatedBy: string;
  BookingDate: Date
}

export class VisitorParkingBookingUpdate{
  BookingId: number;
  UnitId: number;
  SiteId: number;
  VehicleType: string;
  VisitorType: string;
  VisitorName: string;
  VisitorContact: string;
  CarPlateNo: string;
  CarParkId: number;
  Note: string;
  NeedParking: boolean;
  RegisteredBy: string;
  OvernightParking: boolean;
  AccessMethod: string;
  ApprovedBy: string;
  ApprovedOn: Date;
  StartParkingTimeSlot: { TimeSlotId: number };
  EndParkingTimeSlot: { TimeSlotId: number };
  BookingDate: Date
}

export class VisitorParkingBookingAdd{
  UnitId: number;
  SiteId: number;
  VehicleType: string;
  VisitorType: string;
  VisitorName: string;
  VisitorContact: string;
  CarPlateNo: string;
  CarParkId: number;
  Note: string;
  NeedParking: boolean;
  RegisteredBy: string;
  OvernightParking: boolean;
  AccessMethod: string;
  ApprovedBy: string;
  ApprovedOn: Date;
  StartParkingTimeSlot: { TimeSlotId: number };
  EndParkingTimeSlot: { TimeSlotId: number };
  BookingDate: Date
  CreatedOn: string
}

export class WalkInVisitor{
  WalkInId: number;
  Unit: Unit;
  SiteId: number;
  VisitorName: string;
  VisitorContact: string;
  CheckInTime: string;
  CheckOutTime: string;
  Note: string;
  RegisteredBy: string;
  QRCode: string;
  CreatedOn: Date;
  CreatedBy: string;
}
