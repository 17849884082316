import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError , EMPTY} from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import {Owner, OwnerInfo, UnitCountrySite} from '../models/owner.models';
import {Unit, UnitFeeUpdate, UnitImport,UnitTitleUpdate,massUploadCN, UnitAccountImport,UnitImportDto} from '../models/unit.models';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  private getUnitsBySiteIdURL: string = environment.apiBaseUrl+'ownerUnit/getallunitbysite/';
  private getUnitsBySiteId_ShortInfoURL: string = environment.apiBaseUrl+'ownerUnit/getallunitbysite_shortinfo/';
  private importUnitURL: string = environment.apiBaseUrl+'ownerUnit/importunit';
  private updateUnitURL: string = environment.apiBaseUrl+'ownerUnit/updateunit';
  private getUnitKeyOwnerDetailURL: string = environment.apiBaseUrl+'ownerUnit/getunitownerdetail/';
  private updateUnitTitleURL: string = environment.apiBaseUrl+'ownerUnit/updateunittitle/';
  private updateUnitFeeURL: string = environment.apiBaseUrl+'ownerUnit/updateunitfee/';
  private massCreditNoteUploadURL: string = environment.apiBaseUrl+'creditnote/MassCreditNoteUpload';
  private getunitwithnooccupantsbysiteURL = environment.apiBaseUrl + '/ownerUnit/getunitwithnooccupantsbysite/';
  private importUnitAccountURL = environment.apiBaseUrl + 'ownerUnit/unitaccountimport/';
  private getunitcounterbysiteURL = environment.apiBaseUrl + 'ownerUnit/getunitcounterbysite/';
  private currentUnitInfo: any;

  constructor(
    private http: HttpClient,
    private commonService: CommonService) { }

  getUnitsBySiteId(siteId: number): Observable<OwnerInfo[]>{
    return this.http.get<OwnerInfo[]>(this.getUnitsBySiteIdURL + siteId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getUnitsBySiteId_ShortInfo(siteId: number): Observable<UnitCountrySite[]>{
    return this.http.get<UnitCountrySite[]>(this.getUnitsBySiteId_ShortInfoURL + siteId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getUnitKeyOwnerDetail(unitId: number): Observable<Owner>{
    return this.http.get<Owner>(this.getUnitKeyOwnerDetailURL + unitId)
      .pipe(
        tap((data: Owner) => console.log(data)),
        catchError(this.commonService.handleError)
      );
  }

  
  getUnitWithNoOccupantsBySite(unitId: number): Observable<Unit[]>{
    return this.http.get<Unit[]>(this.getunitwithnooccupantsbysiteURL + unitId)
      .pipe(
        tap((data: Unit[]) => console.log(data)),
        catchError(this.commonService.handleError)
      );
  }

  importUnit(units: UnitImportDto){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.importUnitURL}`;
    return this.http.post<any>(url, units, { headers })
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  updateUnit(unit: Unit){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.updateUnitURL}`;
    return this.http.post<any>(url, unit, { headers })
      .pipe(
        tap(() => console.log('updated unit: ' + unit.UnitId)),
        // Set new state for the unit on an update
        map(() => {
          // this.currentUnitInfo.Unit = unit;
        }),
        catchError(this.commonService.handleError)
      );
  }

    updateTitle(units: UnitTitleUpdate[]){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.updateUnitTitleURL}`;
    return this.http.post<any>(url, units, { headers })
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

    updateFee(units: UnitFeeUpdate[]){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.updateUnitFeeURL}`;
    return this.http.post<any>(url, units, { headers })
      .pipe(
        catchError(this.commonService.handleError)
      );
  }
    massUploadCN(units: massUploadCN[]){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.massCreditNoteUploadURL}`;
        return this.http.post<any>(url, units, { headers })
          .pipe(
            catchError(this.commonService.handleError)
          );
  }
    massUploadUnitAccount(units: UnitAccountImport[]){
        const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        const url = `${this.importUnitAccountURL}`;
        return this.http.post<any>(url, units, { headers })
          .pipe(
            catchError(this.commonService.handleError)
          );
  }

    getunitcounterbysite(siteId: number): Observable<any>{
    return this.http.get<any>(this.getunitcounterbysiteURL + siteId)
      .pipe(
        tap((data: any) => console.log(data)),
        catchError(this.commonService.handleError)
      );
  }


  // TODO: refactor this into setCurrentUnitId
  // set unitInfo to be passed from list in main page to unit info page
  setCurrentUnitInfo(unitInfo: any){
    this.currentUnitInfo = unitInfo;
  }

  getCurrentUnitInfo(){
    return this.currentUnitInfo;
  }

}
