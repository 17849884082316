import {Component, OnInit, Output, EventEmitter, OnDestroy, HostListener} from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../core/services/auth.service';
import { notificationItems } from './data';
import {SiteService} from '../../../core/services/site.service';
import {Subscription,Subject} from 'rxjs';
import {Site} from '../../../core/models/site.models';
import {NotificationService} from '../../../core/services/notification.service';
import {CommonService} from '../../../core/services/common.service';
import {Notification} from '../../../core/models/notification.model';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  userId: number;
  sites: Site[] = [];
  siteId: number;
  currentSite: Site;
  allSitesPageUrl = '/sites';
  dashboardPageUrl = '/dashboard';

  notificationItems: Notification[];
  newNotificationCount: number = 0;
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };

  openMobileMenu: boolean;
  userActivity;
  getNotification;
  userInactive: Subject<any> = new Subject();
  allNotificationLink = '/apps/notification'
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  constructor(private router: Router,
              private authService: AuthenticationService,
              private siteService: SiteService,
              private notificationService: NotificationService,
              private dialogRef: MatDialog,
              private commonService: CommonService,) {}

  ngOnInit() {
    this.setTimeout();
    // this.setNotificationTimeout(2000);
    this.siteService.currentselectedSite.subscribe(site => {
        this.currentSite= this.sites.find(x=>x.SiteId==site.SiteId)
    });
    this.siteId = Number(localStorage.getItem('siteId'));
    this.userId = this.authService.currentUser().UserId;
    this.getAllSitesByUserId()
    
    this.openMobileMenu = false;
  }

  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggle the right side bar when having mobile screen
   */
  toggleRightSidebar(event: any) {
    event.preventDefault();
    this.settingsButtonClicked.emit();
  }


  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.dialogRef.closeAll();
    this.router.navigate(['/account/login']);
  }

    @HostListener('window:mousemove') refreshUserState() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  setTimeout() {
    this.userActivity = setTimeout(() => this.logout(), 3600000);
  }

  setNotificationTimeout(time:number) {
    this.getNotification = setTimeout(() => this.getNotificationByUserId(), time);
  }
  

  getAllSitesByUserId() {
    this.subscriptions.push(
      this.siteService.getAllSitesDetailsByUserId(this.userId).subscribe({
        next: data => {
          this.sites = data
          this.currentSite = this.sites.find(site => site.SiteId === this.siteId)
              let newSite=new Site();
              newSite.SiteName='--All Site--'
              this.sites.unshift(newSite);
                  if(this.currentSite == null || this.currentSite == undefined){
                    this.currentSite=newSite
                  }

        },
        error: err => console.error(err)
      })
    )
  }

  getNotificationByUserId(){
    this.subscriptions.push(
      this.notificationService.getAllNotification(this.userId,this.siteId).subscribe({
        next: data => {
          this.notificationItems = data
          this.newNotificationCount = this.notificationItems.filter(x=>!x.IsRead)?.length
          clearTimeout(this.getNotification);
          this.setNotificationTimeout(10000);
        },
        error: err => console.error(err)
      })
    )
  }

  readNotification(notification:Notification){
    console.log(notification)
    this.notificationService.updateNotificationStatus(notification.NotificationStatusId)
      .subscribe({
        next: () => {
          this.router.navigate([this.allNotificationLink])
        },
        error: err => this.commonService.ErrorNotification("error while creating user, " + err)
      })
  }
  clicksite(){
    if(this.currentSite.SiteName == '--All Site--')
        this.router.navigate([this.allSitesPageUrl])
  }
  changeSite(site) {
    console.log(site);
    // return;
    if(site ==null || site.SiteId==null || site.SiteId ==undefined){
      localStorage.removeItem('siteId');
      this.router.navigate([this.allSitesPageUrl]).then(() => {
        window.location.reload();});
    }
    else{
      localStorage.setItem('siteId', site.SiteId.toString())
      this.router.navigate([this.dashboardPageUrl]).then(() => {
        window.location.reload();});
    }

  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
