import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../core/guards/auth.guard';
import {SiteGuard} from '../../core/guards/site.guard';
import { NoticeComponent } from './notice/notice.component';
import { NoticeDetailComponent } from './notice/notice-detail/notice-detail.component';
import { AccessComponent } from './access/access.component';
import { FacilityComponent } from './facility/facility.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { VendorComponent } from './vendor/vendor.component';
import { VendorDetailsComponent } from './vendor/vendor-details/vendor-details.component';
import { VisitorComponent } from './visitor/visitor.component';
import { VisitorAddComponent } from './visitor/add/add.component';


import { RentalParkingComponent } from './rentalparking/rentalparking';
import { RentalParkingFormComponent } from './rentalparking/rentalparking-form';
import { IssueComponent } from './issue/issue';
import { IssueFormComponent } from './issue/issue-form';
import { PaymentComponent } from './payment/payment';

import { RenovationComponent } from './renovation/renovation';
import { RenovationFormComponent } from './renovation/renovation-form';
import { MaintenanceComponent } from './maintenance/maintenance';
import { MaintenanceFormComponent } from './maintenance/maintenance-form';

const routes: Routes = [
    {path: 'units', loadChildren: () => import('./data/units/units.module').then(m => m.UnitsModule), canActivate: [AuthGuard, SiteGuard]},
    
    {path: 'invoice', loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule), canActivate: [AuthGuard, SiteGuard]},
    {path: 'credit-note', loadChildren: () => import('./invoice/credit-note/credit-note.module').then(m => m.CreditNoteModule), canActivate: [AuthGuard, SiteGuard]},
    {path: 'payment',component: PaymentComponent, canActivate: [AuthGuard, SiteGuard]},


    {path: 'notice',component: NoticeComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'notice-detail/:id', component:NoticeDetailComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'visitor',component: VisitorComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'visitor/add',component: VisitorAddComponent, canActivate: [AuthGuard, SiteGuard]},

    {path: 'access',component:AccessComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'facility', component:FacilityComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'vehicle', component:VehicleComponent, canActivate: [AuthGuard, SiteGuard]},
    
    {path: 'vendor',component: VendorComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'vendor-details/:id',component: VendorDetailsComponent, canActivate: [AuthGuard, SiteGuard]},

    {path: 'issue', component:IssueComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'issue-form/:id',component: IssueFormComponent, canActivate: [AuthGuard, SiteGuard]},

    {path: 'rentalparking',component:RentalParkingComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'rentalparking-form/:id',component: RentalParkingFormComponent, canActivate: [AuthGuard, SiteGuard]},

    {path: 'renovation', component:RenovationComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'renovation-form/:id',component: RenovationFormComponent, canActivate: [AuthGuard, SiteGuard]},

    {path: 'maintenance', component:MaintenanceComponent, canActivate: [AuthGuard, SiteGuard]},
    {path: 'maintenance-form/:id',component: MaintenanceFormComponent, canActivate: [AuthGuard, SiteGuard]},
   
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AppsRoutingModule { }
