import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {CommonService} from '../../../core/services/common.service';
import {Attachment} from '../../../core/models/attachment.model';

@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit, OnChanges {
@ViewChild('newFile', {static: false})
  private newFileInput: ElementRef;

  @Input() disabled: boolean;
  @Input() fileObj: Attachment;
  @Input() editable: boolean;
  @Input() fileType: string;
  @Input() hideDelete: boolean;
  @Input() isProfilePic: boolean;

  @Output() eventEmitter: EventEmitter<Attachment> = new EventEmitter();

  private OldFileObj: Attachment = new Attachment();
  private newFileObj: Attachment = new Attachment();
  
  constructor(private commonService: CommonService) {
    if (this.disabled) {
      this.disabled = this.disabled
    }
    else {
      this.disabled = false;
    }
    if (this.hideDelete) {
      this.hideDelete = this.hideDelete
    }
    else {
      this.hideDelete = false;
    }
  }

  ngOnInit(): void {
    //console.log("In upload-file-multiple ngOnInit fileType: " + this.fileType);
    
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.fileUploadObject)
    this.eventEmitter.emit(this.fileObj)
  }

  // convert byte to base64
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }

  fileNewSelect(event: any): void {
    console.log(event)

    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.newFileObj.fileName = file.name;
      this.newFileObj.filePath = '';
      this.convertFile(file).subscribe(base64 => {
        this.newFileObj.fileBase64 = base64? base64.split(',')[1] : null;
        if(this.fileObj?.filePath!=null)
          this.OldFileObj=JSON.parse(JSON.stringify(this.fileObj));

        if(this.fileObj!=null && this.fileObj!= undefined){
          this.fileObj.filePath = null;
          this.fileObj.fileBase64 = null;
          this.fileObj.fileName = null;
        }

        this.eventEmitter.emit(this.newFileObj)
      });

    }
    //console.log(this.fileUploadObject)
  }

  onClickDownload(base64String,fileName) {
    console.log(base64String)
    console.log(fileName)
    this.commonService.onClickDownload(base64String,fileName);
  }

  fileNewDelete() {
    this.newFileInput.nativeElement.value = '';
    this.newFileObj.fileBase64 = null;
    this.newFileObj.fileName = null;

    this.fileObj=JSON.parse(JSON.stringify(this.OldFileObj))
    console.log(this.newFileObj)
    this.eventEmitter.emit(this.newFileObj)
  }

  fileUploadedDelete() {
    this.OldFileObj=JSON.parse(JSON.stringify(this.fileObj));
    this.fileObj.fileBase64 = null;
    this.fileObj.filePath = null;
    this.fileObj.fileName = null;

    this.eventEmitter.emit(this.fileObj)
  }

  getFileExtension(fileName) {
    var fileExtension;
    fileExtension = fileName.replace(/^.*\./, '');
    return fileExtension;
  }

  isImage(fileName) {
    if(fileName == null || fileName == undefined)
    return;
    var fileExt = this.getFileExtension(fileName);
    //console.log('fileExt:' + fileExt);
    var imagesExtension = ["png", "jpg", "jpeg","PNG", "JPG", "JPEG"];

    if (imagesExtension.indexOf(fileExt) !== -1) {
      return true;
    
    } else {
      return false;
    }
  }

  getFileName(fullname:string):string{
    if(fullname==null || fullname==undefined)
      return ''
    return fullname.substring(fullname.lastIndexOf('\\')+1)
  }

  downloadFile(fullPath:string){
      this.commonService.readFileFromURL(fullPath)
      .subscribe(async res => {
        const url= window.URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = url;
        link.download = fullPath.substring(fullPath.lastIndexOf('\\')+1);
        link.click();
      });
    }

}
