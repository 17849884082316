import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatDatepicker, MatDatepickerInputEvent} from '@angular/material/datepicker';

@Component({
  selector: 'multiple-dates-picker',
  templateUrl: './multiple-dates-picker.component.html',
  styleUrls: ['./multiple-dates-picker.component.css']
})
export class MultipleDatesPickerComponent implements OnInit {
  date = new Date();
  CLOSE_ON_SELECTED = false;
  init = new Date();
  resetDatesSelected = new Date(0);

  @Input() disabled: boolean;
  @Input() datesSelected = [];
  @ViewChild('picker', { static: true }) _picker: MatDatepicker<Date>;
  @Output() eventEmitter = new EventEmitter();

  constructor() {
    if (this.disabled) {
      this.disabled = this.disabled
    }
    else {
      this.disabled = false;
    }
  }

  ngOnInit(): void {
    console.log(this.datesSelected)
  }

  public dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return [ 'selected' ];
    }
    return [ ];
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (event.value) {
      const date = event.value;
      const index = this._findDate(date);
      if (index === -1) {
        this.datesSelected.push(date);
      } else {
        this.datesSelected.splice(index, 1)
      }
      this.resetDatesSelected = new Date(0);
      if (!this.CLOSE_ON_SELECTED) {
        const closeFn = this._picker.close;
        this._picker.close = () => { };
        this._picker['_popupComponentRef'].instance._calendar.monthView._createWeekCells()
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }
    }

    this.eventEmitter.emit(this.datesSelected)
  }

  public remove(date: Date): void {
    const index = this._findDate(date);
    this.datesSelected.splice(index, 1)
  }

  private _findDate(date: Date): number {
    return this.datesSelected.map((m) => +m).indexOf(+date);
  }


}
