import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError,EMPTY } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { Countries } from '../models/holiday.models';
import { Country } from '../models/country.models';
import { State } from '../models/state.model';
import { async } from 'rxjs/internal/scheduler/async';
import { read } from 'fs';
import { ToastrService } from 'ngx-toastr';
import {FormBuilder, FormGroup,FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { NewEvent, GetEvent, CalendarHolidaySync } from 'src/app/pages/dashboard/calendar/event.model';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private getCountryURL = environment.apiBaseUrl + 'ownerUnit/getallcountry';
  private getStatesURL = environment.apiBaseUrl + 'ownerUnit/getallState';
  private getEventURL = environment.apiBaseUrl + 'calendar/getallcalendarevent';
  private deleteFileUrl = environment.apiBaseUrl + 'siteData/deleteattachmentfile';
  private addEvent = environment.apiBaseUrl + 'calendar/addupdatecalendarevent';
  private calendarholidaysync = environment.apiBaseUrl + 'calendar/calendarholidaysync';
  private getAllCalendarHoliday = environment.apiBaseUrl + 'calendar/getallcalendarholiday';
  private events: GetEvent[] = []; // Property to hold current events

  constructor(private http: HttpClient,
              private toastr: ToastrService,) { }

  getCountrys(): Observable<Country[]> {
    return this.http.get<Country[]>(this.getCountryURL).pipe(
      map((data: Country[]) => {
        data = data.map((item: any) => ({
          CountryName: item.countryName,
          CountryId: item.countryId
        }));
        return data;
      }),
      catchError(this.handleError)
    )
  }

  getCountries(): Observable<Countries[]> {
    return this.http.get<Countries[]>(this.getCountryURL).pipe(
      map((data: Countries[]) => {
        data = data.map((item: any) => ({
          CountryCode: item.countryCode,
          CountryName: item.countryName,
          CountryId: item.countryId,
          CountryCurrency: item.countryCurrency,
          CountryCurrencyName: item.countryCurrencyName,
          CountryIso: item.countryIso,
          CountryPhoneCode: item.countryPhoneCode,
          CountryRegion: item.countryRegion,
        }));
        return data;
      }),
      catchError(this.handleError)
    )
  }

  getAllEvent(siteId: number): Observable<GetEvent[]> {
    const eventUrl = this.getEventURL + '/' + siteId;
    // Clear existing events
    this.events = [];
    return this.http.get<GetEvent[]>(eventUrl).pipe(
      tap(data => {
        // Update the events property with new data
        this.events = data;
      }),
        catchError(this.handleError)
      );
  }

  getAllCountries(): Observable<any[]> {
    return this.http.get<any[]>(this.getCountryURL).pipe()
  }
  getStates(): Observable<State[]> {
    // return this.http.get<State[]>(this.getStatesURL + `/${countryName}`)
    return this.http.get<State[]>(this.getStatesURL).pipe(
      map((data: State[]) => {
        data = data.map((item: any) => ({
          StateName: item.stateName,
          CountryName: item.countryName
        }));
        return data;
      }),
      catchError(this.handleError)
    )
  }


  countFileUploadedSizeText(FileUploadedName, FileUploadedByte): string {
    let FileUploadedSizeText = '';
    if (FileUploadedName) {
      let FileUploadedSize = (((FileUploadedByte.length) * (3 / 4)) - 22) / 1000;
      //console.log('FileUploadedSize: ' + FileUploadedSize);

      FileUploadedSizeText = FileUploadedSize.toFixed(2) + " KB";
      if (FileUploadedSize > 1000) {
        FileUploadedSizeText = ((FileUploadedSize) / 1000).toFixed(2) + " MB";
      }
    }
    //console.log('FileUploadedSizeText: ' + FileUploadedSizeText);
    return FileUploadedSizeText;
  }

  onClickDownload(base64String, fileName) {
    const source = `${base64String}`;
    const link = document.createElement("a");
    link.href = source;
    link.download = `${fileName}`
    link.click();
  }

  readAssetFile(fileNameInAssetsFolder: string): Observable<any[]> {
    console.log('/assets/' + fileNameInAssetsFolder)
    return this.http.get<any>(
      '/assets/' + fileNameInAssetsFolder,
      { responseType: 'blob' as 'json' }
    )
  }

  readAssetFile_test(fileNameInAssetsFolder: string): Observable<any[]> {
    console.log('/assets/' + fileNameInAssetsFolder)
    return this.http.get<any>("/assets/" + fileNameInAssetsFolder, {
      responseType: "arraybuffer" as "json",
    });
  }

  readFileFromURL(URL: string): Observable<Blob> {
    if(URL != null && URL != undefined && URL != '')
      URL=URL.split("\\").join("/")
    return this.http.get<Blob>(
      encodeURI(URL),
      { responseType: 'blob' as 'json' }
    )
  }

  // deleteAttachmentFile(AttachmentId: number) {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   const url = `${this.deleteFileUrl}`;
  //   const body = {
  //     AttachmentId: AttachmentId,
  //   };
  //   //console.log('deleteAttachmentFile Start: ' + JSON.stringify(body));
  //   return this.http.post<any>(url, body, { headers })
  //     .pipe(
  //       tap(data => console.log('deleteAttachmentFile End: ' + AttachmentId)),
  //       catchError(this.handleError)
  //     );
  // }

  deleteAttachmentFile(Id: number): Observable<{}> {
    const url = `${this.deleteFileUrl}/${Id}`;
    console.log(url);
    return this.http.delete<any>(url)
      .pipe(
        //tap(data => console.log('deleteAttachmentFile End: ' + Task.TaskId)),
        catchError(this.handleError)
      );
  }

  generateArrayOfYears() {
    var max = new Date().getFullYear();
    var min = 2022;
    var years = [];

    for (var i = max; i >= min; i--) {
      years.push(i);
    }
    return years;
  }

  generateArrayOfMonths() {
    var monthList = [
      { Value: 1, Text: 'Jan', Full: 'January' },
      { Value: 2, Text: 'Feb', Full: 'February' },
      { Value: 3, Text: 'Mar', Full: 'March' },
      { Value: 4, Text: 'Apr', Full: 'April' },
      { Value: 5, Text: 'May', Full: 'May' },
      { Value: 6, Text: 'Jun', Full: 'June' },
      { Value: 7, Text: 'Jul', Full: 'July' },
      { Value: 8, Text: 'Aug', Full: 'August' },
      { Value: 9, Text: 'Sep', Full: 'September' },
      { Value: 10, Text: 'Oct', Full: 'October' },
      { Value: 11, Text: 'Nov', Full: 'November' },
      { Value: 12, Text: 'Dec', Full: 'December' }
    ];
    return monthList;
  }

  // generateArrayOfMonths() {
  //   var monthList = [
  //     { Value: 1, Text: 'Jan' },
  //     { Value: 2, Text: 'Feb' },
  //     { Value: 3, Text: 'Mar' },
  //     { Value: 4, Text: 'Apr' },
  //     { Value: 5, Text: 'May' },
  //     { Value: 6, Text: 'Jun' },
  //     { Value: 7, Text: 'Jul' },
  //     { Value: 8, Text: 'Aug' },
  //     { Value: 9, Text: 'Sep' },
  //     { Value: 10, Text: 'Oct' },
  //     { Value: 11, Text: 'Nov' },
  //     { Value: 12, Text: 'Dec' }
  //   ];
  //   return monthList;
  // }

  getDaysInMonth(month, year) {
    // Here January is 1 based
    //Day 0 is the last day in the previous month
    return new Date(year, month, 0).getDate();
    // Here January is 0 based
    // return new Date(year, month+1, 0).getDate();
  };

  addNewEvent(event: NewEvent, siteid: number){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.addEvent}`; 
    return this.http.post<NewEvent>(url, event, { headers, observe: 'response' })
      .pipe(
        tap((response) =>{
          if (response.status === 200) {
            console.log('Response Status:', response.status);
            console.log('Added New Event');
            this.getAllEvent(siteid);
          }
          else
          {
            console.log('Add New Event Failed', response.status);
          }
        }),
        catchError(this.handleError)
      );
  }

  getallcalendarholiday(): Observable<CalendarHolidaySync[]>{
    const url = `${this.getAllCalendarHoliday}`; 
    return this.http.get<CalendarHolidaySync[]>(url).pipe(
      tap(data => {
        // Log the received data
        //console.log('Received data:', data); 
        }),
        map((data: CalendarHolidaySync[]) => {
        const calendarHolidays = data.map((item: CalendarHolidaySync) => ({
          CalendarHolidayId: item.CalendarHolidayId,
          Country: {
            CountryCode: item.Country.CountryCode,
            CountryName: item.Country.CountryName,
            CountryCurrency: item.Country.CountryCurrency || null,
            CountryCurrencyName: item.Country.CountryCurrencyName || null,
            CountryIso: item.Country.CountryIso || null,
            CountryId: item.Country.CountryId,
            CountryPhoneCode: item.Country.CountryPhoneCode || null,
            CountryRegion: item.Country.CountryRegion,
          },
          CreatedOn: item.CreatedOn,
          Description: item.Description,
          HolidayDate: item.HolidayDate,
          Location: item.Location,
          Name: item.Name,
          PrimaryType: item.PrimaryType,
          Type: item.Type,
        }));
        return calendarHolidays; 
      }),
      catchError(err => {
        console.error('Error occurred:', err); // Log the error
        return this.handleError(err);
      })
    );
  }

  syncCalendarHoliday(arrcalendarHolidaySync: any){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.calendarholidaysync}`; 
    return this.http.post<any>(url, arrcalendarHolidaySync, { headers })
      .pipe(
        tap(() => console.log('Synced Calendar Holidays')),
        catchError(this.handleError)
      );
  }

  public ErrorNotification(errMsg:string){
    this.toastr.error(errMsg);
  }

  public InfoNotification(info:string){
    this.toastr.info(info);
  }  
  
  public SuccessNotification(success:string){
    this.toastr.success(success);
  }

  public WarningNotification(success:string){
    this.toastr.warning(success);
  }
  
  validateAllFormFields(formGroup: FormGroup) {       
    Object.keys(formGroup.controls).forEach(field => { 
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control); 
      }
  });
}
  
  public handleError(err: HttpErrorResponse): Observable<never> {
    // in a real world app, we may send the server to some remote logging infrastructure
    // instead of just logging it to the console
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      if(err.status==404)
        return EMPTY;
      errorMessage = `Server returned code: ${err.status}, error message is: ${err?.error?.ExceptionMessage}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }

  getFileNameFromPath(PhotoPath) {
    //PhotoPath = "https://propertygaga.eemm.com.my/Uploads/Checklist/Check/80/20230402002159_avatar-9.jpg";
    var PhotoPathSplit = PhotoPath.split('/');
    //console.log('PhotoPathSplit : ' + JSON.stringify(PhotoPathSplit));
    var FileName = PhotoPathSplit[PhotoPathSplit.length-1];
    //console.log('FileName : ' + FileName);
    return FileName;
  }

  toDataURL = async(url)=>{
    var res = await fetch(url);
    var blob = await res.blob();

    const result = await new Promise((resolve, reject)=>{
      var reader = new FileReader();
      reader.addEventListener("load",function(){
        resolve(reader.result);
      }, false);

      reader.onerror = ()=>{
        return reject(this);
      };
      reader.readAsDataURL(blob);
    })
    return result
  }

  isoDateWithoutTimeZone(date) {
  if (date == null || date =="") return date;

  var tempDate = new Date(date);
  var timestamp = tempDate.getTime() - tempDate.getTimezoneOffset() * 60000;
  var correctDate = new Date(timestamp);
  correctDate.setUTCHours(0, 0, 0, 0); // uncomment this if you want to remove the time
  return correctDate.toISOString();
}

  isoDateTimeWithoutTimeZone(date) {
  if (date == null || date =="") return date;

  var tempDate = new Date(date);
  var timestamp = tempDate.getTime() - tempDate.getTimezoneOffset() * 60000;
  var correctDate = new Date(timestamp);
  return correctDate.toISOString();
}

getMalaysiaBanks() {
  let banks = [
    'AFFIN BANK BERHAD',
    'AFFIN INVESTMENT BANK BERHAD',
    'AFFIN ISLAMIC BANK BERHAD',
    'AL RAJHI BANKING & CORPORATION (MALAYSIA) BHD',
    'AL RAJHI BANKING & CORPORATION (MALAYSIA) BHD - IIB',
    'ALLIANCE BANK MALAYSIA BERHAD',
    'ALLIANCE INVESTMENT BANK BERHAD',
    'ALLIANCE ISLAMIC BANK BERHAD',
    'AMBANK (M) BERHAD',
    'AMINVESTMENT BANK BERHAD',
    'AMISLAMIC BANK BERHAD',
    'ASIAN FINANCE BANK BERHAD',
    'BANGKOK BANK BERHAD',
    'BANK ISLAM MALAYSIA BERHAD',
    'BANK MUAMALAT (MALAYSIA) BERHAD',
    'BANK OF AMERICA MALAYSIA BERHAD',
    'BANK OF CHINA (MALAYSIA) BERHAD',
    'BANK OF TOKYO-MITSUBISHI UFJ (MALAYSIA) BERHAD',
    'CIMB BANK BERHAD',
    'CIMB INVESTMENT BANK BERHAD',
    'CIMB ISLAMIC BANK BERHAD',
    'CITIBANK BERHAD',
    'DEUTSCHE BANK (MALAYSIA) BERHAD',
    'ECM LIBRA INVESTMENT BANK BERHAD',
    'EON BANK BERHAD',
    'EONCAP ISLAMIC BANK BERHAD',
    'HONG LEONG BANK BERHAD',
    'HONG LEONG INVESTMENT BANK BERHAD',
    'HONG LEONG ISLAMIC BANK BERHAD',
    'HSBC AMANAH MALAYSIA BERHAD',
    'HSBC BANK MALAYSIA BERHAD',
    'HWANG-DBS INVESTMENT BANK BERHAD',
    'J.P MORGAN CHASE BANK BERHAD',
    'KAF INVESTMENT BANK BERHAD',
    'KENANGA INVESTMENT BANK BERHAD',
    'KUWAIT FINANCE HOUSE (MALAYSIA) BERHAD',
    'MALAYAN BANKING BERHAD',
    'MAYBANK INVESTMENT BANK BERHAD',
    'MAYBANK ISLAMIC BERHAD',
    'MIDF AMANAH INVESTMENT BANK BERHAD',
    'MIMB INVESTMENT BANK BERHAD',
    'OCBC AL-AMIN BANK BERHAD',
    'OCBC BANK (MALAYSIA) BERHAD',
    'OSK INVESTMENT BANK BERHAD',
    'PT BANK SYARIAH MUAMALAT INDONESIA TBK - IIB',
    'PUBLIC BANK BERHAD',
    'PUBLIC INVESTMENT BANK BERHAD',
    'PUBLIC ISLAMIC BANK BERHAD',
    'RHB BANK BERHAD',
    'RHB INVESTMENT BANK BERHAD',
    'RHB ISLAMIC BANK BERHAD',
    'STANDARD CHARTERED BANK MALAYSIA BERHAD',
    'STANDARD CHARTERED SAADIQ BERHAD',
    'THE BANK OF NOVA SCOTIA BERHAD',
    'THE ROYAL BANK OF SCOTLAND BERHAD',
    'UNICORN INTERNATIONAL ISLAMIC BANK MALAYSIA - IIB',
    'UNITED OVERSEAS BANK (MALAYSIA) BHD.',
  ]
  return banks;
}

  async getHttpHeader() {
 const headers= new HttpHeaders({
    'Content-Type':  'application/json',
    'Authorization': 'Bearer ' + 'token'
  })
    return headers;
  }
}
