import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError, EMPTY } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { RentalParking, RentalParkingDelete, RentalParkingList } from '../models/rentalParking.models';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class RentalParkingService {
  private getRentalParkingBySiteUrl = environment.apiBaseUrl + 'ownerUnit/getRentalParkingBySite';
  private getRentalParkingByIDUrl = environment.apiBaseUrl + 'ownerUnit/getRentalParkingById';
  private addRentalParkingUrl = environment.apiBaseUrl + 'ownerUnit/addRentalParking';
  private updateRentalParkingUrl = environment.apiBaseUrl + 'ownerUnit/updateRentalParking';
  private deleteRentalParkingUrl = environment.apiBaseUrl + 'ownerUnit/deleteRentalParking';

  private getRentalParkingListBySiteUrl = environment.apiBaseUrl + 'ownerUnit/getRentalParkingListBySite';
  private getRentalParkingListByIDUrl = environment.apiBaseUrl + 'ownerUnit/getRentalParkingListById';
  private addRentalParkingListUrl = environment.apiBaseUrl + 'ownerUnit/addRentalParkingList';
  private updateRentalParkingListUrl = environment.apiBaseUrl + 'ownerUnit/updateRentalParkingList';
  private deleteRentalParkingListUrl = environment.apiBaseUrl + 'ownerUnit/deleteRentalParkingList';
  private importRentalParkingUrl = environment.apiBaseUrl + 'ownerUnit/importrentalparking';


  
  constructor(
    private http: HttpClient,
    private commonService: CommonService) { }

  getRentalParkingBySite(siteId: number): Observable<RentalParking[]> {
    const url = `${this.getRentalParkingBySiteUrl}/${siteId}`;
    //console.log(url);
    return this.http.get<RentalParking[]>(url)
      .pipe(
        tap(data =>
          //console.log('getRentalParkingBySite: ' + JSON.stringify(data))
          console.log('getRentalParkingBySite: ')
        ),
		//this.combineEntity = data.TermNo + ' (' + data.TermStartDate + ' - ' + data.TermStartDate + ')';
        catchError(this.commonService.handleError)
      );
  }

  getRentalParkingByID(Id: number): Observable<RentalParking> {
    const url = `${this.getRentalParkingByIDUrl}/${Id}`;
    console.log(url);
    return this.http.get<RentalParking>(url)
      .pipe(
        tap(data =>
          //console.log('getRentalParkingByID: ' + JSON.stringify(data))
          console.log('getRentalParkingByID: ')
        ),
        catchError(this.commonService.handleError)
      );
  }

  addRentalParking(RentalParking: RentalParking) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //console.log('addRentalParking Start: ' + JSON.stringify(RentalParking));
    //return this.http.post(this.addUrl,body).pipe(catchError(this.commonService.handleError));
    return this.http.post(this.addRentalParkingUrl, RentalParking, { headers })
      .pipe(
        tap(data => console.log('addRentalParking End: ' + JSON.stringify(data))),
        catchError(this.commonService.handleError)
      );
  }

  updateRentalParking(RentalParking: RentalParking) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.updateRentalParkingUrl}`;
    return this.http.post<any>(url, RentalParking, { headers })
      .pipe(
        //tap(() => console.log('updateRentalParking End: ' + RentalParking.RentalParkingId)),
        map(() => RentalParking),
        catchError(this.commonService.handleError)
      );
  }

  // deleteRentalParking(RentalParking: RentalParkingDelete): Observable<{}> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   //console.log('deleteRentalParking Start: ' + JSON.stringify(body));
  //   const url = `${this.deleteRentalParkingUrl}`;
  //   return this.http.post<RentalParkingDelete>(url, RentalParking, { headers })
  //     .pipe(
  //       //tap(data => console.log('deleteRentalParking End: ' + RentalParking.RentalParkingId)),
  //       catchError(this.commonService.handleError)
  //     );
  // }

  deleteRentalParking(Id: number): Observable<{}> {
    const url = `${this.deleteRentalParkingUrl}/${Id}`;
    console.log(url);
    return this.http.delete<any>(url)
      .pipe(
        //tap(data => console.log('deleteRentalParking End: ' + Task.TaskId)),
        catchError(this.commonService.handleError)
      );
  }

  getRentalParkingListBySite(siteId: number): Observable<RentalParkingList[]> {
    const url = `${this.getRentalParkingListBySiteUrl}/${siteId}`;
    //console.log(url);
    return this.http.get<RentalParkingList[]>(url)
      .pipe(
        tap(data =>
          //console.log('getRentalParkingListBySite: ' + JSON.stringify(data))
          console.log('getRentalParkingListBySite: ')
        ),
		//this.combineEntity = data.TermNo + ' (' + data.TermStartDate + ' - ' + data.TermStartDate + ')';
        catchError(this.commonService.handleError)
      );
  }

  getRentalParkingListByID(Id: number): Observable<RentalParkingList> {
    const url = `${this.getRentalParkingListByIDUrl}/${Id}`;
    console.log(url);
    return this.http.get<RentalParkingList>(url)
      .pipe(
        tap(data =>
          //console.log('getRentalParkingListByID: ' + JSON.stringify(data))
          console.log('getRentalParkingListByID: ')
        ),
        catchError(this.commonService.handleError)
      );
  }

  addRentalParkingList(RentalParkingList: RentalParkingList) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    //console.log('addRentalParkingList Start: ' + JSON.stringify(RentalParkingList));
    //return this.http.post(this.addUrl,body).pipe(catchError(this.commonService.handleError));
    return this.http.post(this.addRentalParkingListUrl, RentalParkingList, { headers })
      .pipe(
        tap(data => console.log('addRentalParkingList End: ' + JSON.stringify(data))),
        catchError(this.commonService.handleError)
      );
  }

  updateRentalParkingList(RentalParkingList: RentalParkingList) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.updateRentalParkingListUrl}`;
    return this.http.post<any>(url, RentalParkingList, { headers })
      .pipe(
        //tap(() => console.log('updateRentalParkingList End: ' + RentalParkingList.RentalParkingListId)),
        map(() => RentalParkingList),
        catchError(this.commonService.handleError)
      );
  }

    importRentalParking(RentalParkingList: RentalParking[]) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.importRentalParkingUrl}`;
    return this.http.post<any>(url, RentalParkingList, { headers })
      .pipe(
        //tap(() => console.log('updateRentalParkingList End: ' + RentalParkingList.RentalParkingListId)),
        map(() => RentalParkingList),
        catchError(this.commonService.handleError)
      );
  }

  // deleteRentalParkingList(Id: number): Observable<{}> {
  //   const url = `${this.deleteRentalParkingListUrl}/${Id}`;
  //   console.log(url);
  //   return this.http.delete<any>(url)
  //     .pipe(
  //       //tap(data => console.log('deleteRentalParkingList End: ' + Task.TaskId)),
  //       catchError(this.commonService.handleError)
  //     );
  // }

}