import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { catchError , tap , map, filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {View} from './../models/user.models'



@Injectable({
  providedIn: 'root',
})
export class ViewlistService {
  private endpoints = new BehaviorSubject<View[] | null>(null);
  readonly api$ = this.endpoints.asObservable().pipe(
    filter((endpoints) => !!endpoints),
    map((endpoints) => endpoints)
  );

  get api() {
    return this.endpoints.getValue();
  }

  constructor(private http: HttpClient) {}

  async fetchEndpoints() {
    await this.http.get<View[]>(environment.apiBaseUrl + 'authUser/getallview')
      .toPromise().then(
        (endpoints) => this.endpoints.next(endpoints)
      ).catch(
        (err) => console.log(err)
      );
  }
}