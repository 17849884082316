import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { SIDEBAR_WIDTH_CONDENSED } from '../../layout.model';
import {User} from '../../../core/models/user.models';
import {Subscription} from 'rxjs';
import {UserService} from '../../../core/services/user.service';
import {CommonService} from '../../../core/services/common.service';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css'],

})
export class LeftsidebarComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  @Input() sidebarType: string;
  userListLink = '/user';
  rolesListLink = '/roles';
  userAccountPageLink = '/user/user-account';
  blacklistLink = 'apps/blacklist';
  sitesLink = '/sites'
  humanResourceLink = '/human-resource'
  user: User;
  userInitials = '';
  userData: User;
  userId: number;
  siteId: number;
  profilePictureByte;
  showMenu = false;
  showUserAndRoleSettings = false;

  showBlackList = false;
  showBlacklistSuperAdmin = false;
  showSites = false;
  showStaffAllocation = false;
  showLoginTracking = false;
  showPaySlip = false;
  showHumanResource = false;
  showMyAccount = false;

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private commonService: CommonService,
              private userService: UserService) { }

  ngOnInit() {
    this.user = this.authenticationService.currentUser();
    const separatedNames = this.user.UserIdentityName.split(' ')
    for (const name of separatedNames) {
      this.userInitials = this.userInitials.concat(Array.from(name)[0]);
    }
    this.userId = this.user.UserId;

    this.subscriptions.push(
      this.userService.getUserDetails(this.userId).subscribe({
        next: data => {
          this.userData = data
            if(this.userData.UserProfilePhoto!= null || this.userData.UserProfilePhoto != undefined){
              // console.log(this.userData.UserProfilePhoto)
                this.commonService.readFileFromURL(this.userData.UserProfilePhoto)
                      .subscribe(async res => {

                        const reader = new FileReader();
                          reader.readAsDataURL(res); 
                          reader.onloadend = () => {
                          const base64data = reader.result;
                        //this.fileObj.fileBase64=base64data.toString();
                        this.profilePictureByte =base64data.toString();
                          }
                      });
          }
           this.setMenu()
          
        },
        error: err => console.error(err)
      })
    )

    this.subscriptions.push(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          this.setMenu()
          
        }
      })
    )
    
    this.siteId = Number(localStorage.getItem('siteId'));
    if((this.user.Role.RoleName=='Super')&&(this.siteId==0)){
      this.blacklistLink = 'apps/blacklist/Super';
    }
    else{
      this.blacklistLink = 'apps/blacklist/Normal';
    }

  }

  
  setMenu() {
    this.siteId = Number(localStorage.getItem('siteId'));
    // if on sites/roles/userAccount page then dont show menu
    if (this.router.url.includes('sites') || this.router.url.includes('roles') || this.router.url.includes('user')|| this.router.url.includes('human-resource')|| this.router.url.includes('blacklist')) {
      this.showMenu = false;

      // if site selected then show menu for userAccount page
      if ((!(isNaN(this.siteId) || this.siteId == null || this.siteId === 0) && this.router.url.includes('user'))) {
        this.showMenu = true;

      }

      // if not superadmin and GM then dont show User And Role Settings
      if (this.userData.Role.RoleName === 'Super' || this.userData.Role.RoleName === 'GM') {
        this.showUserAndRoleSettings = true;
      }
    }

    // if on other pages then show menu
    else {
      this.showMenu = true
      this.showUserAndRoleSettings = false;
    }

    if (((isNaN(this.siteId) || this.siteId == null || this.siteId === 0)))
    {
      if (this.userData.Role.RoleName === 'Super' || this.userData.Role.RoleName === 'GM'|| this.userData.Role.RoleName === 'BM') {
        this.showStaffAllocation = true;
        this.showLoginTracking = true;
        this.showPaySlip = true;
        this.showHumanResource = true;
      }
      
        this.showSites = true;
        this.showMyAccount = true;
        this.showBlackList = true;

    }
    else{
        this.showSites = false;
        this.showStaffAllocation = false;
        this.showLoginTracking = false;
        this.showPaySlip = false;
        this.showHumanResource = false;
        this.showMyAccount = true;
        this.showBlackList = false;
    }
  }

  /**
   * Is sidebar condensed?
   */
  isSidebarCondensed() {
    return this.sidebarType === SIDEBAR_WIDTH_CONDENSED;
  }

  /**
   * Logout the user
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: '/' } });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
