import { Component, OnInit, AfterViewInit, ViewChild, Inject, ViewChildren, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { RentalParkingService } from 'src/app/core/services/rentalParking.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RentalParkingList } from 'src/app/core/models/rentalParking.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';

import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {VIEW_LIST_CONST} from 'src/app/core/models/user.models';
import {UserService} from 'src/app/core/services/user.service';


@Component({
    selector: 'app-maintenance',
    templateUrl: './maintenance.html',
    styleUrls: ['maintenance.css'],
})
export class MaintenanceComponent implements OnInit, OnDestroy {

    userEditRentalParkingAllowedAccess: boolean = false;

    mainListLink = '/apps/maintenance';
    mainEditLink = '/apps/maintenance-form';
    filterForm!: FormGroup;
    siteIdLogin: number;
    errorMessage = '';
    private subscription: Subscription;
    currentDate = new Date();
    user: any;
    RentalParkings: any[] = [];

    filterValues = {};
    tableDataSource = new MatTableDataSource();
    displayedColumns: string[] = ['Id', 'UnitName', 'OccupantName', 'Content', 'Status', 'CreateTime', 'Action'];

    pageSize: number = 10;
    pageNo: number = 0;

    constructor(private authenticationService: AuthenticationService,
        private fb: FormBuilder,
        public dialog: MatDialog,
        private datePipe: DatePipe) {}

    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    get f() { return this.filterForm.controls; }

    ngOnInit(): void {
        this.filterForm = this.fb.group({
            filterStartDate: '',
            filterEndDate: '',
            filterStatus: '',
            searchBox: ''
        });
        this.siteIdLogin = Number(localStorage.getItem('siteId'));
        // set user access
        this.user = this.authenticationService.currentUser();
        this.getDataList();
    }

    async getDataList() {
        let str = localStorage.getItem("renovations");
        let arr: any;
        if(str){
            arr = JSON.parse(str);
        }else{
            arr = [];
        }
        this.tableDataSource = new MatTableDataSource(arr);
    }


    applyFilter() {
        //console.log('filterForm: ' + this.f.searchBox.value);
        const filterValue = this.f.searchBox.value;
        this.tableDataSource.filter = filterValue.trim().toLowerCase();
        // sessionStorage.setItem('filterValueRentalParkingList', filterValue);
    }

    transformDateEng(date) {
        if (date)
            return this.datePipe.transform(date, 'dd MMM yyyy');
        else
            return "";
    }

    transformDateShort(date) {
        if (date)
            return this.datePipe.transform(date, 'yyyy-MM-dd');
        else
            return "";
    }

    // transformYesNo(value) {
    //     return value == true ? 'Yes' : 'No';
    // }

    // isDateBigger(endDate) {
    //     let localStart = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    //     let localEnd = this.datePipe.transform(endDate, 'yyyy-MM-dd');

    //     if (localStart > localEnd)
    //         return "Pass";
    //     else
    //         return "Current";
    // }

    pageEvents(event: any) {
        //console.log(' ,pageSize: ' + event.pageSize + ' ,pageIndex: ' + event.pageIndex);
        // sessionStorage.setItem('pageSizeRentalParkingList', event.pageSize);
        // sessionStorage.setItem('pageIndexRentalParkingList', event.pageIndex);
      }

    ngOnDestroy() {
        // this.subscription.unsubscribe();
    }

    doDelete(ele: any, index: any){
        // console.log("index:" + index);
        let str = localStorage.getItem("renovations");
        let arr: any = JSON.parse(str);
        arr.splice(index);
        // console.log(issueArr);
        this.tableDataSource = new MatTableDataSource(arr);
        localStorage.setItem("renovations", JSON.stringify(arr));
    }
}
