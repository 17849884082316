import { Component, OnInit } from '@angular/core';
import { Vendor } from 'src/app/core/models/vendor.models';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from 'src/app/core/services/vendor.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.css']
})
export class VendorDetailsComponent implements OnInit {

  vendorLoad: Vendor = new Vendor();
  id: number;
  private sub!: Subscription;
  errorMessage = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private vendorService: VendorService,
    private authenticationService: AuthenticationService,
  ) { }

  ngOnInit(): void {
    this.sub = this.route.paramMap.subscribe(
      params => {
        this.id = Number(this.route.snapshot.paramMap.get('id'));
        // console.log("The ID: " + this.id);

        this.displayVendor();
      }
    );
  }

  displayVendor(): void {
    if (this.id > 0) {
      this.vendorService.getVendorByID(this.id).subscribe({
        next: data => {
          this.vendorLoad = data;
          //console.log('Load data : ' + JSON.stringify(this.vendorLoad));
        },
        error: err => this.errorMessage = err
      });

    } else {
      this.router.navigate(['/apps/service-provider']);
    }
  }
}
