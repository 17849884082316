import {Vehicle} from './vehicle.models';

export class RentalParking {
  RentalParkingId: number | null;
  ParkingLotName: string;
  SiteId: number;
  LocationMap: string;
  LocationMapByte: string;
  MapFileName: string;
  PreBookingHour: number;
  DepositPayment: number;
  RentalTerm: string;
  RentalPayment: number;
  UpdatedBy: string;
  UpdatedOn: string;
  ErrorMsg?: string;
}

export class RentalParkingDelete{
  RentalParkingId: number;
}

export class RentalParkingList {
  RentalParkListId: number | null;
  VehicleData?:Vehicle | null;
  RentalParkingData: RentalParking | null;
  OccupantData?:any | null;
  RentalTerm: string;
  RentalDuration: number;
  RentalStartDate: string;
  RentalEndDate: string | null;
  UpdatedBy: string;
  UpdatedOn: string;
  PaymentStatus: string | null;
  RentalStartDateShort: string | null;
  RentalEndDateShort: string | null;
  VehicleId: number | null;
  OccupantId: number | null;
  RentalParkingId: number | null;
}