import { Component, OnInit, AfterViewInit, ViewChild, Inject, ViewChildren, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { VendorService } from '../../../core/services/vendor.service';
import { Vendor } from '../../../core/models/vendor.models';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { ServiceProviderDeleteDialogComponent } from './service-provider-delete-dialog/service-provider-delete-dialog.component';
import {AuthenticationService} from '../../../core/services/auth.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['vendor.component.css']
})
export class VendorComponent implements OnInit, OnDestroy  {

  filterForm!: FormGroup;
  siteIdLogin: number;
  errorMessage = '';
  private subscription: Subscription;
  currentDate = new Date();
  VendorServices: any[] = [];
  allVendor:Vendor[]
  totalVendor = 0;
  VendorServicesSelected = 'All Services';
  userEditVendorAllowedAccess: boolean = false;

  vendorFilter = new MatTableDataSource();
  // displayedColumns: string[] = ['VendorName', 'VendorContact', 'VendorService', 'VendorContractEndDate', 'VendorMonthlyFee', 'OutStandingPayment','Status', 'Action'];
  displayedColumns: string[] = ['VendorName', 'VendorContact', 'VendorService', 'VendorContractEndDate', 'Status', 'VendorMonthlyFee', 'OutStandingPayment'];

  pageSize: number = 10;
  pageNo: number = 0;

  constructor(private vendorService: VendorService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public authenticationService: AuthenticationService,
    private datePipe: DatePipe) { }

  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  get f() { return this.filterForm.controls; }

  ngOnInit(): void {

    this.siteIdLogin = Number(localStorage.getItem('siteId'));
    this.getVendorBySite();
    this.userEditVendorAllowedAccess = this.authenticationService.isAllowAccess('Edit Vendor');

    this.filterForm = this.fb.group({
      searchBox: '',
      filterVendorService: ''
    });

    if (sessionStorage.getItem('pageIndexVendor')) {
      this.pageSize = Number(sessionStorage.getItem('pageSizeVendor'));
      this.pageNo = Number(sessionStorage.getItem('pageIndexVendor'));
      //console.log('pageSizeVendor: ' + this.pageSize + ' ,pageIndexVendor: ' + this.pageNo);
    }
    if (sessionStorage.getItem('filterValueVendor')) {
      this.filterForm.patchValue({
        searchBox: sessionStorage.getItem('filterValueVendor')
      });
    }

    this.vendorService.getAllVendorCategory().subscribe({
      next: VendorServices => {
        this.VendorServices = VendorServices;
        //console.log(this.VendorServices);
      },
      error: err => this.errorMessage = err
    });
  }

  // getProperty = (obj, path) => (
  //   path.split('.').reduce((o, p) => o && o[p], obj)
  // )

  getVendorBySite() {
    this.subscription = this.vendorService.getVendorBySite(this.siteIdLogin).subscribe({
      next: data => {

        const filterVendorService =this.f.filterVendorService.value;
        this.totalVendor = data.length;

        data.map(vendors => {
          if(filterVendorService) {
            data = data.filter(i => i.VendorService == filterVendorService);
            this.VendorServicesSelected = filterVendorService;
          }
          else{
            data = data;
            this.VendorServicesSelected = 'All Services';
          }
          });
        this.allVendor=data;
        data = data.filter(i =>i.Terminated==null || i.Terminated == false);

        this.vendorFilter = new MatTableDataSource(data);
        //this.vendorFilter = new MatTableDataSource<Element>(ELEMENT_DATA);

        this.vendorFilter.filterPredicate = (data2: any, filter: string) => {
          return data2.VendorName.toLocaleLowerCase().includes(filter) ||
          data2.VendorService.toLocaleLowerCase().includes(filter) ||
          data2.VendorContact.toLocaleLowerCase().includes(filter)||
          this.transformDateEng(data2.VendorContractEndDate).toLocaleLowerCase().includes(filter)||
          data2.VendorMonthlyFee?.toString().includes(filter);
        }

       // this.vendorFilter.sortingDataAccessor = (obj, property) => this.getProperty(obj, property);

       this.vendorFilter.sortingDataAccessor = (item: any, property) => {
        switch(property) {
          default: return item[property];
        }
      };

        this.vendorFilter.sort = this.sort;
        this.vendorFilter.paginator = this.paginator;
        this.applyFilter();
      },
      error: err => this.errorMessage = err
    });
  }

  DeleteVendor(element){
    if(element.Active==true)
       element.Active=false;
    else
      element.Active=true;
  }

  UpdateVendor(element){
    let vendorupdate= this.allVendor.find(x=>x.VendorID==element.VendorID)
    if(element.Active==true)
    {
       element.Active=false;
       vendorupdate.Active=false;
    }
    else{
      vendorupdate.Active=true;
      element.Active=true;
    }
      console.log(vendorupdate);
     this.vendorService.updateVendor(vendorupdate)
                    .subscribe({
                        next: x => {
                            console.log(x);
                        },
                        error: err => this.errorMessage = err
                    });
  }

  openDialogDelete(element) {
    let deleteVendor= this.allVendor.find(x=>x.VendorID==element.VendorID)
        //console.log('action : ' + obj.action);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '550px';
        dialogConfig.height = 'auto';
        dialogConfig.data = deleteVendor;
        const dialogRef = this.dialog.open(ServiceProviderDeleteDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
          if (result != false) {
            this.getVendorBySite();
          }
        });
      }

  applyFilter() {
    //console.log('filterForm: ' + this.f.searchBox.value);
    const filterValue =this.f.searchBox.value;
    this.vendorFilter.filter = filterValue.trim().toLowerCase();
    sessionStorage.setItem('filterValueVendor', filterValue);
  }

  vendorDateStatus(endDate) {
    return this.vendorService.vendorDateStatus(endDate);
  }


  transformDateEng(date) {
    if(date)
      return this.datePipe.transform(date, 'dd MMM yyyy');
    else
      return "";
  }

  pageEvents(event: any) {
    //console.log(' ,pageSize: ' + event.pageSize + ' ,pageIndex: ' + event.pageIndex);
    sessionStorage.setItem('pageSizeVendor', event.pageSize);
    sessionStorage.setItem('pageIndexVendor', event.pageIndex);
  }

  ngOnDestroy(){
    this.subscription.unsubscribe();
  }
}
