export class Notification
  {
    NotificationStatusId: number;
    NotificationLogId: number;
    UserId: number;
    IsRead: boolean;
    UpdatedOn: string;
    NotificationLogInfo: NotificationLog
  }

  export class NotificationLog
  {
      NotificationLogId: number;
      SiteId: number;
      Module: string;
      NotificationDescription: string;
      CreatedOn: string;
      LinkToPage: string;
  }

  export class NotificationByDate
  {

    NotificationDate:string;
    Notifications: Notification[]= new Array<Notification>();
    
  }