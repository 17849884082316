import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChartOfAccountService } from 'src/app/core/services/account/chart-of-account.service';
import { AccountDetail,ChartOfAccountType } from '../../../../core/models/account/chartOfAccount.models';
import { User } from 'src/app/core/models/user.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import {formatDate} from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';

@Component({
  selector: 'app-account-sub-dialog',
  templateUrl: './account-sub-dialog.component.html',
  styleUrls: ['./account-sub-dialog.component.css']
})
export class AccountSubDialogComponent implements OnInit {
  currentUser: User;
  siteId = Number(localStorage.getItem("siteId"));
  isAccountTypeEnabled: boolean = false;
  isAccountGroupDisabled: boolean = false;
  isDeleteHidden: boolean = false;
  isSaveHidden: boolean = false;
  isBtnSaveDisabled: boolean = false;
  // accountTypes: string[] = this.chartOfAccountService.accountTypeList;
  // accountGroups: string[] = this.chartOfAccountService.accountGroupList;
  ChartOfAccountType:ChartOfAccountType[] =  this.chartOfAccountService.chartOfAccountType
  selectedChartOfAccountType:ChartOfAccountType;
  accountForm: FormGroup;
  errorMessage = '';
  infoMessage = '';
  title = '';
  coaId = 0;
  isArchieved = false;
  submitted = false;


  constructor(
    @Inject(LOCALE_ID) private locale: string,
    @Inject(MAT_DIALOG_DATA) public data: any = null,
    private formBuilder: FormBuilder,
    private matDialogRef: MatDialogRef<AccountSubDialogComponent>,
    private chartOfAccountService: ChartOfAccountService,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
  ) {}

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUser();
    if (this.data.status == "Edit") {
      this.title = 'Edit';
      this.isAccountTypeEnabled = true;
      this.isAccountGroupDisabled = true;
      this.coaId = this.data.accountDetail.coaId;

      if(this.data.accountDetail.systemAcc){
        this.isDeleteHidden = true;
        // this.isSaveHidden = true;
        this.accountForm = this.formBuilder.group({
          accountTypeControl: this.data.accountDetail.accType,
          accountGroupControl: [{value: this.data.accountDetail.accGroup, disabled: true }],
          accountName: [{ value: this.data.accountDetail.accName, disabled: true }, Validators.required],
          accountId: [this.data.accountDetail.accId, [Validators.required]],
          subAccountName: [{ value: this.data.accountDetail.subAccName, disabled: true}],
          subAccountId: [{ value: this.data.accountDetail.subAccID, disabled: true}],
          description: [{ value: this.data.accountDetail.accDesc, disabled: true}],
          paymentSource: [{ value: this.data.accountDetail.paymentSouce, disabled: true}],
          accountClosed: [{ value: this.data.accountDetail.accClosed, disabled: true}],
          consolidateRnP: [{ value: this.data.accountDetail.consolidateRP, disabled: true}],
          paymentEnabled: [{ value: this.data.accountDetail.paymentEnabled, disabled: true}],
          billEnabled: [{ value: this.data.accountDetail.billEnabled, disabled: true}],
          receiptEnabled: [{ value: this.data.accountDetail.receiptEnabled, disabled: true}],
          excludeFromLPI: [{ value: this.data.accountDetail.exclLPI, disabled: true}],
          allowPrepayment: [{ value: this.data.accountDetail.allowPrePayment, disabled: true}],
          depositTracking: [{ value: this.data.accountDetail.depositTracking, disabled: true}],
        });
        // this.accountForm.disable();
      }
      else{
        this.accountForm = this.formBuilder.group({
          accountType: [this.data.accountDetail.accType, [Validators.required]],
          accountGroup: [this.data.accountDetail.accGroup, [Validators.required]],
          accountName: [this.data.accountDetail.accName, [Validators.required]],
          accountId: [this.data.accountDetail.accId, [Validators.required]],
          subAccountName: this.data.accountDetail.subAccName,
          subAccountId: this.data.accountDetail.subAccID,
          description: this.data.accountDetail.accDesc,
          paymentSource: this.data.accountDetail.paymentSouce,
          accountClosed: this.data.accountDetail.accClosed,
          consolidateRnP: this.data.accountDetail.consolidateRP,
          paymentEnabled: this.data.accountDetail.paymentEnabled,
          billEnabled: this.data.accountDetail.billEnabled,
          receiptEnabled: this.data.accountDetail.receiptEnabled,
          excludeFromLPI: this.data.accountDetail.exclLPI,
          allowPrepayment: this.data.accountDetail.allowPrepayment,
          depositTracking: this.data.accountDetail.depositTracking,
        });
      }
    

    } else{
      console.log(this.data)

      let allAcc = this.data.account.AccountDetails.filter(x=>x.accId == this.data.accountDetail.accId)
      let largestId = 0;
      if(allAcc != null){
        for(let acc of allAcc){
          if(acc.subAccID != null || acc.subAccID != undefined){
            let num = parseInt(acc.subAccID, 10)
            console.log(num)
            if(num > largestId){
              largestId = num;
            }
          }
        }
      }
      largestId = largestId + 1
      let newSubAccID = String(largestId).padStart(2, '0');
      console.log('largest Id: ' + largestId)
      console.log('newSubAccID Id: ' + newSubAccID)

      this.title = 'Add';
      this.isDeleteHidden = true;
      this.accountForm = this.formBuilder.group({
        accountType: [this.data.accountDetail.accType, [Validators.required]],
        accountGroup: [this.data.accountDetail.accGroup, [Validators.required]],
        accountName: [this.data.accountDetail.accName, [Validators.required]],
        accountId: [this.data.accountDetail.accId, [Validators.required]],
        subAccountName: ['', [Validators.required]],
        subAccountId:  [newSubAccID, [Validators.required]],
        description: '',
        paymentSource: false,
        accountClosed: '',
        consolidateRnP: false,
        paymentEnabled: false,
        billEnabled: false,
        receiptEnabled: false,
        excludeFromLPI: false,
        allowPrepayment: false,
        depositTracking: false,
      });
    } 
  }

  onCloseDialog() {
    this.matDialogRef.close('Do Nothing');
  }

  onDelete() {
    this.isDeleteHidden = false;
    var coaId = this.coaId;
    this.title = 'Delete';
    this.isDeleteHidden = true;

    console.log('Request Body Delete:', coaId)
    
    this.chartOfAccountService.archieveAccount(coaId).subscribe({
      next: (x) => {
        this.commonService.SuccessNotification('Successfully Deleted')
        this.matDialogRef.close('Success');
      },
      error: err => 
      {
        this.commonService.ErrorNotification('error delete account : ' + err)
        console.log(`Stack Trace: ${err.error.StackTrace} \n + Exception: ${err.error.ExceptionType}  \n + Status Code: ${err.status}`);
      }
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.accountForm.invalid){
      this.commonService.ErrorNotification('Please fill in all mandatory field');
      return;
    }

    this.isBtnSaveDisabled = true;
    this.isDeleteHidden = true;
    var accountDetail = new AccountDetail();
    accountDetail.IsParent = false;
    accountDetail.AccountType = this.accountForm.get("accountType").value;
    accountDetail.COAId = this.coaId;
    accountDetail.SiteId = this.siteId;
    accountDetail.AccountGroup = this.accountForm.get("accountGroup").value;
    accountDetail.AccountName = this.accountForm.get("accountName").value;
    accountDetail.AccountIndex = this.accountForm.get("accountId").value;
    accountDetail.AccountClosed = this.accountForm.get("accountClosed").value;
    accountDetail.SubAccountName = this.accountForm.get("subAccountName").value;
    accountDetail.SubAccountIndex = this.accountForm.get("subAccountId").value;
    accountDetail.AccountDescription = this.accountForm.get("description").value;
    accountDetail.PaymentSouce = this.accountForm.get("paymentSource").value;
    accountDetail.ConsolidateRnP = this.accountForm.get("consolidateRnP").value;
    accountDetail.PaymentEnabled = this.accountForm.get("paymentEnabled").value;
    accountDetail.BillEnabled = this.accountForm.get("billEnabled").value;
    accountDetail.ReceiptEnabled = this.accountForm.get("receiptEnabled").value;
    accountDetail.ExcludeLPI = this.accountForm.get("excludeFromLPI").value;
    accountDetail.AllowPrepayment = this.accountForm.get("allowPrepayment").value;
    accountDetail.DepositTracking = this.accountForm.get("depositTracking").value;
    accountDetail.CreatedOn = new Date(formatDate(Date.now(), "yyyy-MM-dd", this.locale));
    accountDetail.CreatedBy = this.currentUser.UserIdentityName;
    accountDetail.UpdatedOn = new Date(formatDate(Date.now(), "yyyy-MM-dd", this.locale));
    accountDetail.UpdatedBy = this.currentUser.UserIdentityName;

    console.log('Request Body:', accountDetail)
 
    this.chartOfAccountService.addUpdateAccount(accountDetail).subscribe({
      next: (x) => {
        this.commonService.SuccessNotification('Successfully Saved')
        this.matDialogRef.close('Success');
      },
      error: err => {
        this.commonService.ErrorNotification('error save account : ' + err)
        this.isBtnSaveDisabled = false;
      }
    });
    this.isBtnSaveDisabled = false;
  }

  initilizeForm(){
    this.coaId = 0;
    this.isArchieved = false;
    this.title = 'Add';
    this.isBtnSaveDisabled = false;
    this.submitted = false;
    this.accountForm = this.formBuilder.group({
      accountType: ['', [Validators.required]],
      accountGroup: ['', [Validators.required]],
      accountName: ['', [Validators.required]],
      accountId: ['', [Validators.required]],
      subAccountName: ['', [Validators.required]],
      subAccountId: ['', [Validators.required]],
      description: "",
      paymentSource: false,
      accountClosed: "",
      consolidateRnP: false,
      paymentEnabled: false,
      billEnabled: false,
      receiptEnabled: false,
      excludeFromLPI: false,
      allowPrepayment: false,
      depositTracking: false,
    });
  }

  onModelChanged(val){
    this.errorMessage = '';
    this.infoMessage = '';
  }
  
  get accountFormControl() { 
    return this.accountForm.controls; 
  }
}


