import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { UIModule } from '../../shared/ui/ui.module';
import { AppsRoutingModule } from './apps-routing.module';
import { FullCalendarModule } from '@fullcalendar/angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxMultipleDatesModule } from 'ngx-multiple-dates';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { EditOccupantDialog } from './data/occupants/occupant-edit-dialog';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatTableResponsiveModule } from '../../shared/widgets/mat-table-responsive/mat-table-responsive.module';
import { MatIconModule } from '@angular/material/icon';
import { CustomDatePipe } from 'src/app/shared/custom.datepipe';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MatTabsModule } from '@angular/material/tabs';
import { YesNoPipe } from 'src/app/shared/yes-no.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { SafeHtmlPipe } from 'src/app/shared/safehtml.pipe';
import {MatChipsModule} from '@angular/material/chips';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
//import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { QuillModule } from 'ngx-quill';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { SafePipe } from 'src/app/shared/safe.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectFilterModule } from 'mat-select-filter';

import { AccessCardComponent } from './access-card/access-card.component';
import { AccessCardEditComponent } from './access-card/access-card-edit/access-card-edit.component';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';


import { AccountSubDialogComponent } from './accouting/account-sub-dialog/account-sub-dialog.component';
import { NoticeComponent } from './notice/notice.component';
import { NoticeDetailComponent } from './notice/notice-detail/notice-detail.component';
import { AccessComponent } from './access/access.component';
import { FacilityComponent } from './facility/facility.component';
import { VehicleComponent } from './vehicle/vehicle.component';
import { VehicleAddDialogComponent } from './vehicle/add-dialog/add-dialog.component';
import { VendorComponent } from './vendor/vendor.component';
import { VendorDetailsComponent } from './vendor/vendor-details/vendor-details.component';
import { VendorInfoComponent } from './vendor/vendor-info/vendor-info.component';
import { VendorStatementsComponent } from './vendor/vendor-statements/vendor-statements.component';
import { ServiceProviderDeleteDialogComponent } from './vendor/service-provider-delete-dialog/service-provider-delete-dialog.component';

import { VisitorComponent } from './visitor/visitor.component';
import { VisitorAddComponent } from './visitor/add/add.component';
import { VisitorFormComponent } from './visitor/form/form.component';


import { RentalParkingComponent } from './rentalparking/rentalparking';
import { RentalParkingFormComponent } from './rentalparking/rentalparking-form';

import { IssueComponent } from './issue/issue';
import { IssueFormComponent } from './issue/issue-form';

import { RenovationComponent } from './renovation/renovation';
import { RenovationFormComponent } from './renovation/renovation-form';

import { PaymentComponent } from './payment/payment';


export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD MMM YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);
LOAD_WASM().subscribe()

@NgModule({
    declarations: [
        EditOccupantDialog,
        EditOccupantDialog,
        CustomDatePipe,
        YesNoPipe,
        SafeHtmlPipe,
        SafePipe,
        ServiceProviderDeleteDialogComponent,
        AccessCardComponent,
        AccessCardEditComponent,
        AccountSubDialogComponent,
        NoticeComponent,
        NoticeDetailComponent,
        AccessComponent,
        FacilityComponent,
        VehicleComponent,
        VendorComponent,
        VendorDetailsComponent,
        VendorInfoComponent,
        VendorStatementsComponent,
        VisitorComponent,
        VisitorAddComponent,
        VisitorFormComponent,
        VehicleAddDialogComponent,
        RentalParkingComponent,
        RentalParkingFormComponent,
        IssueComponent,
        IssueFormComponent,
        RenovationComponent,
        RenovationFormComponent,
        PaymentComponent
    ],
  imports: [
    CommonModule,
    NgbModalModule,
    FullCalendarModule,
    AppsRoutingModule,
    UIModule,
    ReactiveFormsModule,
    FormsModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatRadioModule,
    MatMenuModule,
    MatDividerModule,
    MatTableResponsiveModule,
    MatIconModule,
    NgApexchartsModule,
    SharedModule,
    NgxMultipleDatesModule,
    MatChipsModule,
    NgxMaterialTimepickerModule,
    MatTabsModule,
    QuillModule.forRoot(),
    MatAutocompleteModule,
    PdfViewerModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatSlideToggleModule,
    MatSelectFilterModule,
    MatInputModule,
    QRCodeModule,
    NgxScannerQrcodeModule,
    //MatMomentDateModule,
    //MomentDateAdapter
  ],
    exports: [
        EditOccupantDialog,
        MatTableResponsiveModule,
        MatDatepickerModule,
        MatNativeDateModule,
        CommonModule,
        NgbModalModule,
        FullCalendarModule,
        AppsRoutingModule,
        UIModule,
        ReactiveFormsModule,
        FormsModule,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        MatTooltipModule,
        MatRadioModule,
        MatMenuModule,
        MatDividerModule,
        MatIconModule,
        MatTabsModule,
        NgApexchartsModule,
        NgxMultipleDatesModule,
        MatChipsModule,
        NgxMaterialTimepickerModule,
        MatAutocompleteModule,
        CustomDatePipe,
        MatProgressBarModule,
        SafeHtmlPipe,
        SafeHtmlPipe,
        ServiceProviderDeleteDialogComponent,
        VehicleAddDialogComponent,
        VisitorFormComponent        
    ],
    entryComponents: [
        EditOccupantDialog,
        ServiceProviderDeleteDialogComponent,
        AccountSubDialogComponent,
        VehicleAddDialogComponent,
        VisitorFormComponent
    ],
    providers: [
        //{provide: MAT_DATE_LOCALE, useValue: 'en_SG'},
        //{provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}}
         {
    provide: DateAdapter,
    useClass: MomentDateAdapter,
    deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ]
})

export class AppsModule { }

export class DialogModule { }
