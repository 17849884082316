import { Component, OnInit, AfterViewInit, ViewChild, Inject, ViewChildren, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { RentalParking, RentalParkingList } from 'src/app/core/models/rentalParking.models';
import { RentalParkingService } from 'src/app/core/services/rentalParking.service';
import { UnitService } from 'src/app/core/services/unit.service';
import { OccupantsService } from 'src/app/core/services/occupant.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, ReplaySubject, Subscription, Subject } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FileUpload } from 'src/app/core/models/fileUpload.models';
import { InformationDialogComponent } from 'src/app/shared/component/information-dialog/information-dialog.component';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil,map, startWith } from 'rxjs/operators';
import {User} from 'src/app/core/models/user.models';
import {UserService} from 'src/app/core/services/user.service';


  export interface Unit {
    id: number;
    name: string;
  }

@Component({
    selector: 'app-maintenance-form',
    templateUrl: 'maintenance-form.html',
    styleUrls: ['maintenance-form.css'],
})
export class MaintenanceFormComponent {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];

    mainListLink = '/apps/maintenance';
    mainEditLink = '/apps/maintenance-edit';
    DataForm!: FormGroup;
    submitted = false;
    errorMessage = '';
    infoMessage = '';
    action: string;
    pageTitle = '';
    currentDate = new Date();
    RentalParking: RentalParkingList = new RentalParkingList();
    RentalParkingUpdate: RentalParkingList = new RentalParkingList();
    //RentalParkingUpdate:any = {};
    private sub!: Subscription;
    private subscriptions: Subscription[] = [];
    displayMessage: { [key: string]: string } = {};
    id: number;
   
    siteIdLogin: number;
    user: User;
    units: any;
    occupants: any;
    selectedUnit: any;
    unitControl = new FormControl();
    unitOptions: Unit[] = [];
    filteredUnitOptions:  Observable<Unit[]>;
    imgObj:string 

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private RentalParkingService: RentalParkingService,
        private UnitService: UnitService,
        private OccupantsService: OccupantsService,
        private userService: UserService,
        private commonService: CommonService,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.siteIdLogin = Number(localStorage.getItem('siteId'));
        // set user access
        this.user = this.authenticationService.currentUser();

        // Read the product Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                this.id = Number(this.route.snapshot.paramMap.get('id'));
                console.log("The ID: " + this.id);
                //this.getOccupant(id);
            }
        );

        this.UnitService.getUnitsBySiteId(this.siteIdLogin).subscribe({
            next: units => {
                this.units = units;
                // console.log(this.units);

                this.unitOptions = this.units.map((item: any) => ({
                     id: item.UnitInfo.Unit.UnitId,
                     name: item.UnitInfo.Unit.UnitName,
                }));
                
                // const names = this.unitOptions.map(({ name }) => name);
                // this.unitOptions = this.unitOptions.filter(({ name }, index) => !names.includes(name, index + 1));
                // //console.log('unitOptions: ' + JSON.stringify(this.unitOptions));
                // console.log(this.unitOptions)
                this.filteredUnitOptions = this.unitControl.valueChanges.pipe(
                    startWith(''),
                    map(value => (typeof value === 'string' ? value : value.name)),
                    map(name => (name ? this._filterUnit(name) : this.unitOptions.slice())),
                );

                //   if (this.id > 0) {
                //     // this.displayRentalParking();
                //     this.action = 'Update';
                //     } else {
                //         this.pageTitle = 'Add Issue';
                //         this.action = 'Add';
                //     }
            },
            error: err => this.errorMessage = err
        });



        this.user = this.authenticationService.currentUser();
        //console.log('Login User: ' + JSON.stringify(this.user));

        this.DataForm = this.fb.group({
            UnitId: ['', [Validators.required]],
            // UnitName: ['', []],
            OccupantName: ['', [Validators.required]],
            OccupantContact: ['', [Validators.required]],
            Content: ['', [Validators.required]]
        });
    }

    displayFnUnit(unit: Unit): string {
        return unit && unit.name ? unit.name : '';
    }

    selectUnitNo() {
        // console.log('Form data unitControl: ' + JSON.stringify(this.unitControl.value));
        const value = this.unitControl.value;
        this.DataForm.value.UnitId = value.id;
        this.selectedUnit = {
            UnitId: value.id,
            UnitName: value.name,
            CreateTime: new Date()
        };
        // this.DataForm.value.UnitName = value.name;
        // console.log(this.DataForm);
        // this.DataForm.patchValue({
        //     UnitId: value.id,
        //     UnitName: value.name
        // });
    }

    save(): void {
        // console.log(this.DataForm);
        let str = localStorage.getItem("renovations");
        let arr: any;
        if(str){
            arr = JSON.parse(str);
        }else{
            arr = [];
        }
        let data = {...this.DataForm.value,  ...this.selectedUnit}
        // console.log(data, this.selectedUnit);
        

        arr.push(data);
        // console.log(issueArr);  
        localStorage.setItem("renovations", JSON.stringify(arr));
        this.router.navigate(['/apps/renovation']);
    }

    private _filterUnit(name: string): Unit[] {
        const filterValue = name.toLowerCase();

        return this.unitOptions.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    
}
