import { Component, OnInit, AfterViewInit, ViewChild, Inject, ViewChildren, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { RentalParkingService } from '../../../core/services/rentalParking.service';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RentalParkingList } from 'src/app/core/models/rentalParking.models';
import { AuthenticationService } from '../../../core/services/auth.service';

import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CommonService } from 'src/app/core/services/common.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {VIEW_LIST_CONST} from '../../../core/models/user.models';
import {UserService} from '../../../core/services/user.service';


@Component({
    selector: 'rentalparking',
    templateUrl: './rentalparking.html',
    styleUrls: ['rentalparking.css'],
})
export class RentalParkingComponent implements OnInit, OnDestroy {

    userEditRentalParkingAllowedAccess: boolean = false;

    mainListLink = '/apps/rentalparking';
    mainEditLink = '/apps/rentalparking-form';
    filterForm!: FormGroup;
    siteIdLogin: number;
    errorMessage = '';
    private subscription: Subscription;
    currentDate = new Date();
    user: any;
    RentalParkings: any[] = [];

    filterValues = {};
    RentalParkingFilter = new MatTableDataSource();
    displayedColumns: string[] = ['RentalParkListId', 'UnitName', 'OccupantName', 'ParkingLotName', 'PlateNo', 'RentalTerm', 'RentalStartDate', 'RentalEndDate', 'PaymentStatus'];

    pageSize: number = 10;
    pageNo: number = 0;

    constructor(private rentalParkingService: RentalParkingService,
        private authenticationService: AuthenticationService,
        private userService: UserService,
        private commonService: CommonService,
        private fb: FormBuilder,
        public dialog: MatDialog,
        private datePipe: DatePipe) {}

    @ViewChild(MatSort, { static: false }) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    get f() { return this.filterForm.controls; }

    ngOnInit(): void {
        this.siteIdLogin = Number(localStorage.getItem('siteId'));
        // set user access
        this.user = this.authenticationService.currentUser();
        this.userEditRentalParkingAllowedAccess = this.authenticationService.isAllowAccess('Edit Car Park');
        this.getRentalParkingBySite();

        this.user = this.authenticationService.currentUser();
        //console.log('Login User: ' + JSON.stringify(this.user));

        this.rentalParkingService.getRentalParkingBySite(this.siteIdLogin).subscribe({
            next: RentalParkings => {
                this.RentalParkings = RentalParkings;
                //console.log(this.RentalParkings);
            },
            error: err => this.errorMessage = err
        });

        this.filterForm = this.fb.group({
            filterParkingLotName: '',
            filterRentalStartDate: '',
            filterRentalEndDate: '',
            filterPaymentStatus: 'Paid',
            searchBox: ''
        });

        if (sessionStorage.getItem('pageIndexRentalParkingList')) {
            this.pageSize = Number(sessionStorage.getItem('pageSizeRentalParkingList'));
            this.pageNo = Number(sessionStorage.getItem('pageIndexRentalParkingList'));
            //console.log('pageSizeRentalParkingList: ' + this.pageSize + ' ,pageIndexRentalParkingList: ' + this.pageNo);
          }
          if (sessionStorage.getItem('filterValueRentalParkingList')) {
            this.filterForm.patchValue({
              searchBox: sessionStorage.getItem('filterValueRentalParkingList')
            });
          }

    }

    async getRentalParkingBySite() {

        this.subscription = (await this.rentalParkingService.getRentalParkingListBySite(this.siteIdLogin)).subscribe({
            next: data => {

                const filterParkingLotName = this.f.filterParkingLotName.value;
                const filterPaymentStatus = this.f.filterPaymentStatus.value;
                const filterRentalStartDate = this.datePipe.transform(this.f.filterRentalStartDate.value, 'yyyy-MM-dd');
                const filterRentalEndDate = this.datePipe.transform(this.f.filterRentalEndDate.value, 'yyyy-MM-dd');

                data = data.map((item: any) => ({
                    RentalParkListId: item.RentalParkListId,
                    VehicleData: item.VehicleData,
                    RentalParkingData: item.RentalParkingData,
                    OccupantData: item.OccupantData,
                    RentalTerm: item.RentalTerm,
                    RentalDuration: item.RentalDuration,
                    RentalStartDate: item.RentalStartDate,
                    RentalEndDate: item.RentalEndDate,
                    UpdatedBy: item.UpdatedBy,
                    UpdatedOn: item.UpdatedOn,
                    UnitName: item.OccupantData.Unit.UnitName,
                    OccupantName: item.OccupantData.OccupantName,
                    OccupantContact: item.OccupantData.OccupantContact,
                    ParkingLotName: item.RentalParkingData.ParkingLotName,
                    PlateNo: item.VehicleData.PlateNo,
                    RentalStartDateEng: this.transformDateEng(item.RentalStartDate),
                    RentalEndDateEng: this.transformDateEng(item.RentalEndDate),
                    RentalStartDateShort: this.transformDateShort(item.RentalStartDate),
                    RentalEndDateShort: this.transformDateShort(item.RentalEndDate),
                    PaymentStatus: 'Paid',
                    SiteId: item.RentalParkingData.SiteId,
                    VehicleId: item.VehicleData.VehicleId,
                    OccupantId: item.OccupantData.OccupantId,
                    RentalParkingId: item.RentalParkingData.RentalParkingId
                    //PublishedText: this.transformYesNo(item.Published),
                    //PublishedText2: item.Published ? 'Unpublish' : 'Publish',
                }));

                if (filterParkingLotName) {
                    data = data.filter(i => i.RentalParkingData.ParkingLotName == filterParkingLotName);
                }

                if (filterRentalStartDate) {
                    data = data.filter(i => i.RentalStartDateShort == filterRentalStartDate);
                }

                if (filterRentalEndDate) {
                    data = data.filter(i => i.RentalEndDateShort == filterRentalEndDate);
                }

                if (filterPaymentStatus) {
                    data = data.filter(i => i.PaymentStatus == filterPaymentStatus);
                }

                //console.log('data: ' + JSON.stringify(data));
                this.RentalParkingFilter = new MatTableDataSource(data);

                this.RentalParkingFilter.filterPredicate = (data2: any, filter: string) => {
                    return data2.RentalParkListId.toString().toLocaleLowerCase().includes(filter) ||
                        data2.UnitName.toLocaleLowerCase().includes(filter) ||
                        data2.OccupantName.toLocaleLowerCase().includes(filter) ||
                        data2.OccupantContact.toLocaleLowerCase().includes(filter) ||
                        data2.ParkingLotName.toLocaleLowerCase().includes(filter) ||
                        data2.PlateNo.toLocaleLowerCase().includes(filter) ||
                        data2.RentalTerm.toLocaleLowerCase().includes(filter) ||
                        data2.RentalStartDateEng.toLocaleLowerCase().includes(filter) ||
                        data2.RentalEndDateEng.toLocaleLowerCase().includes(filter) ||
                        data2.PaymentStatus.toLocaleLowerCase().includes(filter);
                }

                this.RentalParkingFilter.sortingDataAccessor = (item: any, property) => {
                    switch (property) {
                        case 'RentalStartDateEng': return item.RentalStartDate;
                        case 'RentalEndDateEng': return item.RentalEndDate;
                        default: return item[property];
                    }
                };

                this.RentalParkingFilter.sort = this.sort;
                this.RentalParkingFilter.paginator = this.paginator;
                this.applyFilter();

            },
            error: err => this.errorMessage = err
        });
    }


    applyFilter() {
        //console.log('filterForm: ' + this.f.searchBox.value);
        const filterValue = this.f.searchBox.value;
        this.RentalParkingFilter.filter = filterValue.trim().toLowerCase();
        sessionStorage.setItem('filterValueRentalParkingList', filterValue);
    }

    transformDateEng(date) {
        if (date)
            return this.datePipe.transform(date, 'dd MMM yyyy');
        else
            return "";
    }

    transformDateShort(date) {
        if (date)
            return this.datePipe.transform(date, 'yyyy-MM-dd');
        else
            return "";
    }

    // transformYesNo(value) {
    //     return value == true ? 'Yes' : 'No';
    // }

    // isDateBigger(endDate) {
    //     let localStart = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
    //     let localEnd = this.datePipe.transform(endDate, 'yyyy-MM-dd');

    //     if (localStart > localEnd)
    //         return "Pass";
    //     else
    //         return "Current";
    // }

    pageEvents(event: any) {
        //console.log(' ,pageSize: ' + event.pageSize + ' ,pageIndex: ' + event.pageIndex);
        sessionStorage.setItem('pageSizeRentalParkingList', event.pageSize);
        sessionStorage.setItem('pageIndexRentalParkingList', event.pageIndex);
      }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
