import { Component, OnInit, AfterViewInit, ViewChild, Inject, ViewChildren, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { Occupant, OccupantUpdate } from '../../../../core/models/occupant.models';
import { OccupantsService } from '../../../../core/services/occupant.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { Country } from 'src/app/core/models/country.models';
import { CommonService } from 'src/app/core/services/common.service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from '../../../../core/services/auth.service';
import { UnitService } from '../../../../core/services/unit.service';
import { FileUpload } from 'src/app/core/models/fileUpload.models';
import { State } from 'src/app/core/models/state.model';
import { Attachment } from 'src/app/core/models/attachment.model';

@Component({
  selector: 'occupant-edit-dialog',
  templateUrl: 'occupant-edit-dialog.html',
  styleUrls: ['occupant-edit-dialog.css']
})
export class EditOccupantDialog {
  @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];

  occupantForm!: FormGroup;
  submitted = false;
  errorMessage = '';
  infoMessage = '';
  action: string;
  pageTitle = 'Add New Occupant';
  saveButtonText = 'Save';
  currentDate = new Date();
  occupant: Occupant = new Occupant();
  occupantUpdate: OccupantUpdate = new OccupantUpdate();
  private sub!: Subscription;
  private subscriptions: Subscription[] = [];
  displayMessage: { [key: string]: string } = {};
  private countries: Country[];
  id: number;
  occupantLoad: any;
  occupantLoadDetail: any;
  units: any;
  siteIdLogin: number;
  user: any;
  selectedUnit: any;
  selectedMailingCountry: any;
  private states: State[];
  private availableStates: State[] = [];
  private occupantAttachment: Attachment = new Attachment();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditOccupantDialog>,
    private commonService: CommonService,
    private occupantsService: OccupantsService,
    private unitService: UnitService,
    private authenticationService: AuthenticationService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) data) {

    //console.log('Inject data: ' + JSON.stringify(data));

    this.occupantLoad = { ...data };
    this.action = data.action;

    if (data.action == "Add") {
      this.pageTitle = 'Create New Occupant';
    } else if (data.action == "Update") {
      this.pageTitle = 'Edit Occupant - ' + data.OccupantName + ' at Unit ' + data.Unit.UnitName;
    } else if (data.action == "Delete") {
      this.pageTitle = 'Delete Occupant - ' + data.OccupantName + ' at Unit ' + data.Unit.UnitName;
      this.saveButtonText = 'Delete';
    }
  }

  ngOnInit(): void {

    this.user = this.authenticationService.currentUser();
    this.siteIdLogin = Number(localStorage.getItem('siteId'));
    //console.log('Login User: ' + JSON.stringify(this.user));

    this.occupantForm = this.fb.group({
      unitId: ['', [Validators.required]],
      occupantName: ['', [Validators.required]],
      occupantTitle: '',
      //ckbResident: true,
      //ckbTenant: false,
      occupantType: '',
      nationality: [132, [Validators.required]],
      occupantIdentityId: ['', [Validators.required]],
      occupantContact: ['', [Validators.required]],
      occupantEmail: ['', [Validators.email]],
      unitAddress1: [{ value: '', disabled: true }],
      unitAddress2: [{ value: '', disabled: true }],
      unitPostcode: [{ value: '', disabled: true }],
      unitCity: [{ value: '', disabled: true }],
      unitState: [{ value: '', disabled: true }],
      unitCountry: [{ value: '', disabled: true }],
      mailingAddress1: '',
      mailingAddress2: '',
      mailingPostcode: '',
      mailingCity: '',
      mailingState: '',
      mailingCountry: '',
      mailingAddrIsSame: false
    });

    this.getCountries();

    this.unitService.getUnitsBySiteId_ShortInfo(this.siteIdLogin).subscribe({
      next: units => {
        this.units = units;
        //filter duplicate unitname
        const UnitNameList = this.units.map(({ Unit }) => Unit.UnitName);
        this.units = this.units.filter(({ Unit }, index) => !UnitNameList.includes(Unit.UnitName, index + 1));

        if (this.action == "Update") {
          this.displayOccupant();
        }
      },
      error: err => this.errorMessage = err
    });

    this.getStates();

  }

  getCountries() {
    this.subscriptions.push(
      this.commonService.getCountrys().subscribe({
        next: countries => {
          this.countries = countries;

        },
        error: err => console.log(err)
      })
    )
  }

  getStates() {

    this.subscriptions.push(
      this.commonService.getStates().subscribe({
        next: states => {
          this.states = states;

        },
        error: err => console.log(err)
      })
    )
  }

  updateStates(countrySelected: string) {
    //console.log('MailingCountry countrySelected : ' + countrySelected);
    if(this.states ==  null ||this.states ==undefined)
    {
       this.commonService.getStates().toPromise().then((state)=>
       {
        this.states = state
    if (countrySelected) {
      this.availableStates = this.states.filter(state =>
        state.CountryName === countrySelected
      )
    }
       })
    }else{
    if (countrySelected) {
      this.availableStates = this.states.filter(state =>
        state.CountryName === countrySelected
      )
    }
    }



    //console.log(this.availableStates);
  }

  //updateOwnerTenant(ckbName: string): void {
  //console.log('CheckBox Name : ' + ckbName);
  //if (ckbName=='ckbOwner' && this.f.ckbOwner.value == true) {
  //  this.occupantForm.patchValue({
  //    ckbResident: true,
  //    ckbTenant: false
  //  });
  //} else
  // if (ckbName=='ckbTenant' && this.f.ckbTenant.value == true) {
  //     this.occupantForm.patchValue({
  //       ckbOwner: false,
  //       ckbResident:false
  //     });
  //   } else if (ckbName=='ckbResident' && this.f.ckbResident.value == true) {
  //     this.occupantForm.patchValue({
  //       ckbTenant: false
  //     });
  //   }
  //}

  displayOccupant(): void {
    if (this.occupantForm) {
      this.occupantForm.reset();
    }

    //console.log('Load data : ' + JSON.stringify(this.occupantLoad));

    this.occupantsService.getOccupantsById(this.occupantLoad.OccupantId).subscribe({
      next: data => {
          this.occupantLoadDetail = data;

          let MailingCountryName = '';
        if(this.occupantLoadDetail.MailingCountry){
          MailingCountryName = this.occupantLoadDetail.MailingCountry.CountryName;
        }

          this.occupantForm.patchValue({
            unitId: this.occupantLoad.Unit.UnitId,
            occupantName: this.occupantLoad.OccupantName,
            occupantTitle: this.occupantLoad.OccupantTitle,
            //ckbOwner: this.occupantLoad.IsOwner,
            //ckbResident: this.occupantLoad.OccupantType == "Resident" ? true : false,
            //ckbTenant: this.occupantLoad.OccupantType == "Tenant" ? true : false,
            occupantType: this.occupantLoad.OccupantType,
            nationality: this.occupantLoad.Country.CountryId,
            occupantIdentityId: this.occupantLoad.OccupantIdentityId,
            occupantContact: this.occupantLoad.OccupantContact,
            occupantEmail: this.occupantLoad.OccupantEmail,
            mailingAddress1: this.occupantLoadDetail.MailingAddress1,
            mailingAddress2: this.occupantLoadDetail.MailingAddress2,
            mailingPostcode: this.occupantLoadDetail.MailingPostcode,
            mailingCity: this.occupantLoadDetail.MailingCity,
            mailingState: this.occupantLoadDetail.MailingState,
            mailingCountry: MailingCountryName,
            mailingAddrIsSame: this.occupantLoadDetail.MailingAddrIsSame
          });
      
          if(MailingCountryName){
            this.updateStates(MailingCountryName); // set default value for states
          }
          //console.log('displayOccupant UnitId :' + JSON.stringify(this.occupantLoad.Unit.UnitId));
          this.updateUnitAddress(this.occupantLoad.Unit.UnitId);
      
          // if (this.occupantLoadDetail.ProfileImage) {
          //   this.fileUploadObject = {
          //     fileUploadedName: this.occupantLoadDetail.ProfileImageName,
          //     fileUploadedByte: this.occupantLoadDetail.ProfileHeader + ',' + this.occupantLoadDetail.ProfileImage,
          //     fileUploadedSize: this.commonService.countFileUploadedSizeText(
          //       this.occupantLoadDetail.ProfileImageName,
          //       this.occupantLoadDetail.ProfileHeader + ',' + this.occupantLoadDetail.ProfileImage)
          //   }
          //   //this.OldMinutesFileAttachmentId = attachment.AttachmentId;
          // }
          this.occupantAttachment = {
            fileBase64: this.occupantLoadDetail.ProfileImageByte,
            fileName: this.occupantLoadDetail.ProfileImageName,
            filePath: this.occupantLoadDetail.ProfileImage
          }

        },
        error: err => this.errorMessage = err
    });

  }

  get f() { return this.occupantForm.controls; }

  updateUnitAddress(UnitId) {
    //console.log('updateUnitAddress UnitId:' + UnitId);
    if (UnitId) {

      //console.log('units :' + JSON.stringify(this.units));
      this.selectedUnit = this.units.filter(i => i.Unit.UnitId == UnitId)[0];
      //console.log('selectedUnit :' + JSON.stringify(this.selectedUnit));
      if (this.selectedUnit) {
        //console.log('selectedUnit UnitInfo:' + JSON.stringify(this.selectedUnit));
        let CountryName = '';
        if(this.selectedUnit.Country){
          CountryName = this.selectedUnit.Country.CountryName;
        }

        this.occupantForm.patchValue({
          unitAddress1: this.selectedUnit.Unit.UnitAddress1,
          unitAddress2: this.selectedUnit.Unit.UnitAddress2,
          unitPostcode: this.selectedUnit.Unit.UnitPostcode,
          unitCity: this.selectedUnit.Unit.UnitCity,
          unitState: this.selectedUnit.Unit.UnitState,
          unitCountry: CountryName
        });
      }
    } else {
      //  if (this.selectedUnit) {
      //      this.selectedUnit.length = 0;
      //  }
      //console.log('No UnitId');
      this.selectedUnit = null;
      this.occupantForm.patchValue({
        unitAddress1: '',
        unitAddress2: '',
        unitPostcode: '',
        unitCity: '',
        unitState: '',
        unitCountry: '',
      });
    }
  }

  copyAddress() {
    //console.log('Inside copyAddress mailingAddrIsSame : '  + this.f.mailingAddrIsSame.value);
    if (this.f.mailingAddrIsSame.value == true) {
      //console.log('copyAddress');

      let CountryName = '';
      if(this.selectedUnit.Country){
        CountryName = this.selectedUnit.Country.CountryName;
        this.updateStates(CountryName); 
      }

      this.occupantForm.patchValue({
        mailingAddress1: this.selectedUnit.Unit.UnitAddress1,
        mailingAddress2: this.selectedUnit.Unit.UnitAddress2,
        mailingPostcode: this.selectedUnit.Unit.UnitPostcode,
        mailingCity: this.selectedUnit.Unit.UnitCity,
        mailingState: this.selectedUnit.Unit.UnitState,
        mailingCountry: CountryName
      });
    }
  }

  save() {
    //console.log(this.occupantForm);
    //console.log('Form data: ' + JSON.stringify(this.occupantForm.value));

    this.submitted = true;

    if (this.action == "Delete") {
      //console.log('Inside Delete: ' + this.occupantLoad.OccupantId);

      //this.occupantUpdate.OccupantId = this.occupantLoad.OccupantId;
      this.occupantsService.deleteOccupant(this.occupantLoad.OccupantId)
        .subscribe({
          next: x => {
            //console.log(x);
            return this.onSaveComplete();
          },
          error: err => this.errorMessage = err
        });

      this.infoMessage = 'Success delete.';
      return;
    }

    // stop here if form is invalid
    if (this.occupantForm.invalid) {
      this.errorMessage = 'Please correct the validation errors.';
      return;
    } else {

      this.occupantUpdate.OccupantName = this.f.occupantName.value;
      //this.occupantUpdate.UnitId = this.f.unitId.value;
      this.occupantUpdate.Unit = {
        UnitId: this.f.unitId.value
      };
      this.occupantUpdate.OccupantEmail = this.f.occupantEmail.value;
      this.occupantUpdate.OccupantContact = this.f.occupantContact.value;
      this.occupantUpdate.OccupantIdentityId = this.f.occupantIdentityId.value;
      this.occupantUpdate.OccupantTitle = this.f.occupantTitle.value;
      //this.occupantUpdate.OccupantNationalityId = this.f.nationality.value;

      //console.log("nationality " + this.f.nationality.value);
        this.occupantUpdate.Country = {
          CountryId: this.f.nationality.value
        };

      this.occupantUpdate.MailingAddress1 = this.f.mailingAddress1.value;
      this.occupantUpdate.MailingAddress2 = this.f.mailingAddress2.value;
      this.occupantUpdate.MailingCity = this.f.mailingCity.value;
      this.occupantUpdate.MailingPostcode = this.f.mailingPostcode.value;
      this.occupantUpdate.MailingState = this.f.mailingState.value;
      this.occupantUpdate.MailingAddrIsSame = this.f.mailingAddrIsSame.value;

      this.selectedMailingCountry = this.countries.filter(i => i.CountryName == this.f.mailingCountry.value)[0];
      //console.log(this.selectedMailingCountry);

      if (this.selectedMailingCountry) {
        this.occupantUpdate.MailingCountry = {
          CountryId: this.selectedMailingCountry.CountryId
        };
      }else{
        this.occupantUpdate.MailingCountry = {
          CountryId: 0
        };
      }
      this.occupantUpdate.ProfileImage = this.occupantAttachment.filePath
      this.occupantUpdate.ProfileImageByte =  this.occupantAttachment.fileBase64
      this.occupantUpdate.ProfileImageName =  this.occupantAttachment.fileName


      // if (this.fileUploadObject.fileNewByte) {
      //   var AttachmentFileHeader = this.fileUploadObject.fileNewByte.split(',')[0];
      //   var AttachmentFileBytes = this.fileUploadObject.fileNewByte.split(',')[1];
      //   //console.log("fileNewName " + this.fileUploadObject.fileNewName);
      //   this.occupantUpdate.ProfileImage = AttachmentFileBytes;
      //   this.occupantUpdate.ProfileHeader = AttachmentFileHeader;
      //   this.occupantUpdate.ProfileImageName = this.fileUploadObject.fileNewName;
      // }
      // else {
      //   if (this.fileUploadObject.fileUploadedName != null) {
      //     this.occupantUpdate.ProfileImage = this.occupantLoadDetail.ProfileImage;
      //     this.occupantUpdate.ProfileHeader = this.occupantLoadDetail.ProfileHeader;
      //     this.occupantUpdate.ProfileImageName = this.occupantLoadDetail.ProfileImageName;
      //   }
      // }

      //let occupantType = "";
      //if (this.f.ckbOwner.value) {
      //  occupantType = "Owner";
      //}
      // if (this.f.ckbResident.value) {   //So if user tick both Owner and Resident, here will save as Resident, because already have IsOwner flag
      //   occupantType = "Resident";
      // }
      // if (this.f.ckbTenant.value) {
      //   occupantType = "Tenant";
      // }
      // console.log('Save data occupantType: ' + occupantType);

      this.occupantUpdate.OccupantType = this.f.occupantType.value;;
      //this.occupantUpdate.IsOwner = this.f.ckbOwner.value;
      this.occupantUpdate.UpdatedBy = this.user.UserIdentityName;
      this.occupantUpdate.UpdatedOn = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');

      //console.log('Save data: ' + JSON.stringify(this.occupantUpdate));

      if (this.action == "Add") {
        this.occupantUpdate.OccupantId = 0;
        this.occupantUpdate.IsOwner = false;
        this.occupantsService.addOccupant(this.occupantUpdate)
          .subscribe({
            next: x => {
              //console.log(x);
              return this.onSaveComplete();
            },
            error: err => this.errorMessage = err
          });
      } else if (this.action == "Update") {
        this.occupantUpdate.OccupantId = this.occupantLoad.OccupantId;
        this.occupantUpdate.IsOwner = this.occupantLoad.IsOwner;
        //this.occupantUpdate.IsOwner = true;
        //console.log(JSON.stringify(this.occupantUpdate));
        this.occupantsService.updateOccupant(this.occupantUpdate)
          .subscribe({
            next: x => {
              //console.log(x);
              return this.onSaveComplete();
            },
            error: err => this.errorMessage = err
          });
      }
      this.infoMessage = 'Success save.';
      //this.dialogRef.close({event:this.action,data:this.occupantUpdate});  //If put this UI cannot refresh
    }

  }

  close(): void {
    this.dialogRef.close(false);
  }

  onSaveComplete(): void {
    // Reset the form to clear the flags
    this.occupantForm.reset();
    this.dialogRef.close(this.occupantForm.value);
  }

  updateFileUploadObject(fileUploadObject) {
    this.occupantAttachment = fileUploadObject
    //console.log(fileUploadObject);
  }

}