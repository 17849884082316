import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import {UserService} from '../services/user.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {InformationDialogComponent} from '../../shared/component/information-dialog/information-dialog.component';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        public dialog: MatDialog,
        private router: Router,
        private authenticationService: AuthenticationService,
        private userService: UserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
      const currentUser = this.authenticationService.currentUser();
      const token = localStorage.getItem('auth_token');

      // if user is logged in and granted access token
      if (currentUser && token) {

        // validate if token is expired
        if (this.isExpired(token) === false) {

          // validate based on roles
          if (route.data.viewName || route.data.rolesAllowed) {
            this.userService.getUserDetails(currentUser.UserId).subscribe({
              next: user => {

                // validate based on viewName
                if (route.data.viewName) {
                  const userHasAccess = this.authenticationService.isAllowAccess(route.data.viewName)
                  if (userHasAccess === false) {
                    this.openInformationDialog('ERROR', 'Unauthorized.')
                    this.router.navigate(['/']);
                    return false;
                  }
                }

                // validate access for superadmin / gm related functions
                if (route.data.rolesAllowed) {
                  if (!route.data.rolesAllowed.includes(user.Role.RoleName)) {
                    this.openInformationDialog('ERROR', 'Unauthorized.')
                    this.router.navigate(['/']);
                    return false;
                  }
                }
                return true;
              },
              error: err => console.error(err)
            });
          }

         return true

        } else {
          return false;
        }
      }

      // not logged in so redirect to login page with the return url
      this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }

    public isExpired(strToken:string):boolean{
        //JWT token decoder

        var base64Url = strToken.split('.')[1];
        var decodedValue = JSON.parse(window.atob(base64Url));


        if(decodedValue === null)return false;
       else{
           var expiredTime = decodedValue.exp;
           var today = Math.floor(Date.now()/1000);
           if(today>=expiredTime)return true;
           return false;
       }
    }

    openInformationDialog(title, message){
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '500px';
      dialogConfig.height = 'auto';
      dialogConfig.data = {
        title,
        message
      }

      let dialogRef: MatDialogRef<any>;
      dialogRef = this.dialog.open(InformationDialogComponent, dialogConfig);
    }

}
