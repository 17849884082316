import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { ChartOfAccountService } from 'src/app/core/services/account/chart-of-account.service';
import { VendorAccountService } from 'src/app/core/services/data/vendor-account.service';
import { VendorService } from 'src/app/core/services/vendor.service';
import * as XLSX from 'xlsx';
import { ConfirmationDialogComponent } from '../../invoice/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-vendor-statements',
  templateUrl: './vendor-statements.component.html',
  styleUrls: ['./vendor-statements.component.css']
})
export class VendorStatementsComponent implements OnInit {

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns = ['Date', 'Transaction', 'Description', 'RefNo', 'Amount', 'Payment', 'Balance']
  vendorAccountDataSource : MatTableDataSource<any> = new MatTableDataSource<any>();

  myForm: FormGroup;
  vendorId: string;
  vendorDetail: any;
  vendorAccount: any;
  startDate: Date;
  dueDate: Date;
  
  balance: string = "0.00";
  invoiceAmmout: string = "0.00";
  balanceDue: string = "0.00";

  constructor(
    private activatedRoute: ActivatedRoute,
    private vendorService: VendorService,
    private chartOfAccountService: ChartOfAccountService,
    private fb: FormBuilder,
    private vendorAccountService: VendorAccountService,
    private matDialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.vendorId = this.activatedRoute.snapshot.paramMap.get('id');

    this.myForm = this.fb.group({
      startDate: ['', Validators.required],
      dueDate: ['', Validators.required]
    }, { validator: this.dateRangeValidator });

    this.getVendorDetails(Number(this.vendorId));
  }

  get f(){ 
    return this.myForm.controls; 
  }

  dateRangeValidator(formGroup: FormGroup) {
    const startDate = formGroup.get('startDate').value;
    const dueDate = formGroup.get('dueDate').value;

    if (startDate && dueDate && startDate > dueDate) {
      return { dateRangeError: true };
    }

    return null;
  }

  onView() {
    this.getVendorAccountSummaryByUnitId();
  }

  exportToExcel(): void {
    const data = this.vendorAccount;
    const filename = `vendorAccounts.xlsx`;

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Save the Excel file
    XLSX.writeFile(wb, filename);
  }

  exportToPdf(): void {
    let temp = {
      vendorDetail: this.vendorDetail,
      vendorAccountDetails: this.vendorAccount,
      startDate: this.startDate,
      dueDate: this.dueDate,
      balance: this.balance,
      invoiceAmmout: this.invoiceAmmout,
      balanceDue: this.balanceDue
    }

    this.vendorAccountService.exportToPdf(temp);
  }

  getVendorDetails(vendorId: number) {
    this.vendorService.getVendorByID(vendorId).subscribe({
      next: item => {
        this.vendorDetail = item;
        console.log('getVendorDetails', this.vendorDetail);
      },
      error: err => console.log(err)
    })
  }

  getVendorAccountSummaryByUnitId() {
    let accountDTO = {
      VendorId: this.vendorId,
      StartDate: new Date(this.f.startDate.value).toISOString(),
      EndDate: new Date(this.f.dueDate.value).toISOString()
    }

    this.startDate = this.f.startDate.value;
    this.dueDate = this.f.dueDate.value;

    // console.log('getVendorAccountSummaryByUnitId', accountDTO);

    this.chartOfAccountService.getVendorAccountSummaryByUnit(accountDTO).subscribe({
      next: item => {
        let sortedInvoiceTransactions;

        console.log('getVendorAccountSummaryByUnitId', item);

        if (item.length != 0) {
          this.vendorAccount = item;
          this.vendorAccount = item.sort((a, b) => new Date(a.Date).getTime() - new Date(b.Date).getTime());
  
          sortedInvoiceTransactions = this.vendorAccount.filter(transaction => transaction.Transactions === 'Invoice');
  
          this.balance = this.vendorAccount[0].Balance;
          this.invoiceAmmout = sortedInvoiceTransactions.reduce((sum, transaction) => sum + transaction.Amount, 0);
          this.balanceDue = this.vendorAccount[this.vendorAccount.length - 1].Balance
  
          this.vendorAccountDataSource = new MatTableDataSource(this.vendorAccount);
          this.vendorAccountDataSource.sort = this.sort;
          this.vendorAccountDataSource.paginator = this.paginator;
        }
        else {
          this.matDialog.open(ConfirmationDialogComponent, {
            data:{
              title: 'Vendor Statement Status',
              message: `There is no record in vendor statement.`
            },
            width: '40%'
          });
        }
      },
      error: err => console.log(err)
    })
  }

}
