import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        label: 'Dashboard',
        iconType: 'feather',
        icon: 'home',
        link: '/dashboard',
    },
    {
        label: 'Notices',
        iconType: 'feather',
        icon: 'mic',
        link: '/apps/notice'
    },
    {
        label: 'Payment',
        iconType: 'unicon',
        icon: 'uil-money-insert',
        // accessibleBy:[1],
        subItems: [
            {
                label: 'Invoices',
                link: '/apps/invoice'
            },
            {
                label: 'Payment',
                link: '/apps/payment'
            },
            {
                label: 'Credit Note',
                link: '/apps/credit-note'
            }
        ]
    },
    {
        label: 'Visitor',
        iconType: 'unicon',
        icon: 'uil-car-sideview',
        link: '/apps/visitor'
    },
    {
        label: 'Access',
        iconType: 'unicon',
        icon: 'uil-lock-access',
        link: '/apps/access'
    },
    {
        label: 'Facility',
        iconType: 'unicon',
        icon: 'uil-ball',
        link: '/apps/facility'
    },
    {
        label: 'Rental Parking',
        iconType: 'unicon',
        icon: 'uil-car',
        link: '/apps/rentalparking'
    },
    {
        label: 'Issue & Complain',
        iconType: 'unicon',
        icon: 'uil-clipboard-alt',
        link: '/apps/issue'
    },
    {
        label: 'Vehicles',
        iconType: 'unicon',
        icon: 'uil-bus',
        link: '/apps/vehicle'
    },
    {
        label: 'Renovation',
        iconType: 'unicon',
        icon: 'uil-paint-tool',
        link: '/apps/renovation'
    },
    {
        label: 'Vendors',
        iconType: 'unicon',
        icon: 'uil-store',
        link: '/apps/vendor'
    }
];