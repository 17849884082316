import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from "src/environments/environment";
import { Account, AccountDetail,ChartOfAccount,ChartOfAccountType } from '../../models/account/chartOfAccount.models';
import { CommonService } from '../common.service';

@Injectable({
  providedIn: "root",
})
export class ChartOfAccountService {
  constructor(
    private http: HttpClient,
    private commonService: CommonService) { }

  accountTypeList: string[];
  accountGroupList: string[];
  chartOfAccountType:ChartOfAccountType[];
  baseUrl = environment.apiBaseUrl;

  getAccountBySite(siteId: number): Observable<Account[]> {
    var url = this.baseUrl + `account/getchartofaccountbysite/${siteId}`;
    return this.http.get<Account[]>(url).pipe(
      tap(data => {
        
        // console.log("getAccountBySite: " + JSON.stringify(data))
        // console.log("getAccountBySite");
        this.accountTypeList = Array.from(new Set(data.map(x => x.AccountType)));
        this.accountGroupList = Array.from(new Set(data.map((x) => x.AccountGroup)));
        
        this.chartOfAccountType = data.reduce((types,grp)=>{
          let existingType = types.find(x=>x.AccountType == grp.AccountType)
            if(existingType != null || existingType!= undefined){
              if(!existingType.AccountGroup.some(y=>y == grp.AccountGroup))
              {
                existingType.AccountGroup.push(grp.AccountGroup)
              }
            }else{
              let newType: ChartOfAccountType = new ChartOfAccountType();
              newType.AccountType = grp.AccountType;
              newType.AccountGroup.push(grp.AccountGroup)
              types.push(newType);
            }
          return types
        },new Array<ChartOfAccountType>())
      }),
      catchError(this.commonService.handleError)
    );
  }

  getChartOfAccountBySite(siteId: number): Observable<ChartOfAccount[]> {
   var url = this.baseUrl + `account/getchartofaccountbysite/${siteId}`;

    return this.http.get<ChartOfAccount[]>(url).pipe(
      tap(data => {
        // console.log("getAccountBySite: " + JSON.stringify(data))
        // console.log("getAccountBySite");
        this.accountTypeList = Array.from(new Set(data.map(x => x.AccountType)));
        this.accountGroupList = Array.from(new Set(data.map((x) => x.AccountGroup)));
      }),
      catchError(this.commonService.handleError)
    );
  }

  addUpdateAccount(accountDetail: AccountDetail): Observable<AccountDetail>{
    var url = this.baseUrl + "account/addupdatechartofaccount";
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<AccountDetail>(url, accountDetail, { headers })
      .pipe(
        tap(data => console.log('addNupdateAccount: ')),
        catchError(this.commonService.handleError)
      );
  }

  archieveAccount(coaId){
    var url = this.baseUrl + `account/archievechartofaccount/${coaId}`;
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    //console.log(url);
    return this.http.post<any>(url, {headers})
      .pipe(
        // tap(data => console.log('archieveAccount: ')),
        catchError(this.commonService.handleError)
      );
  }

  getUnitAccountSummaryByUnit(accountData){
    var url = this.baseUrl + "account/by-unit-and-date";
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any[]>(url, accountData, { headers })
      .pipe(
        tap(data => console.log('getUnitAccountSummaryByUnit: ')),
        catchError(this.commonService.handleError)
      );
  }

  getVendorAccountSummaryByUnit(accountData){
    var url = this.baseUrl + "account/by-vendor-and-date";
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post<any[]>(url, accountData, { headers })
      .pipe(
        tap(data => console.log('getVendorAccountSummaryByUnit: ')),
        catchError(this.commonService.handleError)
      );
  }

  getAccountDetailsByAccountGroup(siteId): Observable<{ accountGroup: string; accountDetails: { coaId: number; accName: string }[] }[]> {
    return this.getChartOfAccountBySite(siteId).pipe(
    map(data =>
      data.map(group => {
        const accountMap = new Map<string, {coaId: number; subAccName: string[] }>();

        group.AccountDetails.forEach(account => {
          const { coaId, accName, subAccName } = account;

          if(!accountMap.has(accName)) {
            accountMap.set(accName, {
              coaId: coaId,
              subAccName: subAccName ? [subAccName] : []
            });
          }
          else {
            const existingAccount = accountMap.get(accName);
            if (subAccName && existingAccount && !existingAccount.subAccName.includes(subAccName)) {
              existingAccount.subAccName.push(subAccName);
            }
          }
        });

        return {
          accountGroup: group.AccountGroup,
          accountDetails: Array.from(accountMap.entries()).map(([accName, { coaId, subAccName }]) => ({
            coaId,
            accName,
            subAccName
          }))
        };
      })
    ),
      catchError(this.commonService.handleError)
    );
  }
}
// map(data => 
//   data.map(group => ({
//     accountGroup: group.AccountGroup,
//     accountDetails: group.AccountDetails.map(account => ({
//       coaId: account.coaId,
//       accName: account.accName,
//       subName: account.subAccName
//     }))
//   }))
// )