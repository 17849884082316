export class Attachment {
  filePath?: string;
  fileBase64?: string;
  fileName?: string;

}

export class AttachmentMultiple{
  AttachmentId?: number;
  AttachmentFileName?: string;
  AttachmentFileType?: string;
  AttachmentFileModule?: string;
  AttachmentFilePath?: string;
  Base64Attachment?: string;
  TypeId?: number;
  CreatedOn?: Date|string;
  CreatedBy?: string;
  AttachmentFileBase64?: string;
  AttachmentFileSize?: string;
}