import { User } from 'src/app/core/models/user.models';
export class Site{
  SiteId: number;
  SiteName: string;
  SiteCity?: string;
  SiteLocation?: string;
  SiteState?: string;
  SiteCode?: string;
  SiteCountry?: string;
  SitePhoto?: string;
  SitePhotoByte?: string;
  SitePhotoFileName?: string;
  Type?: string;
  SubType?: string;
  SiteRoles?: Array<any>;
  SubId?: string;
  UpdatedOn?: string;
  UpdatedBy?: string;
  UpdatedByUserId?: number;
  CreatedOn?: Date;
  PropertyManager?: string;
  Developer?: string;
  ManagementBody?: string;
  RegistrationNo?: string;
  RegistrationCertificate?: string;
  RegistrationCertificateByte?: string;
  RegistrationCertificateFileName?: string;
  Currency?: string;
  Country?: any;
  Address1?: string;
  Address2?: string;
  PostCode?: string;
  ContactNo?: string;
  Email?: String;
  Facsimile?: string;
  Website?: string;
  Setting?: SiteSetting;
  BankAccount?: Bank[];
  Insurance?: Insurance[];
  ProfileTeam?: ProfileTeam[];
  OfficeHours?: OfficeHours;
  SiteParking?: SiteParking[];
}

export class SiteSetting{
  SettingId: number;
  SiteId: number;
  ActivationDate: string;
  FYStartMonth: string;
  TaxDateLock: string;
  PettyCashAccount: string;
  PettyCashAccountBalance: number;
  MaintainanceFundChargeOn: string;
  MaintainanceFundCharges: number;
  SinkingFundChargeOn: string;
  SinkingFundCharges: number;
  IsSST: boolean;
  SSTStartDate: string;
  SSTEndDate: string;
  SSTNo: string;
  IsGST: boolean;
  GSTStartDate: string;
  GSTEndDate: string;
  GSTNo: string;
  IsGSTSinkFund: boolean;
  ReceiptPrefix: string;
  ReceiptStartingNo: string;
  ReceiptStartDate: string;
  //ShowBankAccount: boolean;
  CheckLegalCase: boolean;
  POPrefix: string;
  POStartingNo: string;
  ActivateInvoiceSetting: boolean;
  Basis: string;
  SinkingFund: number;
  RatePerShareUnit: number;
  InvoiceCycle: string;
  InvoiceStartDate: string;
  InvoiceDueDate: string;
  InvoiceStartingNo: string;
  InvoicePrefix: string;
  CalculateInterest: boolean;
  InterestsStartCalOn: string;
  LPIFollowDueDate: string;
  GracePeriods: number;
  LPIInterestRate: number;
  LPIInterestCycle: string;
  LPICharges: string;
  CovenienceFee: number;
  AllowOwnerUpdate: boolean;
  AllowVoting: boolean;
  UpdatedBy: string;
  UpdatedOn: string;

  // MaintenanceFeeDecimals: number;
  // SinkingFundDecimals: number;
  // UseStatementFormat: boolean;
  // ShowDueDateOnStatement: boolean;
  // ShowParcelNo: boolean;
  // ShowPropertyAddress: boolean;
  // ShowShareUnits: boolean;
  // IsEInvoice: boolean;
  // LPIChargesThreshold: string;
  // ShowCommitteeOrgChart: boolean;
  // ShowStaffOrgChart: boolean;
  }

  export interface NameValuePair{
    DisplayName: string;
    Value: string;
  }
export class SitePicture {
  SiteId: number;
  SitePhoto: string;
  SitePhotoHeader: string;
  UpdatedOn: Date;
  UpdatedBy: string;
}

export class Bank{
  SiteBankAccId: number;
  SiteId: number;
  BankName: string;
  BankAccountNo: string;
  BankAccountName: string;      
  Category: string;
  Display: boolean;
}

export class Insurance{
  SiteInsuranceId: number;
  SiteId: number;
  PremiumPrice: number;
  PolicyExpiryDate: string;
  InsuranceType: string;
  CompanyName: string;
  TotalInsured: number;
  UpdatedOn: Date;
  UpdatedBy: string
}

export class SiteParking{
  SiteParkingId: number;
  SiteId: number;
  ParkingNumber: number;
  MaxVehicle: number;
  CreatedOn: Date;
}

export class ProfileTeam{
    SiteProfileId: number;
    SiteId: number;
    RoleName: string;
    User: User;
}

export class OfficeHours{
    OfficeHourId: number;
    SiteId: number;
    MonFirstHalfStartTime: string
    MonFirstHalfEndTime: string
    MonSecondHalfStartTime: string
    MonSecondHalfEndTime: string
    TueFirstHalfStartTime: string
    TueFirstHalfEndTime: string
    TueSecondHalfStartTime: string
    TueSecondHalfEndTime: string
    WedFirstHalfStartTime: string
    WedFirstHalfEndTime: string
    WedSecondHalfStartTime: string
    WedSecondHalfEndTime: string
    ThuFirstHalfStartTime: string
    ThuFirstHalfEndTime: string
    ThuSecondHalfStartTime: string
    ThuSecondHalfEndTime: string
    FriFirstHalfStartTime: string
    FriFirstHalfEndTime: string
    FriSecondHalfStartTime: string
    FriSecondHalfEndTime: string
    SatFirstHalfStartTime: string
    SatFirstHalfEndTime: string
    SatSecondHalfStartTime: string
    SatSecondHalfEndTime: string
    SunFirstHalfStartTime: string
    SunFirstHalfEndTime: string
    SunSecondHalfStartTime: string
    SunSecondHalfEndTime: string
}