import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-information-dialog',
  template: `
    <div class="container-fluid">
      <div class="modal-body" >
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button (click)="close()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
<!--  TODO        -->
        <div class="image" *ngIf="image"><img class="images" [src]="image" alt="image" [width]="imageWidth" [height]="imageHeight"/></div>
        <div class="message" *ngIf="message" [innerHTML]="message"></div>
        <br/>
      </div>
      <div class="modal-footer">
        <button (click)="close()" class="btn btn-primary btn-rounded width-sm">Close</button>
      </div>
    </div>
    `
  ,
  styles: [
    '.message { padding-left: 15px }'
  ]
})
export class InformationDialogComponent implements OnInit {
  private readonly title: string;
  private readonly message: string;
  private readonly image: string;
  private readonly imageWidth: string;
  private readonly imageHeight: string;

  constructor(private dialogRef: MatDialogRef<InformationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title
    this.message = data.message
    this.image = data.image
    this.imageWidth = data.imageWidth
    this.imageHeight = data.imageHeight
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(false);
  }

}
