import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError,EMPTY } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import {Owner, OwnerInfo} from '../models/owner.models';
import {AccessCard} from '../models/accessCard.model';
import {CommonService} from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AccessCardService {
  private getallaccesscardbysiteURL: string = environment.apiBaseUrl+'accessCard/getallaccesscardbysite/'
  private getallaccesscardbyidURL: string = environment.apiBaseUrl+'accessCard/getallaccesscardbyid/'


  private updateaccesscardURL: string = environment.apiBaseUrl+'accessCard/updateaccesscard/'
  private addaccesscardURL: string = environment.apiBaseUrl+'accessCard/addaccesscard/'


  constructor(private http: HttpClient,private commonService: CommonService) { }

  getAllAccessCardBySite(siteId: number): Observable<AccessCard[]>{
    return this.http.get<AccessCard[]>(this.getallaccesscardbysiteURL + siteId)
      .pipe(
        catchError(this.commonService.handleError)
      );
    }

  getAccessCardById(cardId: Number){
    return this.http.get<AccessCard>(this.getallaccesscardbyidURL + cardId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  addAccessCard(card: AccessCard){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.addaccesscardURL}`;
    return this.http.post<any>(url, card, { headers })
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

   updateAccessCard(card: AccessCard){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.updateaccesscardURL}`;
    return this.http.post<any>(url, card, { headers })
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

}
