import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        label: 'Dashboard',
        iconType: 'feather',
        icon: 'home',
        link: '/dashboard',
    },
    {
        label: 'Notices',
        iconType: 'feather',
        icon: 'mic',
        link: '/apps/notice'
    },
    {
        label: 'My Unit',
        iconType: 'unicon',
        icon: 'uil-home',
        // accessibleBy:[1],
        subItems: [
            {
                label: 'Unit Info',
                link: '/unit/info'
            },
            {
                label: 'Ownership',
                link: '/unit/ownership'
            },
            {
                label: 'Occupants',
                link: '/unit/occupants'
            },
            {
                label: 'Access',
                link: '/unit/access'
            },
            {
                label: 'Vehicles',
                link: '/apps/vehicle'
            },
            {
                label: 'Accessory Parcels',
                link: '/unit/accessory'
            }
        ]
    },
    {
        label: 'Payments',
        iconType: 'unicon',
        icon: 'uil-usd-circle',
        link: '/apps/payment'
    },
    // {
    //     label: 'Payment',
    //     iconType: 'unicon',
    //     icon: 'uil-money-insert',
    //     // accessibleBy:[1],
    //     subItems: [
    //         {
    //             label: 'Invoices',
    //             link: '/apps/invoice'
    //         },
    //         {
    //             label: 'Payment',
    //             link: '/apps/payment'
    //         },
    //         {
    //             label: 'Credit Note',
    //             link: '/apps/credit-note'
    //         }
    //     ]
    // },
    {
        label: 'Service',
        iconType: 'unicon',
        icon: 'uil-cog',
        // accessibleBy:[1],
        subItems: [
            {
                label: 'Facility',
                link: '/apps/facility'
            },
            {
                label: 'Rental Parking',
                link: '/apps/rentalparking'
            },
            {
                label: 'Maintenance',
                link: '/apps/maintenance'
            },
            {
                label: 'Renovation',
                link: '/apps/renovation'
            }
        ]
    },
    {
        label: 'Visitor',
        iconType: 'unicon',
        icon: 'uil-car-sideview',
        link: '/apps/visitor'
    },
    // {
    //     label: 'Access',
    //     iconType: 'unicon',
    //     icon: 'uil-lock-access',
    //     link: '/apps/access'
    // },
    // {
    //     label: 'Facility',
    //     iconType: 'unicon',
    //     icon: 'uil-ball',
    //     link: '/apps/facility'
    // },
    // {
    //     label: 'Rental Parking',
    //     iconType: 'unicon',
    //     icon: 'uil-car',
    //     link: '/apps/rentalparking'
    // },
    // {
    //     label: 'Vehicles',
    //     iconType: 'unicon',
    //     icon: 'uil-bus',
    //     link: '/apps/vehicle'
    // },
    // {
    //     label: 'Renovation',
    //     iconType: 'unicon',
    //     icon: 'uil-paint-tool',
    //     link: '/apps/renovation'
    // },
    {
        label: 'Vendors',
        iconType: 'unicon',
        icon: 'uil-store',
        link: '/apps/vendor'
    },
    {
        label: 'Issue & Complain',
        iconType: 'unicon',
        icon: 'uil-clipboard-alt',
        link: '/apps/issue'
    },
];