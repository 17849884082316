import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError, EMPTY} from 'rxjs';
import {
  SpecialTimeSlot,
  TimeSlot,
  VisitorParkingBooking, VisitorParkingBookingAdd,
  VisitorParkingBookingUpdate,
  VisitorParkingLot,
  WalkInVisitor,
  VisitorParkingLotDelete
} from '../models/visitorParking.models';
import {catchError, tap} from 'rxjs/operators';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class VisitorParkingService{
  private uri = {
    getAllVisitorParkingLotBySite: environment.apiBaseUrl + 'servicedata/getallvisitorparkingslotbysite',
    getVisitorParkingLotById: environment.apiBaseUrl + 'servicedata/getallvisitorparkingslotbyid',
    addVisitorParkingSettings: environment.apiBaseUrl + 'servicedata/addvisitorparkinglot',
    updateVisitorParkingSettings: environment.apiBaseUrl + 'servicedata/updatevisitorparkinglot',
    updateVisitorParkingSlots: environment.apiBaseUrl + 'servicedata/updatevisitorparkingslot',
    updateVisitorParkingSlotsSpecial: environment.apiBaseUrl + 'servicedata/updatevisitorparkingslotspecial',
    deleteVisitorParkingSettings: environment.apiBaseUrl + 'servicedata/deletevisitorparkinglot',

    getVisitorParkingBookingBySite : environment.apiBaseUrl + 'servicedata/getallvisitorparkingsBookingbysite',
    updateVisitorParkingBooking : environment.apiBaseUrl + 'servicedata/updatevisitorcarparkbooking',
    addVisitorParkingBooking : environment.apiBaseUrl + 'servicedata/addvisitorcarparkbooking',
    importvisitorparkinglot : environment.apiBaseUrl + 'servicedata/importvisitorparkinglot',
    getAllWalkInVisitorBySite : environment.apiBaseUrl + 'servicedata/getallvisitorwalkinbysite',
    updateWalkInVisitorNote : environment.apiBaseUrl + 'servicedata/updatevisitorwalkinnote',
  }

  constructor(
    private http: HttpClient,
    private commonService: CommonService) { }

  getAllVisitorParkingLotBySite(siteId: number): Observable<VisitorParkingLot[]>{
    const url = `${this.uri.getAllVisitorParkingLotBySite}/${siteId}`;
    return this.http.get<VisitorParkingLot[]>(url)
      .pipe(
        tap(data =>
          console.log('getAllVisitorParkingSlotBySite')
        ),
        catchError(this.commonService.handleError)
      );
  }

  getVisitorParkingLotById(carParkId: number): Observable<VisitorParkingLot>{
    const url = `${this.uri.getVisitorParkingLotById}/${carParkId}`;
    return this.http.get<VisitorParkingLot>(url)
      .pipe(
        tap(data =>
          console.log('getAllVisitorParkingSlotById')
        ),
        catchError(this.commonService.handleError)
      );
  }

  addVisitorParkingSettings(visitorParking: VisitorParkingLot){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.uri.addVisitorParkingSettings}`;
    return this.http.post<any>(url, visitorParking, { headers })
      .pipe(
        tap(() => console.log('added Visitor Parking Settings: ')),
        catchError(this.commonService.handleError)
      );
  }

  updateVisitorParkingSettings(visitorParking: VisitorParkingLot): Observable<VisitorParkingLot[]>{
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.uri.updateVisitorParkingSettings}`;
    return this.http.post<any>(url, visitorParking, { headers })
      .pipe(
        tap(() => console.log('updated Visitor Parking Settings: ' + visitorParking.CarParkId)),
        catchError(this.commonService.handleError)
      );
  }

  updateVisitorParkingSlots(visitorParkingSlots: TimeSlot[]){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.uri.updateVisitorParkingSlots}`;
    return this.http.post<any>(url, visitorParkingSlots, { headers })
      .pipe(
        tap(() => console.log('updated Visitor Parking Slots: ')),
        catchError(this.commonService.handleError)
      );
  }

  updateVisitorParkingSlotsSpecial(visitorParkingSlotsSpecial: SpecialTimeSlot[]){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.uri.updateVisitorParkingSlotsSpecial}`;
    return this.http.post<any>(url, visitorParkingSlotsSpecial, { headers })
      .pipe(
        tap(() => console.log('updated Visitor Parking Slots Special: ')),
        catchError(this.commonService.handleError)
      );
  }

  deleteVisitorParkingSettings(carParkId: number): Observable<VisitorParkingLot>{
    const url = `${this.uri.deleteVisitorParkingSettings}/${carParkId}`;
    return this.http.delete<any>(url)
      .pipe(
        tap(() => console.log('deleted Visitor Parking Setting: ' + carParkId)),
        catchError(this.commonService.handleError)
      );
  }

    importVisitorParkingSettings(visitorParkings: VisitorParkingLot[]){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.uri.importvisitorparkinglot}`;
    return this.http.post<any>(url, visitorParkings, { headers })
      .pipe(
        tap(() => console.log('import Visitor Parking Settings: ')),
        catchError(this.commonService.handleError)
      );
  }



  // =======
  // Booking
  // =======
  getVisitorParkingBookingBySite(siteId: number): Observable<VisitorParkingBooking[]>{
    const url = `${this.uri.getVisitorParkingBookingBySite}/${siteId}`;
    return this.http.get<VisitorParkingBooking[]>(url)
      .pipe(
        tap(data =>
          console.log('getAllVisitorParkingBookingBySite')
        ),
        catchError(this.commonService.handleError)
      );
  }

  updateVisitorParkingBooking(visitorParkingBooking: VisitorParkingBookingUpdate){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.uri.updateVisitorParkingBooking}`;
    return this.http.post<any>(url, visitorParkingBooking, { headers })
      .pipe(
        tap(() => console.log('updated Visitor Parking Booking: ')),
        catchError(this.commonService.handleError)
      );
  }

  addVisitorParkingBooking(visitorParkingBooking: VisitorParkingBookingAdd){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.uri.addVisitorParkingBooking}`;
    return this.http.post<any>(url, visitorParkingBooking, { headers })
      .pipe(
        tap(() => console.log('added Visitor Parking Booking: ')),
        catchError(this.commonService.handleError)
      );
  }

  getAllWalkInVisitorBySite(siteId: number): Observable<WalkInVisitor[]>{
    const url = `${this.uri.getAllWalkInVisitorBySite}/${siteId}`;
    return this.http.get<WalkInVisitor[]>(url)
      .pipe(
        tap(data =>
          console.log('getAllWalkInVisitorBySite')
        ),
        catchError(this.commonService.handleError)
      );
  }

    updateWalkinVisitorNote(visitor: WalkInVisitor){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.uri.updateWalkInVisitorNote}`;
    return this.http.post<any>(url, visitor, { headers })
      .pipe(
        tap(() => console.log('updated Visitor note: ')),
        catchError(this.commonService.handleError)
      );
  }

}
