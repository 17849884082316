import { Component, OnInit,LOCALE_ID,Inject } from '@angular/core';
import {FormBuilder, FormGroup,FormControl, ValidationErrors, ValidatorFn, Validators, FormArray,FormGroupDirective,AbstractControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription,ReplaySubject,Observable} from 'rxjs';
import {AuthenticationService} from 'src/app/core/services/auth.service';
import {ProfilePicture, Role, SpecialView, User, VIEW_LIST_CONST} from 'src/app/core/models/user.models';
import {  DatePipe,formatDate,Location} from '@angular/common';
import { UnitService } from 'src/app/core/services/unit.service';
import { OccupantsService } from 'src/app/core/services/occupant.service';
import { AccessCardService } from 'src/app/core/services/access-card.service';
import { Occupant } from 'src/app/core/models/occupant.models';
import { CommonService } from 'src/app/core/services/common.service';
import { AccessCard } from 'src/app/core/models/accessCard.model';


@Component({
  selector: 'app-access-card-edit',
  templateUrl: './access-card-edit.component.html',
  styleUrls: ['./access-card-edit.component.css']
})
export class AccessCardEditComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  siteId=Number(localStorage.getItem('siteId'));
  accessCardForm!: FormGroup;
  cardTypes: string[] = ['Car','Pedestrian','Tenant'];
  cardStatus: string[] = ['Active','Inactive','Block'];
  reasons: string[] = ['Non-payment or Dues of Fees','Violation of Rules','Security Concerns','Lost or Stolen Card','Renovation or Maintenance Work','End of Lease or Ownership','Others'];  
  units: any[];
  currentUnit:any;
  filteredUnit: any;
  occupantsByUnit: Occupant[];
  selectedOccupant:Occupant;
  currentUser:User
  selectedCardStatus:string;
  cardRunningId: number;
  currentAccessCard: AccessCard;
  constructor(private formBuilder: FormBuilder,
              private authenticationService: AuthenticationService,              
              private router: Router,
              private route: ActivatedRoute,
              private datePipe:DatePipe,
              private unitService:UnitService,
              private occupantsService:OccupantsService,
              private accessCardService:AccessCardService,
              private commonService:CommonService,
              @Inject(LOCALE_ID) private locale: string) { }

  async ngOnInit() {
    
    this.currentUser = this.authenticationService.currentUser();
    this.buildAssetForm();
    this.getUnitsBySiteId();
    this.route.paramMap.subscribe(
    params => {
      this.cardRunningId = Number(this.route.snapshot.paramMap.get('id'));
      console.log(this.cardRunningId)
      this.getCurrentUserDataAndPatchForm();
    })
  }

    getCurrentUserDataAndPatchForm(){
    if(this.cardRunningId){
      this.subscriptions.push(
      this.accessCardService.getAccessCardById(this.cardRunningId)
          .subscribe({
            next: data => {
              this.currentAccessCard=data;
              console.log(this.currentAccessCard)
              this.getOccupantByUnitId(this.currentAccessCard.UnitInfo.UnitId)
              
              this.displayDetail();
            },
            error: err => this.commonService.ErrorNotification(err)
        })
      )
    }
  }

    get getAccessCardForm(){ return this.accessCardForm.controls; }

    buildAssetForm(){
      this.accessCardForm = this.formBuilder.group({
        CardType : ['', [Validators.required]],
        UnitInfo:['', [Validators.required]],
        OccupantInfo: ['', [Validators.required]],
        CardId: ['', [Validators.required]],
        Status: ['', [Validators.required]],
        Reason: [''],
        BlockFrom: [],
        BlockTo: [''],
        Notes: [''],
      })
  }

  getUnitsBySiteId(){
      this.subscriptions.push(
        this.unitService.getUnitsBySiteId(this.siteId).subscribe({
          next: units => {
                  this.units = units.map(item=> item.UnitInfo.Unit);
                  //filter duplicate unitname
                  const UnitNameList = this.units.map(x => x.UnitName);
                  this.units = this.units.filter(( Unit, index) => !UnitNameList.includes(Unit.UnitName, index + 1));
                  this.filteredUnit = this.units.slice();
                  
        this.accessCardForm.get('UnitInfo').setValue(this.units.find(x=>x.UnitId == this.currentAccessCard.UnitInfo.UnitId))
          },
          error: err => this.commonService.ErrorNotification(err)
        })
      )
  }

  getOccupantByUnitId(unitId:number){
    this.subscriptions.push(
      this.occupantsService.getOccupantsByUnit(unitId).subscribe({
        next: occupant => {
          console.log(occupant)
          this.occupantsByUnit = occupant
          
        this.accessCardForm.get('OccupantInfo').setValue(this.occupantsByUnit.find(x=>x.OccupantId == this.currentAccessCard.OccupantInfo.OccupantId))
        this.selectedOccupant = this.occupantsByUnit.find(x=>x.OccupantId == this.currentAccessCard.OccupantInfo.OccupantId);
        },
        error: err => this.commonService.ErrorNotification(err)
      })
    )
  }

  displayDetail(){
    this.accessCardForm.patchValue({
        CardType:  this.currentAccessCard.CardType,
        UnitInfo: null,
        OccupantInfo: null,
        CardId: this.currentAccessCard.CardId,
        Status: this.currentAccessCard.Status,
        Reason: this.currentAccessCard.Reason,
        BlockFrom: this.currentAccessCard.BlockFrom,
        BlockTo: this.currentAccessCard.BlockTo,
        Notes: this.currentAccessCard.Notes,
      });
      
        this.accessCardForm.get('UnitInfo').setValue(this.units.find(x=>x.UnitId == this.currentAccessCard.UnitInfo.UnitId))
        this.accessCardForm.get('OccupantInfo').setValue(this.occupantsByUnit.find(x=>x.OccupantId == this.currentAccessCard.OccupantInfo.OccupantId))
        this.selectedOccupant = this.occupantsByUnit.find(x=>x.OccupantId == this.currentAccessCard.OccupantInfo.OccupantId);

}

  submit(){
     if (this.accessCardForm.valid) {
      console.log('form valid for submition');
    } else {
      console.log('form invalid');
      this.validateAllFormFields(this.accessCardForm)
      this.commonService.ErrorNotification('Please fill in all required field');
      return;
    }
    console.log(this.accessCardForm.value)
  let newCard:AccessCard = new AccessCard();

if(this.cardRunningId != 0){
    newCard = this.currentAccessCard;
}

  newCard.CardRunningId = this.cardRunningId;
  newCard.CardId = this.getAccessCardForm.CardId.value;
  newCard.CardType = this.getAccessCardForm.CardType.value;
  newCard.OccupantInfo = this.getAccessCardForm.OccupantInfo.value;
  newCard.UnitInfo = this.getAccessCardForm.UnitInfo.value;
  newCard.Status = this.getAccessCardForm.Status.value;
  newCard.Reason = this.getAccessCardForm.Reason.value;
  newCard.BlockFrom = this.getAccessCardForm.BlockFrom.value;
  newCard.BlockTo = this.getAccessCardForm.BlockTo.value;
  newCard.Notes = this.getAccessCardForm.Notes.value;
  newCard.UpdatedBy = this.currentUser.UserName;
  newCard.UpdatedOn = new Date();

  console.log(newCard);

  if(this.cardRunningId == 0){
        this.accessCardService.addAccessCard(newCard)
      .subscribe({
        next: x => {
          this.commonService.SuccessNotification("Succesfully add access card");
          this.router.navigate(['/apps/access-card']);
        },
        error: err => {
          console.log(err)
            this.commonService.ErrorNotification(err);
        }
      });
  }else{
          this.accessCardService.updateAccessCard(newCard)
      .subscribe({
        next: x => {
          this.commonService.SuccessNotification("Succesfully Update access card");
          this.router.navigate(['/apps/access-card']);
        },
        error: err => {
          console.log(err)
            this.commonService.ErrorNotification(err);
        }
      });
  }

  }

    updateSelectedUnit(event){
      this.getOccupantByUnitId(event.value.UnitId);
    }

    occupantChanged(event){
      console.log(event)
      this.selectedOccupant = this.occupantsByUnit.find(x=>x.OccupantId == event.value.OccupantId);
    }

    validateAllFormFields(formGroup: FormGroup) {       
        Object.keys(formGroup.controls).forEach(field => { 
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
          } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control); 
          }
      });
    }

}
