import {Component, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
  VisitorParkingBooking, VisitorParkingBookingAdd,
  VisitorParkingBookingUpdate,
  VisitorParkingLot
} from '../../../../core/models/visitorParking.models';
import {Owner, OwnerInfo} from '../../../../core/models/owner.models';
import {UnitService} from '../../../../core/services/unit.service';
import {VisitorParkingService} from '../../../../core/services/visitorParking.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {InformationDialogComponent} from '../../../../shared/component/information-dialog/information-dialog.component';
import {from, Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate} from '@angular/common';
import {map, mergeMap, toArray} from 'rxjs/operators';
import {User} from '../../../../core/models/user.models';
import {AuthenticationService} from '../../../../core/services/auth.service';
import {UserService} from '../../../../core/services/user.service';
import {CommonService} from '../../../../core/services/common.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-visitor-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class VisitorFormComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  parkingBookingListLink = '/apps/visitor';

  @Input() action: string;

  user: User;
  userEditVisitorParkingAllowedAccess: boolean = false;
  userAddVisitorParkingAllowedAccess: boolean = false;

  siteId: number;
  bookingId: number;
  booking: VisitorParkingBooking;
  units: {UnitId, UnitName}[];
  visitorParkingLots: VisitorParkingLot[];
  parkingLotsFilteredByType: VisitorParkingLot[]; // filtered from CarParkType
  parkingLotSelected: VisitorParkingLot;
  timeSlotsChecked = [];
  parkingLotsUnavailableSpecialDates = [];

  bookingForm: FormGroup;
  allParkingBooking:VisitorParkingBooking[];

  constructor(private visitorParkingService: VisitorParkingService,
              private unitService: UnitService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              private route: ActivatedRoute,
              private router: Router,
              private commonService: CommonService,
              private datePipe: DatePipe,
              @Inject(LOCALE_ID) private locale: string) { }

  ngOnInit(): void {
    this.siteId = Number(localStorage.getItem('siteId'));
    // set user access
    this.user = this.authenticationService.currentUser();

    // get units from site id
    this.getUnits()

    // build booking form
    this.buildBookingForm()

    // get parking lot settings
    this.getParkingLotSettings()

    // get current booking id from url
    if(this.action === 'edit'){
      this.getBookingId()
    }
    
    this.userEditVisitorParkingAllowedAccess = this.authenticationService.isAllowAccess('Edit Visitor Parking');
    this.userAddVisitorParkingAllowedAccess = this.authenticationService.isAllowAccess('Add Visitor Parking');
    if (!this.userEditVisitorParkingAllowedAccess) {
      for (const field of Object.keys(this.bookingForm.controls)) {
        this.bookingForm.get(field).disable();
      }
    }
  }

  // ==========================
  // Functions for getting data
  // ==========================

  getUnits() {
    this.subscriptions.push(
      this.unitService.getUnitsBySiteId(this.siteId).subscribe({
        next: data => {
          this.units = data.map((ownerInfo: OwnerInfo) => ({
            UnitId: ownerInfo.UnitInfo.Unit.UnitId,
            UnitName: ownerInfo.UnitInfo.Unit.UnitName
          }))
          const UnitNameList = this.units.map(({ UnitName }) => UnitName);
          this.units = this.units.filter(({ UnitName }, index) => !UnitNameList.includes(UnitName, index + 1));
        },
        error: err => console.log(err)
      })
    )
  }

  getBookingId(){
    this.subscriptions.push(
      this.route.paramMap.subscribe(
        params => {
          this.bookingId = Number(this.route.snapshot.paramMap.get('id'));
        })
    )
  }

  getCurrentBooking(){

    this.subscriptions.push(
      this.visitorParkingService.getVisitorParkingBookingBySite(this.siteId).subscribe({
        next: data => {
          this.allParkingBooking = data;

          if(this.action === 'edit') {
            this.booking = data.find(booking => booking.BookingId === this.bookingId)
            this.displayData()
          }

        },
        error: err => console.log(err)
      })
    )
  }

  getParkingLotSettings(){
    // getAllVisitorParkingLotBySite and the TimeSlots using getVisitorParkingLotById
    this.subscriptions.push(
      this.visitorParkingService.getAllVisitorParkingLotBySite(this.siteId)
        .pipe(
          mergeMap((visitorParkingLots: VisitorParkingLot[]) =>
            from(visitorParkingLots).pipe(
              mergeMap((visitorParkingLot) =>
                this.visitorParkingService.getVisitorParkingLotById(visitorParkingLot.CarParkId)
              ),
              map(data => { return data }),
              toArray(),
            )
          )
        )
        .subscribe({
          next: data => {
            this.visitorParkingLots = data

            // create a list to filter special date in VisitorParkingSpecialTimeSlotList
            this.visitorParkingLots.map(visitorParkingLot => {
              if(visitorParkingLot.VisitorParkingSpecialTimeSlotList !== null){
                visitorParkingLot.VisitorParkingSpecialTimeSlotList.map(specialTimeSlot => {
                  this.parkingLotsUnavailableSpecialDates.push({
                    SpecialDate: new Date(specialTimeSlot.SpecialDate),
                    CarParkId: specialTimeSlot.CarParkId
                  })
                })
              }
            })

            //if(this.action === 'edit') // get current booking and patch data if action === 'edit'
              this.getCurrentBooking()
            if(this.action === 'add') // set default data if action === 'add'
              this.setDefaultParkingLotAndTimeSlot()

          },
          error: err => { console.log(err) }
        })
    )
  }


  // ======================
  // Form related functions
  // ======================

  get getBookingForm(){ return this.bookingForm.controls }

  buildBookingForm(){ // TODO: QRcode, CreatedOn, CreatedBy, RegisteredBy?
    this.bookingForm = this.formBuilder.group({
      VisitorPark: ['', [Validators.required]],
      UnitId: ['', [Validators.required]],
      VisitorName: ['', [Validators.required]],
      VisitorContact: ['', [Validators.required]],
      Note: '',
      CarPlateNo: ['', [Validators.required]],
      ParkingType: ['Visitor Parking', [Validators.required]],
      VisitorType: ['Visitor', [Validators.required]],
      VehicleType: ['', [Validators.required]],
      RegisteredBy: [this.user.UserIdentityName],
      AccessMethod: ['QR Code', [Validators.required]],
      OvernightParking: [false, [Validators.required]],
      BookingDate: ['', [Validators.required]],
      CarParkId: ['', [Validators.required]],
      StartParkingTimeSlot: ['', [Validators.required]],
      EndParkingTimeSlot: ['', [Validators.required]]
    })
  }

  setDefaultParkingLotAndTimeSlot(){

    // initialise parkinglotsSelected
    this.parkingLotsFilteredByType = this.visitorParkingLots.filter(visitorParkingLot => visitorParkingLot.CarParkType === 'Visitor Parking');
    this.subscriptions.push(
      this.visitorParkingService.getVisitorParkingLotById(this.parkingLotsFilteredByType[0].CarParkId).subscribe({
        next: data => {
          this.parkingLotSelected = data

            // patch form values
          this.bookingForm.patchValue({
            ParkingType: 'Visitor Parking',
            CarParkId: this.parkingLotSelected.CarParkId
          })
        },
        error: err => console.log(err)
      })
    )
  }

  displayData(){
    this.bookingForm.patchValue({
      UnitId: this.booking.UnitId,
      VisitorName: this.booking.VisitorName,
      VisitorContact: this.booking.VisitorContact,
      Note: this.booking.Note,
      CarPlateNo: this.booking.CarPlateNo,
      VehicleType:this.booking.VehicleType,
      VisitorType:this.booking.VisitorType,
      RegisteredBy:this.booking.RegisteredBy,
      AccessMethod:this.booking.AccessMethod,
      ParkingType: this.booking.VisitorParkingLot.CarParkType,
      BookingDate: formatDate(new Date(this.booking.BookingDate),'yyyy-MM-dd', this.locale),
      CarParkId: this.booking.VisitorParkingLot.CarParkId,
      StartParkingTimeSlot: {TimeSlotId: this.booking.StartParkingTimeSlot.TimeSlotId},
      EndParkingTimeSlot: {TimeSlotId: this.booking.EndParkingTimeSlot.TimeSlotId}
    })

    // patch Parking Lot selected (Parking Lot Name in the form)
    this.parkingLotsFilteredByType = this.visitorParkingLots.filter(visitorParkingLot => visitorParkingLot.CarParkType === this.getBookingForm.ParkingType.value);
    this.bookingForm.patchValue({CarParkId: this.booking.VisitorParkingLot.CarParkId});

    // patch parkingLotSelected with the data of VisitorParkingTimeSlotList
    this.subscriptions.push(
      this.visitorParkingService.getVisitorParkingLotById(this.booking.VisitorParkingLot.CarParkId).subscribe({
        next: data => {
          this.parkingLotSelected = data
          this.reloadAvailableTimeSlot();
        },
        error: err => console.log(err)
      })
    )

    // patch time slot (check time slot checkbox buttons)
    for(let i = this.booking.StartParkingTimeSlot.SlotTimeNo; i <= this.booking.EndParkingTimeSlot.SlotTimeNo; i++){
      if(!this.timeSlotsChecked.includes(i))
        this.timeSlotsChecked.push(i)
    }

  }

  // =============================================
  // Parking Type & Parking Type Related Functions
  // =============================================

  onParkingTypeChange($event){
    this.timeSlotsChecked.length = 0;
    const selectedCarParkType = $event.value
    this.parkingLotsFilteredByType = this.visitorParkingLots.filter(visitorParkingLot => visitorParkingLot.CarParkType === selectedCarParkType)

    // clear time slots that belongs to only a certain parking lot
    this.timeSlotsChecked = [];
    this.bookingForm.patchValue({
      StartParkingTimeSlot: null,
      EndParkingTimeSlot: null,
      CarParkId: this.parkingLotsFilteredByType[0].CarParkId
    })

    // set new parkingLotSelected to be used to display time slots
    this.subscriptions.push(
      this.visitorParkingService.getVisitorParkingLotById(this.parkingLotsFilteredByType[0].CarParkId).subscribe({
        next: data => {
          this.parkingLotSelected = data
          this.reloadAvailableTimeSlot();
        },
        error: err => console.log(err)
      })
    )

    if(this.action === 'edit'){
      // if selectedCarParkType is the default CarParkType, patch default parking lot selected and time slot selected
      if (selectedCarParkType === this.booking.VisitorParkingLot.CarParkType){

        // patch form values
        this.bookingForm.patchValue({
          StartParkingTimeSlot: {TimeSlotId: this.booking.StartParkingTimeSlot.TimeSlotId},
          EndParkingTimeSlot: {TimeSlotId: this.booking.EndParkingTimeSlot.TimeSlotId},
          CarParkId: this.booking.VisitorParkingLot.CarParkId
        })

        // patch default time slot selected (check time slot checkbox buttons)
        for(let i = this.booking.StartParkingTimeSlot.SlotTimeNo; i <= this.booking.EndParkingTimeSlot.SlotTimeNo; i++){
          if(!this.timeSlotsChecked.includes(i))
            this.timeSlotsChecked.push(i)
        }
      }
    }

  }

  onParkingLotSelectionChange($event){
    this.timeSlotsChecked.length = 0;
    const selectedCarParkId = $event.value

    // clear time slots that belongs to only a certain parking lot
    this.timeSlotsChecked = [];
    this.bookingForm.patchValue({
      StartParkingTimeSlot: null,
      EndParkingTimeSlot: null,
    })

    // set new parkingLotSelected to be used to display time slots
    this.subscriptions.push(
      this.visitorParkingService.getVisitorParkingLotById(selectedCarParkId).subscribe({
        next: data => {
          this.parkingLotSelected = data
          this.reloadAvailableTimeSlot();
        },
        error: err => console.log(err)
      })
    )

    if(this.action === 'edit'){
      // if selectedCarParkId is the default CarParkId, patch default time slot selected
      if (selectedCarParkId === this.booking.VisitorParkingLot.CarParkId){

        // patch form value
        this.bookingForm.patchValue({
          StartParkingTimeSlot: {TimeSlotId: this.booking.StartParkingTimeSlot.TimeSlotId},
          EndParkingTimeSlot: {TimeSlotId: this.booking.EndParkingTimeSlot.TimeSlotId},
          CarParkId: this.booking.VisitorParkingLot.CarParkId
        })

        // patch time slot (check time slot checkbox buttons)
        for(let i = this.booking.StartParkingTimeSlot.SlotTimeNo; i <= this.booking.EndParkingTimeSlot.SlotTimeNo; i++){
          if(!this.timeSlotsChecked.includes(i))
            this.timeSlotsChecked.push(i)
        }
      }
    }

  }

  // =============================
  // Booking Date related function
  // =============================
  // filter the parking lot available based on special date (parking lot will be hidden if the bookingDate == its special date)
  onBookingDateChange(){
    this.timeSlotsChecked.length = 0;
    this.parkingLotsUnavailableSpecialDates.map(specialDateParkingLot => {
      const bookingDate = new Date(this.getBookingForm.BookingDate.value)
      const specialDate = new Date(specialDateParkingLot.SpecialDate)
      if(bookingDate.getDate() === specialDate.getDate()){
        this.parkingLotsFilteredByType = this.parkingLotsFilteredByType.filter(visitorParkingLot => visitorParkingLot.CarParkId !== specialDateParkingLot.CarParkId)
      }
    })

    // set new parkingLotSelected to be used to display time slots
    this.subscriptions.push(
      this.visitorParkingService.getVisitorParkingLotById(this.parkingLotsFilteredByType[0].CarParkId).subscribe({
        next: data => {
          this.parkingLotSelected = data
          this.reloadAvailableTimeSlot();
        },
        error: err => console.log(err)
      })
    )

  }

  reloadAvailableTimeSlot(){
    
    this.parkingLotSelected?.VisitorParkingTimeSlotList.forEach(x=>x.IsBooked = false)

    let bookedSlot = this.allParkingBooking.filter(x=>x.CarParkId == this.parkingLotSelected?.CarParkId && 
      this.commonService.isoDateWithoutTimeZone(x.BookingDate) == this.commonService.isoDateWithoutTimeZone(this.getBookingForm.BookingDate.value))
    bookedSlot.forEach(book=>{
      if(this.bookingId == book.BookingId)
        return;
        let temp = this.parkingLotSelected?.VisitorParkingTimeSlotList.filter(x=>x.SlotTimeNo >= book.StartParkingTimeSlot.SlotTimeNo && x.SlotTimeNo <= book.EndParkingTimeSlot.SlotTimeNo)
        temp.forEach(y=>{
          y.IsBooked = true;
        })
      }
    )

      if(this.commonService.isoDateWithoutTimeZone(this.getBookingForm.BookingDate.value) == this.commonService.isoDateWithoutTimeZone(new Date()))
      {
        this.parkingLotSelected?.VisitorParkingTimeSlotList.forEach(z=>{
          let hour = z.SlotTimeTo.split(':')[0]
          let min = z.SlotTimeTo.split(':')[1]
          
          if(hour == '00' && min == '00'){
            hour = '23';
            min = '59';
          }
          if(Number(hour) < new Date().getHours()) {
            z.IsBooked = true;
          }else if(Number(hour) == new Date().getHours()){
            if(Number(min)<= new Date().getMinutes()){
              z.IsBooked = true; 
            }
          }

        })
      }else if (this.commonService.isoDateWithoutTimeZone(this.getBookingForm.BookingDate.value) < this.commonService.isoDateWithoutTimeZone(new Date())){
        this.parkingLotSelected?.VisitorParkingTimeSlotList.forEach(z=>{
          z.IsBooked = true;
        })
      }
  }

  openImageDialog(){
    let img
    this.commonService.readFileFromURL(this.parkingLotSelected.ParkingLotMap).toPromise().then(
            (res)=>{
              const reader = new FileReader();
              reader.readAsDataURL(res); 
              reader.onloadend = () => {
                  const base64data = reader.result;   

                  //this.fileObj.fileBase64=base64data.toString();
                  img =  base64data.toString();
                  const dialogConfig = new MatDialogConfig();
                  dialogConfig.disableClose = true;
                  dialogConfig.autoFocus = true;
                  dialogConfig.width = '600px';
                  dialogConfig.height = 'auto';
                  dialogConfig.data = {
                    title: 'Parking Lot Location',
                    image: img,
                    imageWidth: 500,
                    imageHeight: 500,
                  }
                  let dialogRef: MatDialogRef<any>;
                  dialogRef = this.dialog.open(InformationDialogComponent, dialogConfig);
              }
            }
          )
  }
  // ====================================
  // Functions to check time slot buttons
  // ====================================

  checkTimeSlot(timeSlotId, timeSlotNo){ // check here means checking the checkbox
    const timeSlotSelected = timeSlotNo

    // check time slots
    if(this.timeSlotsChecked.includes(timeSlotSelected)){
      this.timeSlotsChecked = this.timeSlotsChecked.filter(timeSlot => timeSlot !== timeSlotSelected)
    }
    else{
      this.timeSlotsChecked.push(timeSlotSelected)
    }

    const smallestTimeSlotNo = Math.min(...this.timeSlotsChecked.map(Number))
    const biggestTimeSlotNo = Math.max(...this.timeSlotsChecked.map(Number))

    // * EDGE CASE: limit time slots selection based on slotPerBooking
    // if user selected time slots that exceeds this.parkingLotSelected.SlotPerBooking
    if(biggestTimeSlotNo - smallestTimeSlotNo + 1 > this.parkingLotSelected.SlotPerBooking){
      this.timeSlotsChecked = this.timeSlotsChecked.filter(timeSlot => timeSlot !== timeSlotSelected)
      // alert('This parking lot is only limited to ' + this.parkingLotSelected.SlotPerBooking + ' per booking.')
      this.openInformationDialog('WARNING', 'This parking lot is only limited to ' + this.parkingLotSelected.SlotPerBooking + ' slots per booking.')
    }
    else{
      // if timeSlotsChecked are not continuous, help user check middle time slots
      if(biggestTimeSlotNo - smallestTimeSlotNo > 1){
        for(let i = smallestTimeSlotNo+1; i < biggestTimeSlotNo; i++){
          if(!this.timeSlotsChecked.includes(i))
            this.timeSlotsChecked.push(i)
        }
      }
    }

    if(isFinite(smallestTimeSlotNo) || isFinite(biggestTimeSlotNo)) {
      // patch value for form
      this.bookingForm.patchValue({
        StartParkingTimeSlot: {
          TimeSlotId: this.parkingLotSelected.VisitorParkingTimeSlotList.find(timeSlot => timeSlot.SlotTimeNo === smallestTimeSlotNo).TimeSlotId
        },
        EndParkingTimeSlot: {
          TimeSlotId: this.parkingLotSelected.VisitorParkingTimeSlotList.find(timeSlot => timeSlot.SlotTimeNo === biggestTimeSlotNo).TimeSlotId
        }
      })
    }

    // if timeSlotsChecked is 0, make StartParkingTimeSlot and EndParkingTimeSlot null to disable form submimssion
    if(this.timeSlotsChecked.length === 0){
      this.bookingForm.patchValue({
        StartParkingTimeSlot: null,
        EndParkingTimeSlot: null,
      })
    }

  }


  // ======
  // submit
  // ======

  submit(){
    if(this.action === 'edit'){
      const visitorParkingBookingUpdate = new VisitorParkingBookingUpdate()
      visitorParkingBookingUpdate.BookingId = this.booking.BookingId
      visitorParkingBookingUpdate.UnitId = this.getBookingForm.UnitId.value
      visitorParkingBookingUpdate.SiteId = this.booking.SiteId
      visitorParkingBookingUpdate.CarParkId = this.getBookingForm.CarParkId.value
      visitorParkingBookingUpdate.VehicleType = this.getBookingForm.VehicleType.value
      visitorParkingBookingUpdate.VisitorType = this.getBookingForm.VisitorType.value
      visitorParkingBookingUpdate.OvernightParking = this.getBookingForm.OvernightParking.value
      visitorParkingBookingUpdate.AccessMethod = this.getBookingForm.AccessMethod.value
      visitorParkingBookingUpdate.RegisteredBy = this.getBookingForm.RegisteredBy.value


      visitorParkingBookingUpdate.VisitorName = this.getBookingForm.VisitorName.value
      visitorParkingBookingUpdate.VisitorContact = this.getBookingForm.VisitorContact.value
      visitorParkingBookingUpdate.CarPlateNo = this.getBookingForm.CarPlateNo.value
      visitorParkingBookingUpdate.Note = this.getBookingForm.Note.value
      visitorParkingBookingUpdate.StartParkingTimeSlot = this.getBookingForm.StartParkingTimeSlot.value
      visitorParkingBookingUpdate.EndParkingTimeSlot = this.getBookingForm.EndParkingTimeSlot.value
      visitorParkingBookingUpdate.BookingDate = new Date(formatDate(this.getBookingForm.BookingDate.value,'yyyy-MM-dd', this.locale))
      visitorParkingBookingUpdate.NeedParking = true;
      console.log(visitorParkingBookingUpdate)
      this.subscriptions.push(
        this.visitorParkingService.updateVisitorParkingBooking(visitorParkingBookingUpdate).subscribe({
          next: () => this.router.navigate([this.parkingBookingListLink]),
          error: err => console.log(err)
        })
      )
    }

    else if(this.action === 'add') {
      const visitorParkingBookingAdd = new VisitorParkingBookingAdd()
      visitorParkingBookingAdd.UnitId = this.getBookingForm.UnitId.value
      visitorParkingBookingAdd.SiteId = this.siteId
      visitorParkingBookingAdd.VisitorName = this.getBookingForm.VisitorName.value
      visitorParkingBookingAdd.VisitorContact = this.getBookingForm.VisitorContact.value
      visitorParkingBookingAdd.CarPlateNo = this.getBookingForm.CarPlateNo.value
      visitorParkingBookingAdd.CarParkId = this.getBookingForm.CarParkId.value
      visitorParkingBookingAdd.VehicleType = this.getBookingForm.VehicleType.value
      visitorParkingBookingAdd.VisitorType = this.getBookingForm.VisitorType.value
      visitorParkingBookingAdd.OvernightParking = this.getBookingForm.OvernightParking.value
      visitorParkingBookingAdd.AccessMethod = this.getBookingForm.AccessMethod.value
      visitorParkingBookingAdd.RegisteredBy = this.getBookingForm.RegisteredBy.value
      visitorParkingBookingAdd.CreatedOn = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss', this.locale),

      visitorParkingBookingAdd.Note = this.getBookingForm.Note.value
      visitorParkingBookingAdd.StartParkingTimeSlot = this.getBookingForm.StartParkingTimeSlot.value
      visitorParkingBookingAdd.EndParkingTimeSlot = this.getBookingForm.EndParkingTimeSlot.value
      visitorParkingBookingAdd.BookingDate = new Date(formatDate(this.getBookingForm.BookingDate.value,'yyyy-MM-dd', this.locale))
      visitorParkingBookingAdd.NeedParking = true;
      console.log(visitorParkingBookingAdd)
      console.log(this.bookingForm)
      
      this.subscriptions.push(
        this.visitorParkingService.addVisitorParkingBooking(visitorParkingBookingAdd).subscribe({
          next: () => this.router.navigate([this.parkingBookingListLink]),
          error: err => console.log(err)
        })
      )
    }

  }

  openInformationDialog(title, message){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      title,
      message
    }

    let dialogRef: MatDialogRef<any>;
    dialogRef = this.dialog.open(InformationDialogComponent, dialogConfig);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
