import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})

export class VendorAccountService {
  htmlContent: string = `
  <div class="row">
  <div class="col">
      <div class="card border-gray-light">
          <div class="card-body">
              <div class="row mb-2">
                  <div class="col-sm-3">
                      <img src="../../../../../../assets/images/propertygaga/invoicelogo.png" alt="" class="align-self-center img-fluid mb-3">
                  </div>
                  <div class="col-sm-3"></div>
                  <div class="col-sm-6">
                      <h6 class="m-0">Badan Pengurusan Bersama 3 Resident</h6>
                      <address class="mt-2 font-size-13">
                          <small class="text-muted">Managed by</small><br>
                          <span class="font-weight-medium">EAD Global Management Consultant (77881-T)</span><br>
                          1E-B-01, Management Office, Jalan Sinaran 1,<br>11800 Penang, Malaysia.<br>
                          604 2966 114 / 016 2956 6114 <span class="ml-sm-3">3resident.mgt@gmail.com</span>
                      </address>
                  </div>
              </div>

              <div class="border-grayform mt-3 mb-3"></div>

              <div class="clearfix">
                  <div class="float-sm-left col-sm-6 col-md-6 col-lg-6">
                      <div class="row mb-2">
                          <div class="col col-md-6">
                              <h6 class="font-size-16 mt-0">To</h6>
                              <address>
                                  ##vendorAddress1##<br>
                                  ##vendorAddress2##<br>
                                  ##vendorCity##<br>
                                  ##vendorCountry##<br>
                              </address>
                          </div>
                      </div>
                  </div>
                  <div class="float-sm-right col-sm-6 col-md-6 col-lg-6">
                      <div class="row mb-1">
                          <div class="col col-sm-4 font-weight-normal">Statement Date:</div>
                          <div class="col col-sm-8 font-weight-normal">##startDate##</div>
                      </div>
                      <div class="row mb-1">
                          <div class="col col-sm-4 font-weight-normal">Due Date:</div>
                          <div class="col col-sm-8 font-weight-normal">##dueDate##</div>
                      </div>
                  </div>
              </div>

              <div class="row p-2 mb-4">
                  <div class="col-md-6">
                      <h4>
                          <span class="text-primary">Statement of Accounts</span>
                          <br>
                          <small *ngIf="startDate != null && dueDate != null">
                              ##startDate## to ##dueDate##
                          </small>
                      </h4>
                  </div>
                  <div class="col-md-6 text-md-right border-grayform rounded-5">
                      <div class="row bg-light">
                          <div class="col col-md-12 text-md-right">
                              <h6 class="font-weight-normal">Account Summary</h6>
                          </div>
                      </div>
                      <div class="row mb-1">
                          <div class="col col-md-6">Balance b/f</div>
                          <div class="col ol-md-6">RM ##balance##</div>
                      </div>
                      <div class="row mb-1">
                          <div class="col col-md-6">Invoiced Amount</div>
                          <div class="col col-md-6">RM ##invoiceAmmout##</div>
                      </div>
                      <div class="row mb-1">
                          <div class="col col-md-6">Balance Due</div>
                          <div class="col col-md-6">RM ##balanceDue##</div>
                      </div>
                  </div>
              </div>

              <h6>Account Activities</h6>
              <div class="row">
                  <div class="col-12">
                      <table class="table table-hover nowrap dt-responsive full-width" style="margin-top: 15px;">
                          <thead>
                              <tr>
                                  <th>Date</th>
                                  <th>Transaction</th>
                                  <th>Description</th>
                                  <th>RefNo</th>
                                  <th>Amount</th>
                                  <th>Payment</th>
                                  <th>Balance</th>
                              </tr>
                          </thead>
                          <tbody>
                              ##tableContent##
                          </tbody>
                      </table>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
  `

  exportToPdf(vendorPdfData) {
    let tableContent = '';

    if(vendorPdfData.vendorAccountDetails) {
      for (let i = 0; i < vendorPdfData.vendorAccountDetails.length; i++) {
          tableContent += `
              <tr>
              <td>${moment(vendorPdfData.vendorAccountDetails[i].Date).format('DD MMM yyyy')}</td>
              <td>${vendorPdfData.vendorAccountDetails[i].Transactions.substring(0, 10)}</td>
              <td>${vendorPdfData.vendorAccountDetails[i].Description.substring(0, 8)}</td>
              <td>${vendorPdfData.vendorAccountDetails[i].RefNo}</td>
              <td>RM ${Math.round(vendorPdfData.vendorAccountDetails[i].Amount).toFixed(2)}</td>
              <td>RM ${Math.round(vendorPdfData.vendorAccountDetails[i].Payments).toFixed(2)}</td>
              <td>RM ${Math.round(vendorPdfData.vendorAccountDetails[i].Balance).toFixed(2)}</td>
              </tr>
          `;
      }
    }

    this.htmlContent =  this.htmlContent.replace(/##tableContent##/g, tableContent);
    this.htmlContent =  this.htmlContent.replace(/##vendorAddress1##/g, vendorPdfData.vendorDetail.VendorAddress1);
    this.htmlContent =  this.htmlContent.replace(/##vendorAddress2##/g, vendorPdfData.vendorDetail.VendorAddress2);
    this.htmlContent =  this.htmlContent.replace(/##vendorCity##/g, vendorPdfData.vendorDetail.VendorCity);
    this.htmlContent =  this.htmlContent.replace(/##vendorCountry##/g, vendorPdfData.vendorDetail.VendorCountry);
    this.htmlContent =  this.htmlContent.replace(/##startDate##/g, moment(vendorPdfData.startDate).format('DD MMM yyyy'));
    this.htmlContent =  this.htmlContent.replace(/##dueDate##/g, moment(vendorPdfData.dueDate).format('DD MMM yyyy'));
    this.htmlContent =  this.htmlContent.replace(/##balance##/g, Math.round(vendorPdfData.balance).toFixed(2));
    this.htmlContent =  this.htmlContent.replace(/##invoiceAmmout##/g, Math.round(vendorPdfData.invoiceAmmout).toFixed(2));
    this.htmlContent =  this.htmlContent.replace(/##balanceDue##/g, Math.round(vendorPdfData.balanceDue).toFixed(2));

   // Assuming this.htmlContent is your HTML content

  let doc = new jsPDF('p', 'mm', 'a4');

  doc.html(this.htmlContent, {
      callback: function (doc) {
          // Save the PDF
          doc.save(`vendorAccount.pdf`);
      },
      margin: [0, 0, 0, 0],
      autoPaging: 'text',
      x: 0,
      y: 0,
      width: 210, //target width in the PDF document
      windowWidth: 786 // window width in CSS pixels
    });
  }
}