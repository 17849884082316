export class Vendor{
    VendorID:number | null;
	SiteId:number;
    VendorName:string;
	CompanyRegisterNumber:string;
    VendorAddress1:string;
	VendorAddress2:string;
    VendorCity:string;
	VendorState:string;
	VendorCountry:string;
	VendorPostCode:string;
	VendorService:string;
	VendorEmail:string;
    VendorContact:string;   
    VendorFaxNo:string;
	IsContract:boolean;
    VendorContractStartDate:string;
    VendorContractEndDate:string;
	VendorMonthlyFee:number;
	UpdatedOn: string;
    UpdatedBy: string;
	AttachmentFileList?:any | null;
	Active?: boolean | null;
	Terminated?: boolean | null;
}

export class VendorAttachmentDelete {
	AttachmentId: number | null;
  }