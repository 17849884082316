import {formatDate} from '@angular/common';

export class Account {
    AccountType: string;
    AccountGroup: string;
    Remarks: string = '';
    AccountDetails: AccountDetail[] = new Array();
}

export class AccountDetail {
  COAId: number = 0;
  SiteId: number = 0;
  AccountIndex: string = '';
  AccountName: string = '';
  AccountDescription: string = '';
  AccountGroup: string = '';
  AccountType: string = '';
  AccountClosed: string = '';
  IsParent: boolean = false;
  SubAccountIndex: string = '';
  SubAccountName: string = '';
  BillEnabled: boolean = false;
  ConsolidateRnP: boolean = false;
  ExcludeLPI: boolean = false;
  ReceiptEnabled: boolean = false;
  PaymentEnabled: boolean = false;
  PaymentSouce: boolean = false;
  AllowPrepayment: boolean = false;
  DepositTracking: boolean = false;
  CreatedOn: Date = new Date();
  CreatedBy: string = '';
  UpdatedOn: Date = new Date();
  UpdatedBy: string = '';
}

export class ChartOfAccount {
  AccountType: string;
  AccountGroup: string;
  Remarks: string = '';
  AccountDetails: ChartOfAccountDetail[] = new Array<ChartOfAccountDetail>();
}

export class ChartOfAccountDetail {
  coaId: number;
  siteId: number;
  accName: string;
  accType: string;
  accGroup: string;
  accId: string;
  accDesc: string;
  systemAcc: string;
  subAccName: string;
  subAccID: string;
  accClosed: string;
  paymentSouce: boolean;
  consolidateRP: boolean;
  paymentEnabled: boolean;
  billEnabled: boolean;
  receiptEnabled: boolean;
  exclLPI: boolean;
  allowPrepayment: boolean;
  depositTracking: boolean;
  createdon: Date;
  createdBy: string;
  updatedby: string;
  updatedon: Date;
  archieved: boolean;
}

export class ChartOfAccountType{
  AccountType: string;
  AccountGroup: string[] = new Array<string>();
}