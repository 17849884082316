import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {VisitorParkingService} from '../../../core/services/visitorParking.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Subscription} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {VisitorParkingBooking, VisitorParkingBookingUpdate, VisitorParkingLot} from '../../../core/models/visitorParking.models';
import {NoteDialogComponent} from './note-dialog/note-dialog.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UnitService} from '../../../core/services/unit.service';
import {Owner, OwnerInfo} from '../../../core/models/owner.models';
import {User, VIEW_LIST_CONST} from '../../../core/models/user.models';
import {AuthenticationService} from '../../../core/services/auth.service';
import {UserService} from '../../../core/services/user.service';
import {CommonService} from '../../../core/services/common.service';

@Component({
  selector: 'app-visitor',
  templateUrl: './visitor.component.html',
  styleUrls: ['./visitor.component.css']
})
export class VisitorComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  editVisitorParkingBookingLink = '/apps/visitor/visitor-edit'
  addVisitorLink = '/apps/visitor/add'

  user: User;
  userAddVisitorParkingAllowedAccess: boolean = false;
  userEditVisitorParkingAllowedAccess: boolean = false;

  siteId: number;

  visitorParkingBookings: VisitorParkingBooking[];
  visitorParkingSlots: VisitorParkingLot[];
  ownerInfos: OwnerInfo[];
  VisitorParkingBookingTableValues;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  matTableDataSource;
  columns: string[] = ['No', 'Status','UnitName', 'Visitor','RegisteredOn', 'RegisteredBy', 'ParkingType', 'ParkingLotName', 'ParkingSlot', 'CarPlateNo','BookingStart','BookingEnd','BookingDuration', 'CheckIn', 'CheckOut', 'Note'];
  displayedColumns: Record<string, string> = {No: 'No',Status:'Status', UnitName: 'Unit to Visit', Visitor: 'Visitor',RegisteredOn: 'RegisteredOn', RegisteredBy: 'Registered by', ParkingType: 'Parking Type', ParkingLotName: 'Parking Lot Name', ParkingSlot: 'Parking Slot', CarPlateNo: 'Car Plate No.', BookingStart:'Booking Start',BookingEnd:'Booking End', BookingDuration: 'Booking Duration', CheckIn: 'Check-in', CheckOut: 'Check-out', Note: 'Note'};
  filterForm: FormGroup;
  filterType: string;

  constructor(private visitorParkingService: VisitorParkingService,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private unitService: UnitService,
              private commonService: CommonService,
              public dialog: MatDialog,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.siteId = Number(localStorage.getItem('siteId'));
    // set user access
    this.user = this.authenticationService.currentUser();
    this.userAddVisitorParkingAllowedAccess = this.authenticationService.isAllowAccess('Add Visitor Parking');
    this.userEditVisitorParkingAllowedAccess = this.authenticationService.isAllowAccess('Edit Visitor Parking');

    this.getParkingBookings()
    this.getParkingSlots()
    this.getUnits()

    this.filterForm = this.formBuilder.group({
      dateFilter: '',
      searchBox: '',
      parkingLotNameFilter: '',
      parkingTypeFilter: '' // todo: delete
    });
  }

  // =====================
  // Functions to Get Data
  // =====================
  // get parking bookings
  getParkingBookings() {
    this.subscriptions.push(
      this.visitorParkingService.getVisitorParkingBookingBySite(this.siteId).subscribe({
        next: data => {
          this.visitorParkingBookings = data
          console.log(this.visitorParkingBookings)
          this.initialiseTableValues()
        },
        error: err => {console.log(err.status)}
      })
    )
  }

  // get parking slots
  getParkingSlots() {
    this.subscriptions.push(
      this.visitorParkingService.getAllVisitorParkingLotBySite(this.siteId).subscribe({
        next: data => {
          this.visitorParkingSlots = data
          console.log(this.visitorParkingSlots)
        },
        error: err => { console.log(err) }
      })
    )
  }

  // get units
  getUnits(){
    this.subscriptions.push(
      this.unitService.getUnitsBySiteId(this.siteId).subscribe({
          next: data => {
            this.ownerInfos = data
          },
          error: err => console.log(err)
        }
      )
    )
  }

  // =======================================
  // Functions related to table manipulation
  // =======================================

  initialiseTableValues(){
    this.VisitorParkingBookingTableValues = this.visitorParkingBookings.map((visitorParkingBooking) => {
      const parkingSlots: string[] = [];
      for(let i = visitorParkingBooking.StartParkingTimeSlot.SlotTimeNo; i <= visitorParkingBooking.EndParkingTimeSlot.SlotTimeNo; i++){
        parkingSlots.push(' Slot ' + i)
      }
      let Status: string;
      if(visitorParkingBooking.ApprovedBy == null || visitorParkingBooking.ApprovedOn == null ){
        Status = "Awaiting Approval";
      }else{
        Status = "Approved";
      }
      if(visitorParkingBooking.CheckInTime == null &&  this.commonService.isoDateWithoutTimeZone(new Date()) > this.commonService.isoDateWithoutTimeZone(visitorParkingBooking.BookingDate) ){
        Status = "Expired";
      }else if(visitorParkingBooking.CheckInTime == null &&  this.commonService.isoDateWithoutTimeZone(new Date()) == this.commonService.isoDateWithoutTimeZone(visitorParkingBooking.BookingDate) ){

          let hour = visitorParkingBooking.EndParkingTimeSlot.SlotTimeTo.split(':')[0]
          let min = visitorParkingBooking.EndParkingTimeSlot.SlotTimeTo.split(':')[1]
          
          if(Number(hour) < new Date().getHours()) {
            Status = "Expired";
          }else if(Number(hour) == new Date().getHours()){
            if(Number(min)<= new Date().getMinutes()){
               Status = "Expired";
            }
          }
      }
      else if(visitorParkingBooking.CheckInTime != null && visitorParkingBooking.CheckOutTime == null &&  this.commonService.isoDateWithoutTimeZone(new Date()) > this.commonService.isoDateWithoutTimeZone( visitorParkingBooking.BookingDate) ){
        Status = "Time Exceed";
      }
      else if(visitorParkingBooking.CheckInTime != null && visitorParkingBooking.CheckOutTime == null &&  this.commonService.isoDateWithoutTimeZone(new Date()) == this.commonService.isoDateWithoutTimeZone( visitorParkingBooking.BookingDate) ){
          let hour = visitorParkingBooking.EndParkingTimeSlot.SlotTimeTo.split(':')[0]
          let min = visitorParkingBooking.EndParkingTimeSlot.SlotTimeTo.split(':')[1]
          
          if(Number(hour) < new Date().getHours()) {
            Status = "Time Exceed";
          }else if(Number(hour) == new Date().getHours()){
            if(Number(min)<= new Date().getMinutes()){
               Status = "Time Exceed";
            }
          }
      }

      return {
        BookingId: visitorParkingBooking.BookingId,
        Status: Status,
        UnitId: visitorParkingBooking.UnitId,
        SiteId: visitorParkingBooking.SiteId,
        CarParkId: visitorParkingBooking.VisitorParkingLot.CarParkId,
        VisitorName: visitorParkingBooking.VisitorName,
        VisitorContact: visitorParkingBooking.VisitorContact,
        RegisteredOn: visitorParkingBooking.CreatedOn,
        RegisteredBy: visitorParkingBooking.RegisteredBy,
        ParkingType: visitorParkingBooking.VisitorParkingLot.CarParkType,
        ParkingLotName: visitorParkingBooking.VisitorParkingLot.ParkingLotName,
        ParkingSlot: parkingSlots.toString(),
        BookingStart: new Date().setHours(Number(visitorParkingBooking.StartParkingTimeSlot.SlotTimeFrom.split(':')[0]), Number(visitorParkingBooking.StartParkingTimeSlot.SlotTimeFrom.split(':')[1]), 0),
        BookingEnd: new Date().setHours(Number(visitorParkingBooking.EndParkingTimeSlot.SlotTimeTo.split(':')[0]), Number(visitorParkingBooking.EndParkingTimeSlot.SlotTimeTo.split(':')[1]), 0),
        BookingDuration:  (Math.abs(new Date().setHours(Number(visitorParkingBooking.StartParkingTimeSlot.SlotTimeFrom.split(':')[0]), Number(visitorParkingBooking.StartParkingTimeSlot.SlotTimeFrom.split(':')[1]), 0) - new Date().setHours(Number(visitorParkingBooking.EndParkingTimeSlot.SlotTimeTo.split(':')[0]), Number(visitorParkingBooking.EndParkingTimeSlot.SlotTimeTo.split(':')[1]), 0)) / 36e5).toFixed(1).toString() + ' Hours',
        CarPlateNo: visitorParkingBooking.CarPlateNo,
        CheckIn:  visitorParkingBooking.CheckInTime,
        CheckOut:  visitorParkingBooking.CheckOutTime,
        Note: visitorParkingBooking.Note,
        BookingDate: visitorParkingBooking.BookingDate
      }
    })

    this.matTableDataSource = new MatTableDataSource(this.VisitorParkingBookingTableValues)

      this.matTableDataSource.filterPredicate = (element: any, filter: any) => {
      // filter data for search function
      if(this.filterType === 'searchBox'){
        console.log('searchBox')
        return element.VisitorName.toLocaleLowerCase().includes(filter) ||
          // element.RegisteredBy.toLocaleLowerCase().includes(filter) || // TODO
          // element.CheckIn.toLocaleLowerCase().includes(filter) || // TODO
          // element.CheckOut.toLocaleLowerCase().includes(filter) || // TODO
          element.ParkingType.toLocaleLowerCase().includes(filter) ||
          element.ParkingLotName.toLocaleLowerCase().includes(filter) ||
          element.CarPlateNo.toLocaleLowerCase().includes(filter) ||
          element.VisitorContact.toString().includes(filter)
        // element.UnitName.toString().includes(filter)
      }
      // filter data for columns filter function
      else if(this.filterType === 'columnsFilter') {
        console.log('columnsFilter')

          return element.ParkingLotName.toLocaleLowerCase().includes(filter.parkingLotNameFilter.trim().toLowerCase())
        // element.CheckIn.toLocaleLowerCase().includes(filter.Date.trim().toLowerCase()) && // TODO
        // element.CheckOut.toLocaleLowerCase().includes(filter.Date.trim().toLowerCase()) && // TODO
        // element.CheckIn.toLocaleLowerCase().includes(filter.TimeFrom.trim().toLowerCase()) && // TODO
        // element.CheckOut.toLocaleLowerCase().includes(filter.TimeFrom.trim().toLowerCase()) && // TODO
        // element.CheckIn.toLocaleLowerCase().includes(filter.TimeTo.trim().toLowerCase()) && // TODO
        // element.CheckOut.toLocaleLowerCase().includes(filter.TimeTo.trim().toLowerCase()) && // TODO
        }
      }

    // map model field to column name for sort function
    this.matTableDataSource.sortingDataAccessor = (item: any, property) => {
      switch(property) {
        case 'No': return item.No;
        case 'Visitor': return item.VisitorName;
        case 'ParkingType': return item.ParkingType;
        case 'ParkingLotName': return item.ParkingLotName;
        case 'UnitName': return item.UnitName;
        // case 'RegisteredBy': return item.RegisteredBy; // TODO
        // case 'CheckIn': return item.CheckIn; // TODO
        // case 'CheckOut': return item.CheckOut; // TODO

        default: return item[property];
      }
    };

    this.matTableDataSource.paginator = this.paginator;

  }

  getUnitName(unitId: number){
    if(this.ownerInfos){
      return this.ownerInfos.find(unit => unit.UnitInfo.Unit.UnitId === unitId) ? this.ownerInfos.find(unit => unit.UnitInfo.Unit.UnitId === unitId).UnitInfo.Unit.UnitName : ''
    }
  }

  // =============
  // Sort Function
  // =============
  sortData(){
    this.matTableDataSource.sort = this.sort;
  }

  // =========================
  // Search / Filter Functions
  // =========================
  get form() { return this.filterForm.controls; }

  setFilter(filter){
    this.filterType = filter
  }

  applySearchFilter() {
    this.setFilter('searchBox')
    const filterValue = this.form.searchBox.value;
    this.matTableDataSource.filter = filterValue.trim().toLowerCase();
  }

  applyColumnsFilter(){
    this.matTableDataSource.filter = this.filterForm.value;
  }

  view(){
    this.setFilter( 'columnsFilter')
    this.applyColumnsFilter()
  }

  // =====================
  // Update Note Functions
  // =====================
  openNoteDialog(visitorParkingBooking: VisitorParkingBooking){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      note: visitorParkingBooking.Note
    }

    let dialogRef: MatDialogRef<any>;
    dialogRef = this.dialog.open(NoteDialogComponent, dialogConfig);

    this.subscriptions.push(
      dialogRef.afterClosed().subscribe({
        next: result => {
          if(result){
            visitorParkingBooking.Note = result.updatedNote
            // call backend to update note
            this.updateVisitorParkingBooking(visitorParkingBooking)
          }
        },
        error: err => {console.log(err)}
      })
    )
  }

  updateVisitorParkingBooking(visitorParkingBooking){
    console.log(visitorParkingBooking)
    const visitorParkingBookingUpdate = new VisitorParkingBookingUpdate()
    visitorParkingBookingUpdate.BookingId = visitorParkingBooking.BookingId
    visitorParkingBookingUpdate.UnitId = visitorParkingBooking.UnitId
    visitorParkingBookingUpdate.SiteId = visitorParkingBooking.SiteId
    visitorParkingBookingUpdate.VisitorName = visitorParkingBooking.VisitorName
    visitorParkingBookingUpdate.VisitorContact = visitorParkingBooking.VisitorContact
    visitorParkingBookingUpdate.CarPlateNo = visitorParkingBooking.CarPlateNo
    visitorParkingBookingUpdate.CarParkId = visitorParkingBooking.CarParkId
    visitorParkingBookingUpdate.Note = visitorParkingBooking.Note
    visitorParkingBookingUpdate.StartParkingTimeSlot = { TimeSlotId: visitorParkingBooking.StartParkingTimeSlot.TimeSlotId }
    visitorParkingBookingUpdate.EndParkingTimeSlot = { TimeSlotId: visitorParkingBooking.EndParkingTimeSlot.TimeSlotId }

    this.subscriptions.push(
      this.visitorParkingService.updateVisitorParkingBooking(visitorParkingBookingUpdate).subscribe({
        next: () => {},
        error: err => console.log(err)
      })
    )
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }

}
