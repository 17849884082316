import { Component, OnInit, AfterViewInit, ViewChild, Inject, ViewChildren, ElementRef, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators, FormControlName } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { RentalParking, RentalParkingList } from 'src/app/core/models/rentalParking.models';
import { RentalParkingService } from 'src/app/core/services/rentalParking.service';
import { UnitService } from 'src/app/core/services/unit.service';
import { OccupantsService } from 'src/app/core/services/occupant.service';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, ReplaySubject, Subscription, Subject } from 'rxjs';
import { CommonService } from 'src/app/core/services/common.service';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { FileUpload } from 'src/app/core/models/fileUpload.models';
import { InformationDialogComponent } from 'src/app/shared/component/information-dialog/information-dialog.component';
import { MatSelect } from '@angular/material/select';
import { take, takeUntil,map, startWith } from 'rxjs/operators';
import {User} from '../../../core/models/user.models';
import {UserService} from '../../../core/services/user.service';


  export interface Unit {
    id: number;
    name: string;
  }

@Component({
    selector: 'rentalparking-form',
    templateUrl: 'rentalparking-form.html',
    styleUrls: ['rentalparking-form.css'],
})
export class RentalParkingFormComponent {
    @ViewChildren(FormControlName, { read: ElementRef }) formInputElements!: ElementRef[];
    userEditRentalParkingAllowedAccess: boolean = false;;

    mainListLink = '/apps/rentalparking';
    mainEditLink = '/apps/rentalparking-edit';
    RentalParkingForm!: FormGroup;
    submitted = false;
    errorMessage = '';
    infoMessage = '';
    action: string;
    pageTitle = '';
    currentDate = new Date();
    RentalParking: RentalParkingList = new RentalParkingList();
    RentalParkingUpdate: RentalParkingList = new RentalParkingList();
    //RentalParkingUpdate:any = {};
    private sub!: Subscription;
    private subscriptions: Subscription[] = [];
    displayMessage: { [key: string]: string } = {};
    id: number;
    RentalParkingLoad: any;
    siteIdLogin: number;
    user: User;
    units: any;
    occupants: any;
    selectedOccupant: any;
    RentalParkings: any;
    selectedRentalParking: any;
    VehicleList: any;
    RentalTermSelected: string;
    DepositPayment: number = 0;
    SingleTermPayment: number = 0;
    TotalTermPayment: number = 0;
    TotalFee: number = 0;
    RentalDurationText: string = '0 day';

    unitControl = new FormControl();
    unitOptions: Unit[] = [];
    filteredUnitOptions:  Observable<Unit[]>;
    imgObj:string 
    rentalParkingLists:RentalParkingList[];

    filteredRentalParkings: any;
    //private fileUploadObject: FileUpload = new FileUpload();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: FormBuilder,
        private RentalParkingService: RentalParkingService,
        private UnitService: UnitService,
        private OccupantsService: OccupantsService,
        private userService: UserService,
        private commonService: CommonService,
        public dialog: MatDialog,
        private authenticationService: AuthenticationService,
        private datePipe: DatePipe) {}

    ngOnInit(): void {
        this.siteIdLogin = Number(localStorage.getItem('siteId'));
        // set user access
        this.user = this.authenticationService.currentUser();

        // Read the product Id from the route parameter
        this.sub = this.route.paramMap.subscribe(
            params => {
                this.id = Number(this.route.snapshot.paramMap.get('id'));
                console.log("The ID: " + this.id);
                //this.getOccupant(id);
            }
        );

        this.UnitService.getUnitsBySiteId(this.siteIdLogin).subscribe({
            next: units => {
                this.units = units;
                //console.log(this.units);

                this.unitOptions = this.units.map((item: any) => ({
                    id: item.UnitInfo.Unit.UnitId,
                    name: item.UnitInfo.Unit.UnitName,
                }));
                const names = this.unitOptions.map(({ name }) => name);
                this.unitOptions = this.unitOptions.filter(({ name }, index) => !names.includes(name, index + 1));
                //console.log('unitOptions: ' + JSON.stringify(this.unitOptions));
                console.log(this.unitOptions)
                this.filteredUnitOptions = this.unitControl.valueChanges.pipe(
                    startWith(''),
                    map(value => (typeof value === 'string' ? value : value.name)),
                    map(name => (name ? this._filterUnit(name) : this.unitOptions.slice())),
                  );

                  if (this.id > 0) {
                    this.displayRentalParking();
                    this.action = 'Update';
                    } else {
                        this.pageTitle = 'Rent a Parking';
                        this.action = 'Add';
                    }
            },
            error: err => this.errorMessage = err
        });

        this.RentalParkingService.getRentalParkingBySite(this.siteIdLogin).subscribe({
            next: RentalParkings => {
                this.RentalParkings = RentalParkings;
                this.filteredRentalParkings = RentalParkings;
            },
            error: err => this.errorMessage = err
        });



        this.user = this.authenticationService.currentUser();
        //console.log('Login User: ' + JSON.stringify(this.user));

        this.RentalParkingForm = this.fb.group({
            unitId: ['', [Validators.required]],
            //unitId2: ['', [Validators.required]],
            OccupantId: ['', [Validators.required]],
            OccupantContact: ['', [Validators.required]],
            RentalParkingId: ['', [Validators.required]],
            VehicleId: ['', [Validators.required]],
            RentalTerm: ['', [Validators.required]],
            RentalDurationDay: '',
            RentalDurationMonth: '',
            RentalStartDate: ['', [Validators.required]],
            RentalEndDate: ''
        });

        this.getRentalParkingListBySite();
        
        this.userEditRentalParkingAllowedAccess = this.authenticationService.isAllowAccess('Edit Car Park');
        if (!this.userEditRentalParkingAllowedAccess) {
            for (const field of Object.keys(this.RentalParkingForm.controls)) {
            this.RentalParkingForm.get(field).disable();
            }
        }
    }

    getRentalParkingListBySite(){
       this.RentalParkingService.getRentalParkingListBySite(this.siteIdLogin).subscribe({
            next: ParkingList => {
                this.rentalParkingLists = ParkingList;
                console.log(this.rentalParkingLists)
            },
            error: err => this.errorMessage = err
        });

    }

      displayFnUnit(unit: Unit): string {
        return unit && unit.name ? unit.name : '';
      }

      private _filterUnit(name: string): Unit[] {
        const filterValue = name.toLowerCase();

        return this.unitOptions.filter(option => option.name.toLowerCase().includes(filterValue));
      }

    displayRentalParking(): void {
        if (this.RentalParkingForm) {
            this.RentalParkingForm.reset();
        }

        //console.log('Load data : ' + JSON.stringify(this.RentalParkingLoad));

        this.RentalParkingService.getRentalParkingListByID(this.id).subscribe({
            next: data => {
                this.RentalParkingLoad = data;
                //console.log('RentalTerm : ' + this.RentalParkingLoad.RentalTerm + ' RentalDuration : ' + this.RentalParkingLoad.RentalDuration);

                this.RentalParkingForm.patchValue({
                    unitId: this.RentalParkingLoad.OccupantData.Unit.UnitId,
                    OccupantId: this.RentalParkingLoad.OccupantData.OccupantId,
                    OccupantContact: this.RentalParkingLoad.OccupantData.OccupantContact,
                    RentalParkingId: this.RentalParkingLoad.RentalParkingData.RentalParkingId,
                    VehicleId: this.RentalParkingLoad.VehicleData.VehicleId,
                    RentalTerm: this.RentalParkingLoad.RentalTerm,
                    RentalDurationDay: this.RentalParkingLoad.RentalTerm == "Daily" ? this.RentalParkingLoad.RentalDuration : 0,
                    RentalDurationMonth: this.RentalParkingLoad.RentalTerm == "Monthly" ? this.RentalParkingLoad.RentalDuration.toString() : 0,
                    RentalStartDate: this.RentalParkingLoad.RentalStartDate,
                    RentalEndDate: this.RentalParkingLoad.RentalEndDate
                });

                let selectedUnit = this.unitOptions.filter(i => i.id == this.RentalParkingLoad.OccupantData.Unit.UnitId)[0];
                //console.log('selectedUnit : ' + JSON.stringify(selectedUnit));
                this.unitControl.setValue(selectedUnit);

                this.selectUnitNo();
                this.selectRentalTerm();

                //this.selectOccupant();
                // if (this.RentalParkingLoad.LocationMap) {
                //     this.fileUploadObject = {
                //         fileUploadedName: this.RentalParkingLoad.MapFileName,
                //         fileUploadedByte: this.RentalParkingLoad.MapHeader + ',' + this.RentalParkingLoad.LocationMap,
                //         fileUploadedSize: this.commonService.countFileUploadedSizeText(
                //             this.RentalParkingLoad.MapFileName,
                //             this.RentalParkingLoad.MapHeader + ',' + this.RentalParkingLoad.LocationMap)
                //     }
                // }

                this.pageTitle = 'Edit Rental Parking';

            },
            error: err => this.errorMessage = err
        });

    }

    selectUnitNo() {
        //console.log('Form data unitControl: ' + JSON.stringify(this.unitControl.value));
        const UnitId = this.unitControl.value.id;
        //const UnitId = this.f.unitId.value;
        //console.log('UnitId :' + UnitId);
        if (UnitId) {
            this.getOccupantsList(UnitId);
            this.VehicleList = JSON.parse(JSON.stringify(this.units.find(x=>x.UnitInfo.Unit.UnitId == UnitId).UnitInfo.VehicleList));
        } else {

            this.RentalParkingForm.patchValue({
                OccupantContact: null,
                VehicleId: null
            });
            this.VehicleList.length = 0;
            this.occupants.length = 0;
            this.selectedOccupant.length = 0;
        }
    }

    getOccupantsList(UnitId) {
        this.OccupantsService.getOccupantsByUnit(UnitId).subscribe({
            next: data => {
                data = data.filter(i => i.IsOwner == true);
                this.occupants = data;
                this.selectOccupant();
                this.selectRentalParkingId();
            },
            error: err => this.errorMessage = err
        });
    }

    selectOccupant() {
        const OccupantId = this.f.OccupantId.value;
        //console.log('OccupantId :' + OccupantId);
        if (OccupantId) {
            this.selectedOccupant = this.occupants.filter(i => i.OccupantId == OccupantId)[0];
            if (this.selectedOccupant) {
                //console.log('selectedOccupant :' + JSON.stringify(this.selectedOccupant));
                this.RentalParkingForm.patchValue({
                    OccupantContact: this.selectedOccupant.OccupantContact
                });
                //console.log('VehicleList :' + JSON.stringify(this.VehicleList));
            }
        } else {
            //  if (this.selectedOccupant) {
            //      this.selectedOccupant.length = 0;
            //  }
            this.selectedOccupant = null;
            this.RentalParkingForm.patchValue({
                OccupantContact: ''
            });
        }
    }

    selectRentalTerm() {
        this.RentalTermSelected = this.f.RentalTerm.value;
        //console.log('RentalTermSelected :' + this.RentalTermSelected);
        this.updateRentalEndDate();
    }

    selectRentalParkingId() {
        const RentalParkingId = this.f.RentalParkingId.value;
        console.log('RentalParkingId :' + RentalParkingId);
        if (RentalParkingId > 0) {
            this.selectedRentalParking = this.RentalParkings.filter(i => i.RentalParkingId == RentalParkingId)[0];
            this.getImage(this.selectedRentalParking.LocationMap);
        } else {
            this.selectedRentalParking = null;

        }
        this.updateRentalEndDate();
    }

    async updateRentalEndDate() {
        let RentalStartDate = this.f.RentalStartDate.value;
        let RentalTermSelected = this.f.RentalTerm.value;
        let RentalDuration = this.f.RentalDurationDay.value;
        if (RentalTermSelected == 'Monthly') {
            RentalDuration = this.f.RentalDurationMonth.value;
        }

        if (RentalTermSelected && RentalDuration > 0) {
            if (RentalTermSelected == 'Daily') {
                if (RentalDuration > 1) {
                    this.RentalDurationText = RentalDuration + ' days';
                } else {
                    this.RentalDurationText = RentalDuration + ' day';
                }
            } else if (RentalTermSelected == 'Monthly') {
                if (RentalDuration > 1) {
                    this.RentalDurationText = RentalDuration + ' months';
                } else {
                    this.RentalDurationText = RentalDuration + ' month';
                }
            }

            if (RentalStartDate) {
                let RentalEndDate = new Date();
                let RentalEndDateText = '';
                //console.log('RentalStartDate :' + new Date(RentalStartDate));
                if (RentalTermSelected == 'Daily') {
                    RentalEndDate = this.addDays(RentalStartDate, RentalDuration-1);
                } else if (RentalTermSelected == 'Monthly') {
                    RentalEndDate = this.addMonths(new Date(RentalStartDate), RentalDuration);
                }
                RentalEndDateText = this.datePipe.transform(RentalEndDate, 'dd/MM/yyyy').toString();
                //console.log('RentalStartDate :' + RentalStartDate + ' RentalEndDate :' + RentalEndDate + ' RentalDuration :' + RentalDuration + ' RentalEndDateText :' + RentalEndDateText);
                this.RentalParkingForm.patchValue({
                    RentalEndDate: RentalEndDate
                });
            }

            if (this.selectedRentalParking) {
                this.DepositPayment = this.selectedRentalParking.DepositPayment;
                let MonthlyRentalPayment = this.selectedRentalParking.MonthlyRentalPayment;
                let DailyRentalPayment = this.selectedRentalParking.DailyRentalPayment;
                this.SingleTermPayment = DailyRentalPayment;
                if (RentalTermSelected == 'Monthly') {
                    this.SingleTermPayment = MonthlyRentalPayment;
                }
                this.TotalTermPayment = this.SingleTermPayment * RentalDuration;
                this.TotalFee = this.DepositPayment + this.TotalTermPayment;
            } else {
                this.TotalTermPayment = 0;
                this.TotalFee = 0;
            }

        } else {
            this.RentalDurationText = '0 day';
        }

       

        let endDate = this.commonService.isoDateWithoutTimeZone(this.f.RentalEndDate.value)
        
        let startDate = this.commonService.isoDateWithoutTimeZone(RentalStartDate)

        let overlapslot:RentalParkingList[] = new Array<RentalParkingList>();
        for (var rent of this.rentalParkingLists){
            if(await this.dateRangeOverlaps(this.commonService.isoDateWithoutTimeZone(rent.RentalStartDate),
            this.commonService.isoDateWithoutTimeZone(rent.RentalEndDate),
            startDate, endDate))
            {
                overlapslot.push(rent)
            }
        }
        console.log(overlapslot)
        this.filteredRentalParkings = this.RentalParkings.filter(x=>{
            if(overlapslot.some(y=>y.RentalParkingData.RentalParkingId == x.RentalParkingId))
                return false;
            return true;
        })
    }

    async dateRangeOverlaps(a_start, a_end, b_start, b_end) {

    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end   && b_end   <= a_end) return true; // b ends in a
    if (b_start <=  a_start && a_end   <=  b_end) return true; // a in b
    return false;
}

    addDays(date, days) {
        let dateTemp = new Date(date);
        let dateReturn = new Date(dateTemp.getTime() + (days * 24 * 60 * 60 * 1000));
        return dateReturn;
    }

    addMonths(date, months) {
        var d = date.getDate();
        date.setMonth(date.getMonth() + +months);
        if (date.getDate() != d) {
            date.setDate(0);
        }
        return date;
    }

    get f() { return this.RentalParkingForm.controls; }

    unitControlClear(){
        if(this.unitControl.value){
            this.RentalParkingForm.patchValue({
                unitId: "",
            });
            this.unitControl.setValue("");
            this.selectUnitNo();
        }
    }

    save() {

        //console.log('Form data myControl: ' + JSON.stringify(this.unitControl.value));
        if(this.unitControl.value){
            this.RentalParkingForm.patchValue({
                unitId: this.unitControl.value.id,
            });
            this.unitControl.setValue(this.unitControl.value);
        }

        this.submitted = true;

        // stop here if form is invalid
        if (this.RentalParkingForm.invalid) {
            this.errorMessage = 'Please correct the validation errors.';
            return;
        } else {

            // if (this.fileUploadObject.fileNewByte) {
            //     var AttachmentFileHeader = this.fileUploadObject.fileNewByte.split(',')[0];
            //     var AttachmentFileBytes = this.fileUploadObject.fileNewByte.split(',')[1];
            //     //console.log("fileNewName " + this.fileUploadObject.fileNewName);
            //     this.RentalParkingUpdate.LocationMap = AttachmentFileBytes;
            //     this.RentalParkingUpdate.MapHeader = AttachmentFileHeader;
            //     this.RentalParkingUpdate.MapFileName = this.fileUploadObject.fileNewName;
            // }
            // else {
            //     if (this.fileUploadObject.fileUploadedName != null) {
            //         this.RentalParkingUpdate.LocationMap = this.RentalParkingLoad.LocationMap;
            //         this.RentalParkingUpdate.MapHeader = this.RentalParkingLoad.MapHeader;
            //         this.RentalParkingUpdate.MapFileName = this.RentalParkingLoad.MapFileName;
            //     }
            // }

            let RentalTerm = this.f.RentalTerm.value;
            let RentalDuration = this.f.RentalDurationDay.value;
            if (RentalTerm == 'Monthly') {
                RentalDuration = this.f.RentalDurationMonth.value;
            }
            //console.log('Form data RentalEndDate: ' + JSON.stringify(this.f.RentalEndDate.value));
            this.RentalParkingUpdate.VehicleId = this.f.VehicleId.value;
            this.RentalParkingUpdate.OccupantId = this.f.OccupantId.value;
            this.RentalParkingUpdate.RentalParkingId = this.f.RentalParkingId.value;
            this.RentalParkingUpdate.RentalTerm = RentalTerm;
            this.RentalParkingUpdate.RentalDuration = RentalDuration;
            this.RentalParkingUpdate.RentalStartDate = this.commonService.isoDateWithoutTimeZone(this.f.RentalStartDate.value);

            // let RentalEndDateSplit = this.f.RentalEndDate.value.split('/');
            // let RentalEndDateRebuild = RentalEndDateSplit[2] + '-' + RentalEndDateSplit[1] + '-' + RentalEndDateSplit[0];
            this.RentalParkingUpdate.RentalEndDate = this.commonService.isoDateWithoutTimeZone(this.f.RentalEndDate.value);;

            this.RentalParkingUpdate.UpdatedBy = this.user.UserIdentityName;
            this.RentalParkingUpdate.UpdatedOn = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm:ss');

            let RentalParkListId = 0;
            if (this.action == "Update") {
                RentalParkListId = this.RentalParkingLoad.RentalParkListId;
            }

            //console.log('Save data: ' + JSON.stringify(this.RentalParkingUpdate));

            if (this.action == "Add") {
                this.RentalParkingService.addRentalParkingList(this.RentalParkingUpdate)
                    .subscribe({
                        next: x => {
                            //console.log(x);
                            return this.onSaveComplete();
                        },
                        error: err => this.errorMessage = err
                    });
            } else if (this.action == "Update") {

                this.RentalParkingUpdate.RentalParkListId = RentalParkListId;
                //console.log(this.RentalParkingUpdate)
                this.RentalParkingService.updateRentalParkingList(this.RentalParkingUpdate)
                    .subscribe({
                        next: x => {
                            //console.log(x);
                            return this.onSaveComplete();
                        },
                        error: err => this.errorMessage = err
                    });
            }

            this.infoMessage = 'Success save.';
        }

    }

    openImageDialog() {
        if (this.selectedRentalParking) {
            const dialogConfig = new MatDialogConfig();
            dialogConfig.disableClose = true;
            dialogConfig.autoFocus = true;
            dialogConfig.width = '700px';
            dialogConfig.height = 'auto';
            dialogConfig.data = {
                title: 'Parking Lot Location',
                image: this.imgObj,
                imageWidth: 500,
                imageHeight: 450,
            }

            let dialogRef: MatDialogRef<any>;
            dialogRef = this.dialog.open(InformationDialogComponent, dialogConfig);
        }
    }
    onSaveComplete(): void {
        // Reset the form to clear the flags
        //this.RentalParkingForm.reset();
        if (this.action == "Update") {
            //location.reload();
            this.displayRentalParking();
            //this.router.navigate([this.mainEditLink, this.id]);
        } else {
            this.router.navigate([this.mainListLink]);
        }

    }

    getImage(fileName):void{
        if(fileName == null || fileName == undefined || fileName=='')
        {
            this.imgObj= '';
            return
        }

        this.commonService.readFileFromURL(fileName)
                      .subscribe(async res => {

                        const reader = new FileReader();
                          reader.readAsDataURL(res); 
                          reader.onloadend = () => {
                          const base64data = reader.result;   

                        //this.fileObj.fileBase64=base64data.toString();
                        this.imgObj= base64data.toString();
                          }
                      });

    }

    //updateFileUploadObject(fileUploadObject) {
    //console.log(fileUploadObject);
    //}
}
