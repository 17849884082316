import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LoaderComponent } from './component/loader/loader.component';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatButtonModule} from '@angular/material/button';
import { MatMenuModule} from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';

import { UploadFileComponent } from './component/upload-file/upload-file.component';
import { DeleteDialogComponent } from './component/delete-dialog/delete-dialog.component';
import { InformationDialogComponent } from './component/information-dialog/information-dialog.component';
import { UploadFileMultipleComponent } from './component/upload-file/upload-file-multiple.component';
import { MultipleDatesPickerComponent } from './component/multiple-dates-picker/multiple-dates-picker.component';
import { AttachmentComponent } from './component/attachment/attachment.component';
import { AttachmentMultipleComponent } from './component/attachment-multiple/attachment-multiple.component';
import { TableComponent } from './component/table/table.component';

@NgModule({
  declarations: [
    UploadFileComponent,
    DeleteDialogComponent,
    UploadFileMultipleComponent,
    InformationDialogComponent,
    MultipleDatesPickerComponent,
    LoaderComponent,
    AttachmentComponent,
    AttachmentMultipleComponent,
    TableComponent
  ],
  imports: [
      CommonModule,
      MatChipsModule,
      MatDatepickerModule,
      MatIconModule,
      MatFormFieldModule,
      MatInputModule,
      MatIconModule,
      MatMenuModule,
      MatTableModule,
      MatSortModule,
      MatPaginatorModule,
      MatCheckboxModule,
  ],
  exports: [
    UploadFileComponent,
    DeleteDialogComponent,
    InformationDialogComponent,
    UploadFileMultipleComponent,
    MultipleDatesPickerComponent,
    LoaderComponent,
    MatExpansionModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    AttachmentComponent,
    AttachmentMultipleComponent,
    MatRadioModule,
    TableComponent
  ],
  entryComponents: [
    DeleteDialogComponent,
    InformationDialogComponent
  ]
})
export class SharedModule { }
