import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PurchaseOrder } from 'src/app/core/models/purchases/purchaseOrder.model';
import { User } from 'src/app/core/models/user.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { PurchaseOrderService } from 'src/app/core/services/purchases/purchase-order.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.html',
  styleUrls: ['./payment.css']
})
export class PaymentComponent implements OnInit {

  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  siteId = Number(localStorage.getItem("siteId"));
  purchaseOrderDataSource: MatTableDataSource<PurchaseOrder> = new MatTableDataSource<PurchaseOrder>();
  displayedColumns: string[] = ['po', 'date', 'vendor', 'amount', 'status', 'action'];
  currentUser: User;

  constructor(
    private authenticationService: AuthenticationService,
    private purchaseOrderService: PurchaseOrderService,
  ) { }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUser();
    this.getPurchaseOrderBySite(this.siteId);
  }

  ngAfterViewInit() {
    this.purchaseOrderDataSource.paginator = this.paginator;
    this.purchaseOrderDataSource.sort = this.sort;
  }

  applyFilter(filterValue: string){
    this.purchaseOrderDataSource.filter = filterValue.trim();
  }

  onUpdatePoStatus(purchaseOrderId, status) {
    this.updatePoStatus(purchaseOrderId, status);
  }

  getPurchaseOrderBySite(sideId: number) {
    this.purchaseOrderService.getPurchaseOrders(sideId).subscribe({
      next: data => {
        this.purchaseOrderDataSource = new MatTableDataSource(data);
        // this.purchaseOrderDataSource.sort = this.sort;
        // this.purchaseOrderDataSource.paginator = this.paginator;
      },
      error: (err) => console.log(err),
    });
  }

  updatePoStatus(purchaseOderId, status) {
    let updatePurchaseOrderStatusDTO = {
      PurchaseOrderId: purchaseOderId,
      Status: status,
      UpdatedBy: this.currentUser.UserIdentityName,
    } 

    this.purchaseOrderService.updatePurchaseOrderStatus(updatePurchaseOrderStatusDTO).subscribe({
      next: (x) => {
        this.getPurchaseOrderBySite(this.siteId);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }

}
