import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visitor-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.css']
})
export class VisitorAddComponent implements OnInit {
  private parkingBookingListLink = '/apps/visitor';

  constructor() { }

  ngOnInit(): void {
  }

}
