import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.css']
})
export class NoteDialogComponent implements OnInit {
  note: string;

  constructor(private dialogRef: MatDialogRef<NoteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.note = data.note
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close()
  }

  save(){
    this.dialogRef.close({ updatedNote: this.note })
  }
}
