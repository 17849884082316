import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {ProfilePicture, Role, SpecialView, User} from '../models/user.models';
import {environment} from '../../../environments/environment';
import {Observable, throwError, EMPTY} from 'rxjs';
import {Owner} from '../models/owner.models';
import {catchError, map, tap,retry} from 'rxjs/operators';
import {Site} from '../models/site.models';
import { CommonService } from './common.service';

@Injectable({providedIn: 'root'})
export class UserService {
  private getAllUsersBySiteURL: string = environment.apiBaseUrl + 'authUser/getalluser/';
  private getalluserbySubURL: string = environment.apiBaseUrl + 'authUser/getalluserbysub/';
  private getUserDetailsURL: string = environment.apiBaseUrl + 'authUser/getuserdetails/';
  private addUserURL: string = environment.apiBaseUrl + 'authUser/adduser/';
  private updateUserURL: string = environment.apiBaseUrl + 'authUser/updateuser/';
  private updateSpecialViewURL: string = environment.apiBaseUrl + 'authUser/updatespecialview/';
  private updateUserProfilePicURL: string = environment.apiBaseUrl + 'authUser/updateprofile/';
  private getBusinessManagerURL: string = environment.apiBaseUrl + 'authUser/getBusinessManager/';
  private getAllRolesURL: string = environment.apiBaseUrl + 'authUser/getallrole/';
  private getRoleDetailsURL: string = environment.apiBaseUrl + 'authUser/getRoleDetails/';
  private addRoleURL: string = environment.apiBaseUrl + 'authUser/updaterole/';
  private updateRoleURL: string = environment.apiBaseUrl + 'authUser/updaterole/';
  private deleteRoleURL: string = environment.apiBaseUrl + 'authUser/deleterole/';

  constructor(
    private http: HttpClient,
    private commonService: CommonService) { }

  getUsersBySiteId(siteId: number): Observable<User[]> {
    return this.http.get<User[]>(this.getAllUsersBySiteURL + siteId)
      .pipe(
        retry(3),
        catchError((this.commonService.handleError))
      );
  }
  
  getAllUserBySub(adminUserId: string): Observable<User[]> {
    return this.http.get<User[]>(this.getalluserbySubURL + adminUserId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getUserDetails(userId: number): Observable<User> {
    return this.http.get<User>(this.getUserDetailsURL + userId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  addUser(user: User) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.addUserURL}`;
    return this.http.post<any>(url, user, {headers})
      .pipe(
        catchError(this.commonService.handleError)
      );
  }


  updateUser(user: User) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.updateUserURL}`;
    return this.http.post<any>(url, user, {headers})
      .pipe(
        tap(() => console.log('updated user: ' + user.UserId)),
        catchError(this.commonService.handleError)
      );
  }

  updateSpecialView(specialViews: SpecialView[]) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.updateSpecialViewURL}`;
    return this.http.post<any>(url, specialViews, {headers})
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  updateUserProfilePic(userProfilePic: ProfilePicture) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.updateUserProfilePicURL}`;
    return this.http.post<any>(url, userProfilePic, {headers})
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  isUserAllowedToSeeView(user: User, viewId: number): boolean {
    console.log(user.Role)

    // check in role ViewList
    for (const view of user.Role.ViewList) {
      if (view.ViewId === viewId) {
        return true;
      }
    }

    // check for user SpecialViewList
    if (user.UserSpecialView !== null) {
      if (user.UserSpecialView.length !== 0) {
        for (const specialView of user.UserSpecialView) {
          if (specialView.View.ViewId === viewId) {
            return true;
          }
        }
      }
    }

    return false;
  }

  // Roles

  getAllRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.getAllRolesURL)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getRoleDetails(roleId: number) {
    return this.http.get<Role>(this.getRoleDetailsURL + roleId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  addRole(role: Role) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.addRoleURL}`;
    return this.http.post<any>(url, role, {headers})
      .pipe(
        tap(data => {
          return data
        }),
        catchError(this.commonService.handleError)
      );
  }

  updateRole(role: Role) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.updateRoleURL}`;
    return this.http.post<any>(url, role, {headers})
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  deleteRole(roleId: number) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.deleteRoleURL}`;
    return this.http.delete<any>(url + roleId, {headers})
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getBusinessManager(siteId: number): Observable<User>{
    return this.http.get<User>(this.getBusinessManagerURL + siteId)
      .pipe(
        tap((data: User) => console.log(data)),
        catchError(this.commonService.handleError)
      );
  }
}
