import { APP_INITIALIZER, NgModule } from '@angular/core';
import { take } from 'rxjs/operators';
import { ViewlistService } from './core/services/viewlist.service';

export function initializeApp(view: ViewlistService){
  return () => view.fetchEndpoints().then((data)=>{
    view.api$.pipe(take(1));
  })
 }
@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: initializeApp,
      deps: [ViewlistService],
    },
  ],
})
export class InitializerModule {}