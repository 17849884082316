import { Component, OnInit } from '@angular/core';
import {LoadingService} from './core/services/loading.service';
import {AuthenticationService} from './core/services/auth.service';
import {ViewlistService} from './core/services/viewlist.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  
  data: any = {};
  constructor(
    public loading: LoadingService,
    public authenticationService: AuthenticationService,
    private viewlistService: ViewlistService) {

   }
     ngOnInit(): void {    
      this.viewlistService.api$.subscribe((data) => {
        this.authenticationService.VIEW_LIST_CONST = data;
      });
  }

}
