import {Component, OnInit, Output, EventEmitter, OnDestroy, HostListener} from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../../core/services/auth.service';

import {SiteService} from '../../../../core/services/site.service';
import {Subscription,Subject} from 'rxjs';
import {Site} from '../../../../core/models/site.models';


import {NotificationService} from '../../../../core/services/notification.service';
import {Notification,NotificationByDate} from '../../../../core/models/notification.model';

@Component({
  selector: 'app-notice-detail',
  templateUrl: './notice-detail.component.html',
  styleUrls: ['./notice-detail.component.css']
})
export class NoticeDetailComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];

constructor(private router: Router,
          private authService: AuthenticationService,
          private siteService: SiteService,
          private notificationService: NotificationService) {}

  userId: number;
  siteId: number;
  notificationItems: Notification[];
  notificationByDate:NotificationByDate[] = new Array<NotificationByDate>();
  ngOnInit(): void {
    
    this.siteId = Number(localStorage.getItem('siteId'));
    this.userId = this.authService.currentUser().UserId;
    this.getNotificationByUserId();
  }

    getNotificationByUserId(){
    this.subscriptions.push(
      this.notificationService.getAllNotification(this.userId,this.siteId).subscribe({
        next: data => {
          this.notificationItems = data
          this.notificationByDate = data.reduce((groups, notification) => {
            
            this.assignRouterLink(notification)
            const date = notification.NotificationLogInfo.CreatedOn.split('T')[0];
            let existingGrp = groups.filter(x=>x.NotificationDate == date)

            if(existingGrp== null || existingGrp==undefined || existingGrp.length ==0){
              let newGrp:NotificationByDate  = new NotificationByDate();
              newGrp.NotificationDate = date
              newGrp.Notifications.push(notification);
              groups.push(newGrp)
            }
            else{
              existingGrp[0].Notifications.push(notification)
            }

            return groups;
          }, new Array<NotificationByDate>());
        },
        error: err => console.error(err)
      })
    )
  }

  assignRouterLink(notification:Notification){
    if(notification.NotificationLogInfo.Module == 'Task'){
      notification.NotificationLogInfo.LinkToPage = '/apps/tasksheet-open'
    }else if(notification.NotificationLogInfo.Module == 'Checklist'){
      notification.NotificationLogInfo.LinkToPage = '/apps/checklist'
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe())
  }
}
