import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import { catchError , tap , map } from 'rxjs/operators';

import { CookieService } from '../services/cookie.service';
import { UserService } from '../services/user.service';
import { CommonService } from '../services/common.service';
import { User,View } from '../models/user.models';
import { environment } from 'src/environments/environment';
import { ErrorTracker } from '../models/errorTracker';
import { getLocaleTimeFormat } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public isLoading = false;
    user: User;
    changePasswordURL = environment.apiBaseUrl + 'authUser/updatepassword';
    getAllViewURL = environment.apiBaseUrl + 'authUser/getallview';
    public VIEW_LIST_CONST:View[];
    constructor(private http: HttpClient, 
        private cookieService: CookieService,
        private commonService: CommonService,
        private userService: UserService) {

    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            this.user = JSON.parse(this.cookieService.getCookie('currentUser'));
        }
        return this.user;
    }

    public getviewList(){
        if(this.VIEW_LIST_CONST == null || this.VIEW_LIST_CONST.length == 0 ){
        this.getAllView()
        .subscribe({
            next:data=>{
                this.VIEW_LIST_CONST = data
            },
            error:err=>this.commonService.ErrorNotification("Fail to get view list " + err)
        });
        }
    }

    public isAllowAccess(viewName:string):boolean{
        try{
            let thisView = this.VIEW_LIST_CONST.find(x=>x.ViewName == viewName)
            for (const viewId of this.user.Role.ViewIdList) {
                if ( thisView.ViewId == viewId) {
                    return true;
                }
            }
            // check for user SpecialViewList
            if (this.user.UserSpecialView !== null) {
                if (this.user.UserSpecialView.length !== 0) {
                    for (const specialView of this.user.UserSpecialView) {
                    if (specialView.View.ViewName === viewName) {
                        return true;
                    }
                    }
                }
            }
            return false;
        }catch (err){
            this.commonService.ErrorNotification('Error occur at check allow Access for view '+ viewName + ' error msg: ' + err)
        }

    }

    getAllView(): Observable<View[]> {
        return this.http.get<View[]>(this.getAllViewURL)
        .pipe(
            catchError(this.commonService.handleError)
        );
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        this.isLoading=true;
        const body = {email: email, passcode: password};
        console.log(environment.apiBaseUrl+`authUser/validateUser`)

        return this.http.post<User>(environment.apiBaseUrl+`authUser/validateUser`, body)
            .pipe(map(result => {
                // login successful if there's a jwt token in the response
                if (result && result.Token) {
                    // store token in local storage
                    localStorage.setItem('auth_token',result.Token);
                    result.Token='';
                    // TODO: call get user detail
                    // store user details and jwt in cookie
                    let user = JSON.parse(JSON.stringify(result));
                    if(this.user?.UserId != user?.UserId){
                        localStorage.removeItem('siteId');
                    }
                    this.cookieService.setCookie('currentUser', JSON.stringify(result), 1);
                }
                return result;
            }),tap(data=>console.log(JSON.stringify(data))),catchError(this.handleError));
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.cookieService.deleteCookie('currentUser');
        localStorage.removeItem('auth_token');
        localStorage.removeItem('siteId');
        this.user = null;
    }

    updatePassword(userId, hashedOldPassword, hashedNewPassword) {
      const body = {userId, oldPassword: hashedOldPassword, passcode: hashedNewPassword};
      return this.http.post<User>(this.changePasswordURL, body)
        .pipe(tap(data=>console.log(JSON.stringify(data))),catchError(this.handleError));
    }


    registration(newUser:User)
    {
        this.isLoading = true;
        return this.http.post<User>(environment.apiBaseUrl + 'authUser/registeration',{params:newUser})
        .pipe
        (tap(data=>console.log(JSON.stringify(data))),catchError(this.handleError));
    }

    getMessage():Observable<string|ErrorTracker>
    {
        this.isLoading=true;
        return this.http.get<string>(environment.apiBaseUrl + 'authUser/testLogin')
        .pipe(tap(data=>console.log(JSON.stringify(data))),catchError(this.handleError));
    }

    private handleError(err:HttpErrorResponse):Observable<ErrorTracker>
    {
        let dataError= new ErrorTracker();
        dataError.errorNumber = 100;
        dataError.message = err.statusText;
        dataError.friendlyMessage = 'An error occurred.';

        return throwError(dataError);
    }
}

