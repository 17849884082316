import { Injectable } from '@angular/core';
import {Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute} from '@angular/router';

import { AuthenticationService } from '../services/auth.service';
import {UserService} from '../services/user.service';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {InformationDialogComponent} from '../../shared/component/information-dialog/information-dialog.component';
import { CommonService } from '../services/common.service';

@Injectable({ providedIn: 'root' })
export class SiteGuard implements CanActivate {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService,
    private commonService: CommonService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentUser();
    const siteId = Number(localStorage.getItem('siteId'));

    // allow access for these pages on sites page
    if (state.url.includes('roles') || state.url.includes('user') || state.url.includes('blacklist') || state.url.includes('human-resource')|| state.url.includes('leave')|| state.url.includes('overtime')) {
      return true;
    }
    
    if (state.url.includes('sites')) {
      if(!isNaN(siteId) && siteId != null && siteId != 0 && siteId!=undefined)
          this.router.navigate(['/dashboard']);
      return true;
    }

    if (state.url=='/'){
      if(!isNaN(siteId) && siteId != null && siteId != 0 && siteId!=undefined)
        this.router.navigate(['/dashboard']);
      else
        this.router.navigate(['/sites']);
      return true;
    }


    if (isNaN(siteId) || siteId == null || siteId === 0) {
      this.commonService.WarningNotification("Accessing this page require select site...redirecting to home page")
      this.router.navigate(['/']);
      return false;
    }

    this.userService.getUserDetails(currentUser.UserId).subscribe({
      next: user => {
        if (!user.UserSiteList.includes(siteId)) {
          this.openInformationDialog('ERROR', 'Unauthorized.')
          this.router.navigate(['/']);
          return false;
        }

      },
      error: err => {
        console.error(err)
        this.openInformationDialog('ERROR', 'Error getting user credentials.')
        this.router.navigate(['/']);
        return false;
      }
    });

    return true;
  }

  openInformationDialog(title, message){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '500px';
    dialogConfig.height = 'auto';
    dialogConfig.data = {
      title,
      message
    }

    let dialogRef: MatDialogRef<any>;
    dialogRef = this.dialog.open(InformationDialogComponent, dialogConfig);
  }

}
