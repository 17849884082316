import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError, EMPTY } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import {Occupant, OccupantAdd, OccupantDelete, OccupantImport, OccupantUpdate} from '../models/occupant.models';
import {UnitImport} from '../models/unit.models';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class OccupantsService {
  private occupantsBySiteUrl = environment.apiBaseUrl + 'ownerUnit/getalloccupantsbysite';
  private occupantsByUnitUrl = environment.apiBaseUrl + 'ownerUnit/getalloccupantsbyunit';
  private occupantsByIdUrl = environment.apiBaseUrl + 'ownerUnit/getoccupantbyid';
  private addUrl = environment.apiBaseUrl + 'ownerUnit/addoccupant';
  private updateUrl = environment.apiBaseUrl + 'ownerUnit/updateoccupant';
  private deleteUrl = environment.apiBaseUrl + 'ownerUnit/deleteoccupant';
  private allUnitCounterUrl = environment.apiBaseUrl + 'ownerUnit/getallunitcounter';
  private importOccupantsURL = environment.apiBaseUrl + '/ownerUnit/importoccupant';

  // STATE MANAGEMENT //
  private currentOccupantsByUnit$ = new Observable<any>();
  private currentOccupantsBySite$ = new Observable<any>();

  constructor(
    private http: HttpClient,
    private commonService: CommonService) { }

  getOccupantsBySite(siteId: number): Observable<Occupant[]> {
    const url = `${this.occupantsBySiteUrl}/${siteId}`;
    //console.log(url);
    return this.http.get<Occupant[]>(url)
      .pipe(
        tap(data =>
          //console.log('getOccupantsBySite: ' + JSON.stringify(data))
          console.log('getOccupantsBySite: ')
        ),
        catchError(this.commonService.handleError)
      );
  }

  getOccupantsById(Id: number): Observable<Occupant> {
    const url = `${this.occupantsByIdUrl}/${Id}`;
    //console.log(url);
    return this.http.get<Occupant>(url)
      .pipe(
        tap(data =>
          //console.log('getOccupantsById: ' + JSON.stringify(data))
          console.log('getOccupantsById: ')
        ),
        catchError(this.commonService.handleError)
      );
  }

  getallUnitCounter(siteId: number){
    const url = `${this.allUnitCounterUrl}/${siteId}`;
    //console.log(url);
    return this.http.get<any>(url)
      .pipe(
        tap(data =>
          //console.log('getallUnitCounter: ' + JSON.stringify(data))
          console.log('getallUnitCounter: ')
        ),
        catchError(this.commonService.handleError)
      );
  }

  // getOccupantsBySiteAndFilter(siteId: number, owner?:boolean,resident?:boolean,tenant?:boolean): Observable<Occupant[]> {
  //   const url = `${this.occupantsBySiteUrl}/${siteId}`;
  //   //console.log(url);
  //   return this.http.get<Occupant[]>(url)
  //     .pipe(
  //       tap(data =>
  //         //console.log('getOccupantsBySite: ' + JSON.stringify(data))
  //         console.log('getOccupantsBySite: ')
  //       ),
  //       map(data => {
  //         if(owner==true && resident==true) {
  //           return data.filter(i => i.IsOwner == true || i.OccupantsType == "Resident");
  //         }
  //         else if(owner==true && tenant==true){
  //           return data.filter(i => i.IsOwner == true || i.OccupantsType == "Tenant");
  //         }
  //         else if(resident==true && tenant==true){
  //           return data.filter(i => i.OccupantsType == "Resident" || i.OccupantsType == "Tenant");
  //         }
  //         else if(owner==true){
  //             return data.filter(i => i.IsOwner == true);
  //         }
  //         else if(resident==true) {
  //             return data.filter(i => i.OccupantsType == "Resident");
  //         }
  //         else if(tenant==true) {
  //           return data.filter(i => i.OccupantsType == "Tenant");
  //         }
  //         else{
  //           return data;
  //         }
  //       }),
  //       catchError(this.commonService.handleError)
  //     );
  // }

  getOccupantsByUnit(unitId: number){
    const url = `${this.occupantsByUnitUrl}/${unitId}`;
    //console.log(url);
    return this.http.get<any>(url)
      .pipe(
        tap(data =>
          //console.log('getOccupantsByUnit: ' + JSON.stringify(data))
          console.log('getOccupantsByUnit: ')
        ),
        catchError(this.commonService.handleError)
      );
  }

  addOccupant(occupant: OccupantAdd): Observable<OccupantAdd> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // const body = {
    //   occupant
    //   // OccupantsId: occupant.OccupantsId,
    //   // OccupantsName: occupant.OccupantsName,
    //   // UnitId: occupant.UnitId,
    //   // OccupantsEmail: occupant.OccupantsEmail,
    //   // OccupantsContact: occupant.OccupantsContact,
    //   // OccupantsType: occupant.OccupantsType,
    //   // CountryId: occupant.CountryId,
    //   // OccupantsIdentityId: occupant.OccupantsIdentityId,
    //   // OccupantsTitle: occupant.OccupantsTitle,
    //   // IsOwner: occupant.IsOwner,
    //   // UpdatedBy: occupant.UpdatedBy,
    //   // UpdatedOn: occupant.UpdatedOn
    // };
    return this.http.post<OccupantAdd>(this.addUrl, occupant, { headers })
      .pipe(
        map(() => occupant),
        catchError(this.commonService.handleError)
      );
  }

  updateOccupant(occupant: OccupantUpdate): Observable<OccupantUpdate> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // const body = {
    //   occupant
    // };
    const url = `${this.updateUrl}`;
    return this.http.post<OccupantUpdate>(url, occupant, { headers })
      .pipe(
        // Return the occupant on an update
        map(() => occupant),
        catchError(this.commonService.handleError)
      );
  }

  // deleteOccupant(occupant: OccupantDelete): Observable<OccupantDelete> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   const url = `${this.deleteUrl}`;
  //   return this.http.post<OccupantDelete>(url, occupant, { headers })
  //     .pipe(
  //       tap(data => console.log('deleteOccupant End: ' + occupant.OccupantId)),
  //       catchError(this.commonService.handleError)
  //     );
  // }

  deleteOccupant(Id: number): Observable<{}> {
    const url = `${this.deleteUrl}/${Id}`;
    console.log(url);
    return this.http.delete<any>(url)
      .pipe(
        //tap(data => console.log('deleteOccupant End: ' + Task.TaskId)),
        catchError(this.commonService.handleError)
      );
  }

  importOccupants(occupants: OccupantImport[]){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.importOccupantsURL}`;
    return this.http.post<any>(url, occupants, { headers })
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  // private initializeOccupant(): Occupant {
  //   // Return an initialized object
  //   return {
  //     OccupantId: 0,
  //     OccupantName: '',
  //     OccupantContact: '',
  //     OccupantEmail: '',
  //     OccupantType: '',
  //     Country: 0,
  //     OccupantIdentityId: '',
  //     OccupantTitle: '',
  //     IsOwner: false,
  //     Unit: null,
  //     VehicleList: null,
  //     UpdatedOn: null,
  //     UpdatedBy: '',
  //   };
  // }


  // STATE MANAGEMENT //
  // set currentOccupantsInfo to be passed from in unit-detail component to unit-occupants & unit-vehicles component
  setCurrentOccupantsByUnit(currentOccupantsByUnit$: Observable<any>){
    this.currentOccupantsByUnit$ = currentOccupantsByUnit$;
  }
  getCurrentOccupantsByUnit(){
    return this.currentOccupantsByUnit$;
  }
  setCurrentOccupantsBySite(currentOccupantsBySite$: Observable<any>){
    this.currentOccupantsBySite$ = currentOccupantsBySite$;
  }
  getCurrentOccupantsBySite(){
    return this.currentOccupantsBySite$;
  }
}
