import {Unit} from './unit.models'
import {Occupant} from './occupant.models'

export class AccessCard {
  CardRunningId: number;
  CardId: string;
  UnitInfo: Unit;
  Status: string;
  CardType: string;
  OccupantInfo: Occupant;
  Reason: string;
  UpdatedBy: string;
  UpdatedOn: Date;
  BlockFrom: Date;
  BlockTo: Date;
  Notes: string;
  
}