import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { CommonService } from '../../../core/services/common.service';
//import {FileUpload} from '../../../core/models/fileUpload.models';

@Component({
  selector: 'upload-file-multiple',
  templateUrl: './upload-file-multiple.component.html',
  styleUrls: ['./upload-file-multiple.component.css']
})

/**
 * To use this component, specify this at the parent component:
 * <upload-file-multiple [fileUploadObjectMultiple]="fileUploadObjectMultiple" [fileType]="'*'" (eventEmitter)="updateFileUploadObjectMultiple($event)"></upload-file-multiple>
 * - fileUploadObjectMultiple has the model of FileUpload
 * - updateFileUploadObject is the function in the parent component to deal with the updated FileUpload model
 * - fileType example only allow image [fileType]="'image/*'" or image and pdf [fileType]="'image/*,application/pdf'"
 */
export class UploadFileMultipleComponent implements OnInit, OnChanges {
  @ViewChild('newFile', { static: false })
  private newFileInput: ElementRef;
  files: File;
  Attachments: any[] = [];

  @Input() disabled: boolean;
  @Input() fileUploadObjectMultiple: any;
  @Input() fileType: string;
  @Output() eventEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private commonService: CommonService) {
    if (this.disabled) {
      this.disabled = this.disabled
    }
    else {
      this.disabled = false;
    }
  }

  ngOnInit(): void {
    //console.log("In upload-file-multiple ngOnInit fileType: " + this.fileType);
  }
  ngOnChanges(changes: SimpleChanges) {
    //console.log("In ngOnChanges");
    //console.log(this.fileUploadObjectMultiple)
    this.eventEmitter.emit(this.fileUploadObjectMultiple)
  }

  async convertFile(file: File): Promise<Observable<string>> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }

  async fileNewSelect(event: any): Promise<void> {
    //console.log(event)

    if (event.target.files && event.target.files[0]) {
      this.files = event.target.files;
      let filesCount = event.target.files.length;
      //console.log("filesCount " + filesCount);

      for (let i = 0; i < filesCount; i++) {

        const file: File = this.files[i];
        (await this.convertFile(file)).subscribe(base64 => {
          //console.log('Convert base64: ' + JSON.stringify(base64));
          var AttachmentFileName = this.files[i].name;

          var AttachmentFileHeader = base64.split(',')[0];
          var AttachmentFileBytes = base64.split(',')[1];
          //console.log('AttachmentFileName: ' + AttachmentFileName);
          //console.log('AttachmentFileHeader: ' + AttachmentFileHeader);
          //console.log('AttachmentFileBytes: ' + AttachmentFileBytes);
          //console.log('fileType: ' + this.files[i].type);

          const itemTemp = {
            'AttachmentId': 0,
            'AttachmentFileName': AttachmentFileName,
            'AttachmentFileHeader': AttachmentFileHeader,
            'AttachmentFileBytes': AttachmentFileBytes,
            'AttachmentFileBase64': base64,
            'AttachmentFileMarkDelete': false,
            'AttachmentFileSize': this.commonService.countFileUploadedSizeText(AttachmentFileName, base64),
            //'AttachmentIsImage': this.isImage(AttachmentFileName)
          }
          //console.log(this.isImage(AttachmentFileName));
          this.fileUploadObjectMultiple.push(itemTemp);
        });

        //console.log('Attachments: ' + JSON.stringify(this.Attachments));
      }

      //console.log("In fileNewSelect");
      //console.log(this.fileUploadObjectMultiple);
      this.eventEmitter.emit(this.fileUploadObjectMultiple);
    }

  }

  onClickDownload(base64String, fileName) {
    console.log(base64String)
    this.commonService.onClickDownload(base64String, fileName);
  }

  fileUploadedDelete(i: number, AttachmentId: number) {
    console.log('Delete i:' + i + ' AttachmentId:' + AttachmentId);

    this.fileUploadObjectMultiple.splice(i, 1);

    //console.log(this.fileUploadObjectMultiple);
    this.eventEmitter.emit(this.fileUploadObjectMultiple);
  }

  getFileExtension(fileName) {
    var fileExtension;
    fileExtension = fileName.replace(/^.*\./, '');
    return fileExtension;
  }

  isImage(fileName) {
    var fileExt = this.getFileExtension(fileName);
    //console.log('fileExt:' + fileExt);
    var imagesExtension = ["png", "jpg", "jpeg"];
    if (imagesExtension.indexOf(fileExt) !== -1) {
      return true;
    } else {
      return false;
    }
  }

}
