import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  template: `
    <div class="container-fluid">
      <div class="modal-body">
        <div class="modal-header">
          <h5 class="modal-title">{{title}}</h5>
          <button (click)="close()" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="message" [innerHTML]="message"></div>
        <br/>
      </div>
      <div class="modal-footer">
        <button (click)="delete()" class="btn btn-primary btn-rounded width-sm">
          <span *ngIf="!buttonText">Delete</span>
          <span *ngIf="buttonText">{{buttonText}}</span>
        </button>
      </div>
    </div>
  `
  ,
  styles: [
    '.message { padding-left: 15px }'
  ]
})

/**
 * To use this component, add the following lines to your openDialog function:
 *
 * const dialogConfig = new MatDialogConfig();
 * dialogConfig.disableClose = true;
 * dialogConfig.autoFocus = true;
 * dialogConfig.width = '500px';
 * dialogConfig.height = 'auto';
 * dialogConfig.data = {
 *     title: 'Your Title',
 *     message: 'Your Message'
 *     buttonText: 'Text to display at button'
 *   }
 *
 * let dialogRef: MatDialogRef<any>;
 * dialogRef = this.dialog.open(DeleteDialogComponent, dialogConfig);
 *
 * dialogRef.afterClosed().subscribe(result => {
 *   result.deleteConfirmation // whatever you want to do with the result.deleteConfirmation
 * })
 *
 */
export class DeleteDialogComponent implements OnInit {
  private readonly title: string;
  private readonly message: string;
  private readonly buttonText: string;

  constructor(private dialogRef: MatDialogRef<DeleteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title
    this.message = data.message
    if (data.buttonText) {
      this.buttonText = data.buttonText
    }
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close({event: 'Cancel'});
  }

  delete() {
    this.dialogRef.close({deleteConfirmation: true});
  }

}

