import {Component, OnDestroy, OnInit, ViewChild, Input} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { AccessCardService } from 'src/app/core/services/access-card.service';
import {MatTableDataSource} from '@angular/material/table';
import {AuthenticationService} from 'src/app/core/services/auth.service';
import {CommonService} from 'src/app/core/services/common.service';
import { AccessCard} from 'src/app/core/models/accessCard.model';


@Component({
  selector: 'app-access-card',
  templateUrl: './access-card.component.html',
  styleUrls: ['./access-card.component.css']
})
export class AccessCardComponent implements OnInit {
    private subscriptions: Subscription[] = [];
    filterForm!: FormGroup;
    siteId=Number(localStorage.getItem('siteId'));
    matTableDataSource;
    statusList: any;
    cardTypeList: any;
    reasonList: any;
    columns: string[] = ['CardType','Unit',  'CardHolder', 'CardId', 'Status','Reason', 'Action'];
    displayedColumns: Record<string, string> = {CardType: 'CardType',Unit: 'Unit', CardHolder: 'CardHolder',  CardId: 'CardId', Status:'Status',  Reason: 'Reason', Action:'Action'};
    unitList: string[]=new Array<string>();
    accessCardList: AccessCard [];
    accessCardDataSource:any;
    // for mat table functionalities
    searchFilterForm!: FormGroup;
    filterType: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: false }) sort: MatSort;


  constructor(private accessCardService: AccessCardService,
              private authenticationService: AuthenticationService,
              private formBuilder: FormBuilder,
              public dialog: MatDialog,
              private router: Router,
              private commonService: CommonService) { }

  ngOnInit(): void {
    this.getAllAccessCardBySite();
    this.buildSearchFilterForm();
  }

  getAllAccessCardBySite(){
      this.subscriptions.push(
        this.accessCardService.getAllAccessCardBySite(this.siteId).subscribe({
          next: card => {
            this.accessCardList = card
            console.log(this.accessCardList)

            this.statusList = [...new Set(this.accessCardList.map(item => item.Status))]
            this.cardTypeList = [...new Set(this.accessCardList.map(item => item.CardType))]
            this.unitList = [...new Set(this.accessCardList.map(item => item.UnitInfo.UnitName))]
            this.reasonList = [...new Set(this.accessCardList.map(item => item.Reason))]

            this.accessCardDataSource=this.accessCardList.map(x=>{
              return {
                CardRunningId: x.CardRunningId,
                CardType: x.CardType,
                Unit: x.UnitInfo.UnitName,
                CardHolder: x.OccupantInfo.OccupantName,
                CardId: x.CardId,
                Status: x.Status,
                Reason: x.Reason
              }
            })
            this.initialiseTableValues();
          },
          error: err => console.log(err)
        })
      )
  }

  buildSearchFilterForm(){
        // initialise searchFilterForm
    this.searchFilterForm = this.formBuilder.group({
      CardType:'',
      Unit:'',
      Status: '',
      Reason:'',
    });
  }

    initialiseTableValues(){
    
    console.log(this.accessCardDataSource)
    this.matTableDataSource = new MatTableDataSource(this.accessCardDataSource);

     this.matTableDataSource.filterPredicate = ((data, filter) => {
      const a = !filter.Status || data.Status == filter.Status;
      const b = !filter.CardType || data.CardType == filter.CardType;
      const c = !filter.Unit || data.Unit == filter.Unit;;
      const d = !filter.Reason || data.Reason == filter.Reason;
      return a && b && c && d;
    }) as (invetoryList, string) => boolean;

    // map model field to column name for sort function
    this.matTableDataSource.sortingDataAccessor = (item: any, property) => {
      switch(property) {
        case 'Status': return item.Status;
        case 'CardType': return item.CardType;
        case 'Unit': return item.Unit;
        case 'CardHolder': return item.CardHolder;
        case 'CardId': return item.CardId;
        case 'Reason': return item.Reason;
        default: return item[property];
      }
    };
    this.matTableDataSource.paginator = this.paginator;
  }
  
  edit(card){
    console.log(card)
    this.router.navigate(['/apps/access-card-edit',card.CardRunningId]);
  }

    // =========================
  // Search / Filter Functions
  // =========================
  get searchForm() { return this.searchFilterForm.controls; }

  applySearchFilter() {
    
    let cardtype = this.searchForm.CardType.value;
    let unit = this.searchForm.Unit.value;
    let status = this.searchForm.Status.value;
    let reason = this.searchForm.Reason.value;
    
     const filter = {
      CardType:cardtype,
      Unit: unit,
      Status: status,
      Reason: reason,
     }
      this.matTableDataSource.filter = filter;
  }
  
  removeFilters() {
    this.searchFilterForm = this.formBuilder.group({
        CardType: '',
        Unit:'',
        Status:'',
        Reason:''
    });

  this.initialiseTableValues()
  }
  // =============
  // Sort Function
  // =============
  sortData(){
    this.matTableDataSource.sort = this.sort;
  }

}
