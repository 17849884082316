import { Injectable } from '@angular/core';
import { PurchaseOrder } from '../../models/purchases/purchaseOrder.model';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Vendors } from '../../models/vendors.models';
import { ErrorTracker } from '../../models/errorTracker';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  baseUrl = environment.apiBaseUrl;
  public isLoading = false;

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe
  ) { }

  addNewVendor(newVendor:Vendors) {
    this.isLoading=true;
     
    const body = {
        VendorName : newVendor.VendorName,
        VendorEmail : newVendor.VendorEmail,
        VendorAddress : newVendor.VendorAddress,
        VendorFaxNo : newVendor.VendorFaxNo,
        VendorRegisterNo : newVendor.VendorRegisterNo,
        VendorContactPerson: newVendor.VendorContactPerson,
        VendorContactNo : newVendor.VendorContactNo,
        VendorType :newVendor.VendorType
    };

    return this.http.post<Vendors[]>(environment.apiBaseUrl+`vendor/addVendor`,body);
  }

  getVendorList():Observable<Vendors[] | ErrorTracker> {
    this.isLoading = true;
    return this. http.get<Vendors[]>(environment.apiBaseUrl+'vendor/getVendors') 
    .pipe(tap(data=>console.log(JSON.stringify(data))),catchError(this.handleError));
  }

  getPurchaseOrders(siteId: number): Observable<PurchaseOrder[]> {
    var url = this.baseUrl + `purchaseOrder/GetAllPurchaseOrderBySite?siteId=${siteId}`;

    return this.http.get(url).pipe(
      map((data: any) => {
        const purchaseOrders: any = data.map(item => ({
          purchaseOrderId: item.PurchaseOrderId,
          purchaseOrderNo: item.PurchaseOrderNo,
          approvedTaskId: item.ApprovedTaskId,
          createdOn: this.datePipe.transform(item.CreatedOn, 'dd MMM yyyy'),
          vendorId: item.VendorId,
          vendorName: item.VendorName,
          companyRegisterNumber: item.CompanyRegisterNumber,
          amount: item.Amount,
          status: item.Status,
          Notes: item.Notes,
          PurchaseOrderDetails: item.PurchaseOrderDetails
        }))
        return purchaseOrders;
      }),
      catchError(this.handleError)
    );

  }

  getPurchaseOrderById(purchaseOrderId: Number) {
    var url = this.baseUrl + `purchaseOrder/GetPurchaseOrderById?poId=${purchaseOrderId}`;

    return this.http.get(url).pipe(
      // tap(data => console.log('getPurchaseOrderById: ', data)),
      map((data: any) => {
        const temp =  {
          purchaseOrderId: data.PurchaseOrderId,
          purchaseOrderNo: data.PurchaseOrderNo,
          approvedTaskId: data.ApprovedTaskId,
          quotationId: data.QuotationId,
          vendorId: data.VendorId,
          vendorName: data.VendorName,
          companyRegisterNumber: data.CompanyRegisterNumber,
          purchaseOrderDetails: data.PurchaseOrderDetails.map(item => ({
            purchaseOrderDetailId: item.PurchaseOrderDetailId,
            coaId: item.CoaId,
            category: item.Category,
            subCategory: item.SubCategory,
            remark: item.Description,
            quantity: item.Qty,
            priceAmount: item.Price,
            discount: item.Discount,
            taxAmount: item.Tax,
            totalAmount: item.Amount,
          })),
          amount: data.Amount,
          notes: data.Notes,
          status: data.Status,
          createdOn: data.CreatedOn,
          createdBy: data.CreatedBy,
        }
        return temp;
      }),
      catchError(this.handleError)
    )
  }

  createPurchaseOrder(data: any) {
    var url = this.baseUrl + "purchaseOrder/CreatePurchaseOrder";
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers }).pipe(
      tap(data => console.log('addPurchaseOrder: ')),
      catchError(this.handleError)
    );
  }

  updatePurchaseOrder(data: any) {
    var url = this.baseUrl + "purchaseOrder/UpdatePurchaseOrder";
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.post(url, data, { headers }).pipe(
      tap(data => console.log('updatePurchaseOrder: ')),
      catchError(this.handleError)
    );
  }

  updatePurchaseOrderStatus(data: any) {
    var url = this.baseUrl + "purchaseOrder/UpdatePoStatus";
    var headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    return this.http.put(url, data, { headers }).pipe(
      tap(data => console.log('updatePurchaseOrderStatus: ')),
      catchError(this.handleError)
    );
  }

  private handleError(err:HttpErrorResponse):Observable<never> {
    let dataError= new ErrorTracker();
    dataError.errorNumber = 100;
    dataError.message = err.statusText;
    dataError.friendlyMessage = 'An error occur when trying to retrieve data.';

    return throwError(dataError);
  }
}
