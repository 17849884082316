import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders,HttpBackend } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import {Notification} from '../models/notification.model';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private getAllNotificationByUserURL: string = environment.apiBaseUrl+'notification/getallnotificationbyuser/'
  private updateNotificationStatusURL: string = environment.apiBaseUrl+'authUser/updatenotificationstatus'
  

  constructor(
    private http: HttpClient,
    private commonService: CommonService, 
    private handler: HttpBackend)
    { 
        this.http = new HttpClient(handler);
    }

  getAllNotification(userId: number,siteId:number): Observable<Notification[]>{
    return this.http.get<Notification[]>(`${this.getAllNotificationByUserURL}?siteId=${siteId}&userId=${userId}`)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }


  updateNotificationStatus(notificationId: number){
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const url = `${this.updateNotificationStatusURL}?notificationStatusId=${notificationId}`;
    return this.http.post<any>(url, { headers })
      .pipe(
        catchError(this.commonService.handleError)
      );
  }
}
