import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {CommonService} from '../../../core/services/common.service';
import {FileUpload} from '../../../core/models/fileUpload.models';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})

/**
 * To use this component, specify this at the parent component:
 * <app-upload-file [fileUploadObject]="fileUploadObject" [fileType]="'*'" (eventEmitter)="updateFileUploadObject($event)"></app-upload-file>
 * - fileUploadObject has the model of FileUpload
 * - updateFileUploadObject is the function in the parent component to deal with the updated FileUpload model
 * - fileType example only allow image [fileType]="'image/*'" or image and pdf [fileType]="'image/*,application/pdf'"
 */
export class UploadFileComponent implements OnInit, OnChanges {
  @ViewChild('newFile', {static: false})
  private newFileInput: ElementRef;

  @Input() disabled: boolean;
  @Input() fileUploadObject: FileUpload;
  @Input() fileType: string;
  @Input() hideDelete: boolean;
  @Output() eventEmitter: EventEmitter<FileUpload> = new EventEmitter();


  constructor(private commonService: CommonService) {
    if (this.disabled) {
      this.disabled = this.disabled
    }
    else {
      this.disabled = false;
    }
    if (this.hideDelete) {
      this.hideDelete = this.hideDelete
    }
    else {
      this.hideDelete = false;
    }
  }

  ngOnInit(): void {
    //console.log("In upload-file-multiple ngOnInit fileType: " + this.fileType);
  }

  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.fileUploadObject)
    this.eventEmitter.emit(this.fileUploadObject)
  }

  // convert byte to base64
  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => result.next(event.target.result.toString());
    return result;
  }

  fileNewSelect(event: any): void {
    //console.log(event)

    if (event.target.files && event.target.files[0]) {
      const file: File = event.target.files[0];
      this.fileUploadObject.fileNewName = file.name;

      this.convertFile(file).subscribe(base64 => {
        this.fileUploadObject.fileNewByte = base64;
      });

      this.fileUploadObject.fileUploadedByte = null;
      this.fileUploadObject.fileUploadedName = null;
      this.fileUploadObject.fileUploadedSize = null;
    }
    //console.log(this.fileUploadObject)
    this.eventEmitter.emit(this.fileUploadObject)
  }

  onClickDownload(base64String,fileName) {
    console.log(base64String)
    this.commonService.onClickDownload(base64String,fileName);
  }

  fileNewDelete() {
    this.newFileInput.nativeElement.value = '';
    this.fileUploadObject.fileNewByte = null;
    this.fileUploadObject.fileNewName = null;

    //console.log(this.fileUploadObject)
    this.eventEmitter.emit(this.fileUploadObject)
  }

  fileUploadedDelete() {
    this.fileUploadObject.fileUploadedByte = null;
    this.fileUploadObject.fileUploadedName = null;
    this.fileUploadObject.fileUploadedSize = null;

    //console.log(this.fileUploadObject)
    this.eventEmitter.emit(this.fileUploadObject)
  }

  getFileExtension(fileName) {
    var fileExtension;
    fileExtension = fileName.replace(/^.*\./, '');
    return fileExtension;
  }

  isImage(fileName) {
    var fileExt = this.getFileExtension(fileName);
    //console.log('fileExt:' + fileExt);
    var imagesExtension = ["png", "jpg", "jpeg"];
    if (imagesExtension.indexOf(fileExt) !== -1) {
      return true;
    } else {
      return false;
    }
  }

}
