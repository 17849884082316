import {Unit} from './unit.models';
import {Vehicle} from './vehicle.models';
import {Country} from './country.models';

export class Occupant {
    OccupantId: number | null;
    OccupantName: string;
    OccupantContact: string;
    OccupantEmail: string;
    OccupantType: string;
    OccupantIdentityId: string;
    OccupantTitle: string;
    IsOwner: boolean;
    Country: Country;
    Unit: Unit;
    VehicleList: Array<Vehicle>;
    UpdatedOn: string;
    UpdatedBy: string;
  }

export class OccupantUpdate {
  OccupantId: number | null;
  OccupantName: string;
  OccupantContact: string;
  OccupantEmail: string;
  OccupantType: string;
  UnitId?: number|null;
  Unit?:any|null;
  OccupantNationalityId?: number | null;
  Country?:any|null;
  OccupantIdentityId: string;
  OccupantTitle: string;
  IsOwner: boolean;
  MailingAddress1?: string;
  MailingAddress2?: string;
  MailingCity?: string;
  MailingPostcode?: number;
  MailingState?: string;
  MailingCountry?:any | null;
  MailingAddrIsSame?: boolean;
  ProfileImage?: string;
  ProfileImageByte?: string;
  ProfileImageName?: string;
  UpdatedOn: string;
  UpdatedBy: string;
}

export class OccupantAdd {
  OccupantId: number | null;
  OccupantName: string;
  OccupantContact: string;
  OccupantEmail: string;
  OccupantType: string;
  UnitId?: number|null;
  Unit?:any|null;
  VehicleList?:any|null;
  Country?:any|null;
  OccupantIdentityId: string;
  OccupantTitle: string;
  IsOwner: boolean;
  MailingAddress1?: string;
  MailingAddress2?: string;
  MailingCity?: string;
  MailingPostcode?: number;
  MailingState?: string;
  MailingCountry?:any | null;
  MailingAddrIsSame?: boolean;
  ProfileImage?: string;
  ProfileImageName?: string;
  ProfileHeader?: string;
  UpdatedOn: string;
  UpdatedBy: string;
}

export class OccupantDelete {
  OccupantId: number | null;
}

export class OccupantImport {
  UnitBlock: string;
  UnitLevel: string;
  UnitName: string;
  OccupantName: string;
  OccupantContact: string;
  OccupantEmail: string;
  OccupantType: string;
  OccupantIdentityId: string;
  OccupantTitle: string;
  IsOwner: boolean;
  NationalityCountryId: number;
  UnitSiteId: number;
  UpdatedOn: Date;
  UpdatedBy: string;
  ErrorMsg?: string;
}

