import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';

import {ProfilePicture, Role, SpecialView, User} from '../models/user.models';
import {environment} from '../../../environments/environment';
import {Observable, throwError, EMPTY} from 'rxjs';
import {Owner} from '../models/owner.models';
import {catchError, map, tap} from 'rxjs/operators';
import {Site} from '../models/site.models';
import {SoftwareSub} from '../models/softwareSub.model';
import { BehaviorSubject } from 'rxjs';
import { CommonService } from './common.service';

@Injectable({providedIn: 'root'})
export class SiteService {
  private getAllSitesURL: string = environment.apiBaseUrl + 'manage/getallsites/';
  private getAllSitesDetailsByUserIdURL: string = environment.apiBaseUrl + 'manage/getallsitesdetailsbyuserid/';
  private addUpdateSiteURL: string = environment.apiBaseUrl + 'manage/addUpdateSite/';
  private getSitesByIdURL: string = environment.apiBaseUrl + 'manage/getsitesbyId/';
  private getSoftwareSubBySiteIdURL: string = environment.apiBaseUrl + 'manage/getsitesubsdetails/';
  private addUpdateSiteDraftURL: string = environment.apiBaseUrl + 'manage/addupdatesitedraft/';
  private getDraftSitesByIdURL: string = environment.apiBaseUrl + 'manage/getdraftsitesbyId/';
  private getAllDraftSitesURL: string = environment.apiBaseUrl + 'manage/getalldraftsites/';
  private cSite:Site=new Site();


    private selectedSite = new BehaviorSubject(new Site());
    currentselectedSite = this.selectedSite.asObservable();

  constructor(
    private http: HttpClient,
    private commonService: CommonService) { }

  updateCurrentSide(siteid){
    this.selectedSite.next(siteid)
  }

  getAllSites(): Observable<Site[]> {
    return this.http.get<Site[]>(this.getAllSitesURL)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getAllSitesDetailsByUserId(userId: number): Observable<any[]> {
    return this.http.get<any[]>(this.getAllSitesDetailsByUserIdURL + userId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  addUpdateSite(site: Site) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.addUpdateSiteURL}`;
    return this.http.post<any>(url, site, {headers})
      .pipe(
        catchError(this.commonService.handleError)
      );
  }
  getSitesById(siteId: number): Observable<Site> {
    return this.http.get<Site>(this.getSitesByIdURL + siteId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getSoftwareSubBySiteId(siteId: number): Observable<SoftwareSub> {
    return this.http.get<SoftwareSub>(this.getSoftwareSubBySiteIdURL + siteId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  addUpdateSiteDraft(site: Site) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    const url = `${this.addUpdateSiteDraftURL}`;
    return this.http.post<any>(url, site, {headers})
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getDraftSitesById(siteId: number): Observable<Site> {
    return this.http.get<Site>(this.getDraftSitesByIdURL + siteId)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }

  getAllDraftSites(): Observable<Site[]> {
    return this.http.get<Site[]>(this.getAllDraftSitesURL)
      .pipe(
        catchError(this.commonService.handleError)
      );
  }
}
